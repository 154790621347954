import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {TourDetail} from './components/TourDetail'
import {ListDetail} from './components/ListDetail'
import { EditDetail } from './components/EditDetail'

const maintenanceBreadCrumbs: Array<PageLink> = [
  {
    title: '',
    path: '/apps/itinerary/add-includes',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TourdetailsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='tour-detail'
          element={
            <>
              <TourDetail />
            </>
          }
        />

        <Route
          path='list-detail'
          element={
            <>
              <ListDetail />
            </>
          }
        />

        <Route
          path='edit-detail/:id'
          element={
            <>
              <EditDetail />
            </>
          }
        />

        <Route index element={<Navigate to='/apps/tourdetails/tour-detail' />} />
      </Route>
    </Routes>
  )
}

export default TourdetailsPage
