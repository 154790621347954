import axios from 'axios'
import React, { FC, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import styles
import { useParams } from 'react-router-dom'
import { KTIcon } from '../../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import countriesData from '../../../../../countries.json'

const EditIsland: FC = () => {
    interface AddDayApiResponse {
        status: boolean
        day: {
            id: number
            day: string
            day_icon: string
            day_title: string
            day_tag: string
            day_highlight: string
            day_description: string
            status: string
            created_at: string
            updated_at: string
            // Other properties of the day object
        }
    }

    interface AddInfoApiResponse {
        status: boolean
        info: {
            id: number
            title: string
            description: string
            status: string
            created_at: string
            updated_at: string
            // Other properties of the day object
        }
    }

    interface AddTimingApiResponse {
        id: number
        start_month: string
        end_month: string
        amount: string
        created_at: string
        updated_at: string
    }

    const [showPopup, setShowPopup] = useState(false)
    const openPopup = () => {
        setShowPopup(true)
    }
    const closePopup = () => {
        setShowPopup(false)
    }

    const truncateDescription = (description: string, maxLength: number) => {
        if (description.length <= maxLength) {
            return description
        }

        const truncatedText = description.substring(0, maxLength)
        return `${truncatedText}...`
    }

    const [editorHtml, setEditorHtml] = useState('')

    const [isActive, setIsActive] = useState(false)
    const [title, setTitle] = useState('')
    const [visitorCount, setVisitorCount] = useState(0)
    const [days, setDays] = useState(0)
    const [city, setCity] = useState('')
    const [slug, setSlug] = useState('')
    const [nightDescription, setNightDescription] = useState('')
    const [country, setCountry] = useState('')
    const [tourType, setTourType] = useState('')
    const [daysOptions, setDaysOptions] = useState<string[]>([])
    const [selectedDay, setSelectedDay] = useState('')
    const [iconName, setIconName] = useState('')
    const [dayTitle, setDayTitle] = useState('')
    const [dayTag, setDayTag] = useState('')
    const [dayHighlight, setDayHighlight] = useState('')
    const [dayDescription, setDayDescription] = useState('')
    const [dayData, setDayData] = useState<AddDayApiResponse['day'][]>([])
    const [dayIds, setDayIds] = useState<number[]>([])
    const [isEditingDay, setIsEditingDay] = useState(false)
    const [editingDayId, setEditingDayId] = useState('')
    const [infoTitle, setInfoTitle] = useState('')
    const [infoDescription, setInfoDescription] = useState('')
    const [infoData, setInfoData] = useState<AddInfoApiResponse['info'][]>([])
    const [infoIds, setInfoIds] = useState<number[]>([])
    const [isEditingInfo, setIsEditingInfo] = useState(false)
    const [editingInfoId, setEditingInfoId] = useState(0)
    const [includes, setIncludes] = useState([])
    const [excludes, setExcludes] = useState([])
    const [inclusion, setInclusion] = useState('')
    const [includesDetails, setIncludesDetails] = useState<any[]>([])
    const [selectedIncludes, setSelectedIncludes] = useState<string[]>([])
    const [selectedIncludesText, setSelectedIncludesText] = useState<string[]>([])
    const [selectedExcludes, setSelectedExcludes] = useState<string[]>([])
    const [selectedExcludesText, setSelectedExcludesText] = useState<string[]>([])
    const [selectedFiles, setSelectedFiles] = useState<File[]>([])
    const [selectedThumbnailFiles, setSelectedThumbnailFiles] = useState<File[]>([])
    const [selectedFilesIds, setSelectedFilesIds] = useState<String[]>([])
    const [selectedThumbnailFilesIds, setSelectedThumbnailFilesIds] = useState<String[]>([])
    const [packageList, setPackageList] = useState([])
    const [adultPrice, setAdultPrice] = useState('')
    const [childPrice, setChildPrice] = useState('')
    const [infantPrice, setInfantPrice] = useState('')
    const [isFlightIncluded, setIsFlightIncluded] = useState(false)
    const [isCruise, setIsCruise] = useState(false)
    const [isResort, setIsResort] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [amount, setAmount] = useState('')
    const [resortDates, setResortDates] = useState<AddTimingApiResponse[]>([])
    const [selectedResortDateIds, setSelectedResortDateIds] = useState<String[]>([])
    const [selectedResortIds, setSelectedResortIds] = useState<String[]>([])
    const [resortName, setResortName] = useState('');
    const [resortRating, setResortRating] = useState('');
    const [resortOccuppancy, setResortOccuppancy] = useState('');
    const [resortVillaCount, setResortVillaCount] = useState('');
    const [resortRoomSize, setResortRoomSize] = useState('');
    const [resorts, setResorts] = useState<any[]>([]);
    const [resortImageFile, setResortImageFile] = useState<File[]>([])
    const [isEditingResort, setIsEditingResort] = useState(false);
    const [editingResortId, setEditingResortId] = useState(0);
    const [editingResortIndex, setEditingResortIndex] = useState(-1);
    const [editingInfoIndex, setEditingInfoIndex] = useState(-1)
    const [isEditingTiming, setIsEditingTiming] = useState(false);
    const [editingTimingId, setEditingTimingId] = useState(0);
    const [editingTimingIndex, setEditingTimingIndex] = useState(-1);
    const [bannerTitle, setBannerTitle] = useState('')
    const [bannerDescription, setBannerDescription] = useState('')


    const handleToggle = () => {
        setIsActive(!isActive)
    }

    const handleIncludeCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const value = event.target.value
        if (event.target.checked) {
            setSelectedIncludes([...selectedIncludes, value])
            setSelectedIncludesText([...selectedIncludesText, (includes[index] as any).included])
        } else {
            setSelectedIncludes(selectedIncludes.filter((item) => item !== value))
            setSelectedIncludesText(
                selectedIncludesText.filter((item) => item !== (includes[index] as any).included)
            )
        }
    }

    const handleExcludeCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const value = event.target.value
        if (event.target.checked) {
            setSelectedExcludes([...selectedExcludes, value])
            setSelectedExcludesText([...selectedExcludesText, (excludes[index] as any).excluded])
        } else {
            setSelectedExcludes(selectedExcludes.filter((item) => item !== value))
            setSelectedExcludesText(
                selectedExcludesText.filter((item) => item !== (excludes[index] as any).excluded)
            )
        }
    }

    const icons = [
        'day1',
        'day2',
        'day3',
        'day4',
        'day5',
        'day6',
        'day7',
        'day8',
        'day9',
        'day10',
        'day11',
        'day12',
        'day13',
        'day14',
        'day15',
        'day16',
        'day17',
        'day18',
        'day19',
        'day20',
        'day21',
        'day22',
        'day23',
        'day24',
        'day25',
        'day26',
        'day27',
    ]

    const handleSearchClick = () => {
        // Implement your search logic here
        alert('Search button clicked')
    }

    const iconClass = isActive ? 'active-icon' : 'inactive-icon'

    const [pricingList, setPricingList] = useState([])
    const [activeTab, setActiveTab] = useState('Tab2')

    const { id } = useParams()

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setSelectedFiles([...selectedFiles, ...Array.from(e.target.files)])
        }
    }

    const handleThumbnailFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setSelectedThumbnailFiles([...selectedThumbnailFiles, ...Array.from(e.target.files)])
        }
    }

    const handleThumbnailUpload = async () => {
        if (selectedThumbnailFiles.length == 0) {
            toast.error('Please select atleast one file')
        } else {
            const formData = new FormData()

            for (const file of selectedThumbnailFiles) {
                formData.append('images[]', file)
            }

            try {
                const response = await axios.post(
                    'https://admin.trawelmart.com/admin/public/index.php/api/uploadTourThumbnailImage',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )

                if (response.data.status) {
                    const photosInput = document.getElementById('thumbnailInputFiles') as HTMLInputElement
                    if (photosInput) {
                        photosInput.value = ''
                        if (photosInput.files) {
                            photosInput.files = null as any // Clear files
                        }
                    }
                    setSelectedThumbnailFilesIds((prevFileIds) => [...prevFileIds, ...response.data.files])
                    setSelectedThumbnailFiles([])
                } else {
                    toast.error('Some error occurred')
                }
            } catch (error) {
                console.error('Upload failed ', error)
            }
        }
    }

    function ThumbnailImage() {
        setSelectedThumbnailFilesIds([]);
    }

    const handleResortFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setResortImageFile([...resortImageFile, ...Array.from(e.target.files)])
      }
    }

    const handleUpload = async () => {
        if (selectedFiles.length == 0) {
            toast.error('Please select atleast one file')
        } else {
            const formData = new FormData()

            for (const file of selectedFiles) {
                formData.append('images[]', file)
            }

            try {
                const response = await axios.post(
                    'https://admin.trawelmart.com/admin/public/index.php/api/uploadTourImages',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )

                if (response.data.status) {
                    const photosInput = document.getElementById(
                        'photosInputFiles'
                    ) as HTMLInputElement
                    if (photosInput) {
                        photosInput.value = ''
                        if (photosInput.files) {
                            photosInput.files = null as any // Clear files
                        }
                    }
                    setSelectedFilesIds((prevFileIds) => [...prevFileIds, ...response.data.files]);
                    setSelectedFiles([]);
                } else {
                    toast.error('Some error occurred')
                }
            } catch (error) {
                console.error('Upload failed ', error)
            }
        }
    }

    function editDayData(day: any, index: any) {
        setIsEditingDay(true)
        setSelectedDay(dayData[index].day)
        setDayTitle(dayData[index].day_title)
        setDayTag(dayData[index].day_tag)
        setDayHighlight(dayData[index].day_highlight)
        setIconName(dayData[index].day_icon)
        setDayDescription(dayData[index].day_description)
        setEditingDayId(day)
    }

    function editInfoData(info: any, index: any) {
        setIsEditingInfo(true)
        setInfoTitle(infoData[index].title)
        setInfoDescription(infoData[index].description)
        setEditingInfoId(info)
    }

    function deleteDayData(id: any, deleteIndex: any) {
        axios
            .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteDaywiseDetails/' + id)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.status) {
                        setDayData((prevDayData) => {
                            const updatedDayData = prevDayData.filter((_, index) => index !== deleteIndex)
                            return updatedDayData
                        })

                        setDayIds((prevDayDataId) => {
                            const updatedDayDataId = prevDayDataId.filter((_, index) => index !== deleteIndex)
                            return updatedDayDataId
                        })
                    }
                }
            })
    }

    function deleteResort(id: any, deleteIndex: any) {
        axios
        .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteNewResort/' + id)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              cancelResortEdit();
              setResorts((prevResort) => {
                const updatedResort = prevResort.filter((_, index) => index !== deleteIndex)
                return updatedResort
              })
              setSelectedResortIds((prevResortId) => {
                const updatedResortId = prevResortId.filter((_, index) => index !== deleteIndex)
                return updatedResortId
              })
            }
          }
        })
      }

    function deleteInfoData(id: any, deleteIndex: any) {
        axios
            .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteTourInformation/' + id)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.status) {
                        cancelEditingInfo();
                        setInfoData((prevInfoData) => {
                            const updatedInfoData = prevInfoData.filter((_, index) => index !== deleteIndex)
                            return updatedInfoData
                        })

                        setInfoIds((prevInfoDataId) => {
                            const updatedInfoDataId = prevInfoDataId.filter((_, index) => index !== deleteIndex)
                            return updatedInfoDataId
                        })
                    }
                }
            })
    }

    function deleteImages(index: number) {
        const newFileIds = [...selectedFilesIds]
        newFileIds.splice(index, 1)
        setSelectedFilesIds(newFileIds)
    }

    useEffect(() => {
        axios.get('https://admin.trawelmart.com/admin/public/index.php/api/getIsland/' + id)
        .then((response) => {
            if(response.status == 200)
            {
                if(response.data.status)
                {
                    console.log(response.data.island);
                    setCountry(response.data.island.country);
                    setCity(response.data.island.city);
                    setTitle(response.data.island.title);
                    setSlug(response.data.island.slug);
                    setDays(response.data.island.days);
                    setVisitorCount(response.data.island.number_of_guests);
                    setTourType(response.data.island.island_type);
                    response.data.island.night_description ? setNightDescription(response.data.island.night_description) : setNightDescription('');
                    response.data.island.is_flight ? setIsFlightIncluded(response.data.island.is_flight === "Yes") : setIsFlightIncluded(false);
                    response.data.island.is_cruise ? setIsCruise(response.data.island.is_cruise === "Yes") : setIsCruise(false);
                    response.data.timings ? setResortDates(response.data.timings) : setResortDates([]);
                    response.data.island.resort_dates ? setSelectedResortDateIds(response.data.island.resort_dates.split(", ")) : setSelectedResortDateIds([]);
                    response.data.resorts ? setResorts(response.data.resorts) : setResorts([]);
                    response.data.island.resorts ? setSelectedResortIds(response.data.island.resorts.split(", ")) : setSelectedResortIds([]);
                    response.data.infos ? setInfoData(response.data.infos) : setInfoData([]);
                    response.data.island.tour_info_details ? setInfoIds(response.data.island.tour_info_details.split(", ")) : setInfoIds([]);
                    response.data.island.photos_details ? setSelectedFilesIds(response.data.island.photos_details.split(", ")) : setSelectedFilesIds([]);
                    response.data.island.banner_title ? setBannerTitle(response.data.island.banner_title) : setBannerTitle('')
                    response.data.island.banner_description ? setBannerDescription(response.data.island.banner_description) : setBannerDescription('')
                }
            }
        })
    }, [])

    useEffect(() => {
        setInfoData([])
        const axiosRequests = infoIds.map((infoId) => {
            return axios
                .get('https://admin.trawelmart.com/admin/public/index.php/api/getTourInformation/' + infoId)
                .then((response) => {
                    if (response.data.status) {
                        return response.data.info
                    }
                    return null
                })
        })

        Promise.all(axiosRequests).then((infoResponses) => {
            // Use a Set to track unique day.id values
            const uniqueInfoIds = new Set()
            const filteredInfoData = infoResponses.filter((info) => {
                if (info && !uniqueInfoIds.has(info.id)) {
                    uniqueInfoIds.add(info.id)
                    return true
                }
                return false
            })

            setInfoData(filteredInfoData)
        })
    }, [infoIds])

    const handleTabClick = (tabName: React.SetStateAction<string>) => {
        setActiveTab(tabName)
    }

    const handleChange = (html: any) => {
        setInfoDescription(html)
    }

    const handleChange1 = (html: any) => {
        setDayDescription(html)
    }

    function UpdateIsland()
    {
        if(city == '')
        {
          toast.error('Please enter the city')
        }
        else if(country == '')
        {
          toast.error('Please select the country')
        }
        else if(title == '')
        {
          toast.error('Please enter the tour title')
        }
        else if(slug == "")
        {
          toast.error('Please enter the slug')
        }
        else if(days == 0)
        {
          toast.error('Please enter the number of days')
        }
        else if(visitorCount == 0)
        {
          toast.error('Please enter the number of guests travelled')
        }
        else if(tourType == '')
        {
          toast.error('Please select the tour type')
        }
        else if(bannerTitle == '')
        {
          toast.error('Please enter title')
        }
        else if(bannerDescription == '')
        {
          toast.error('Please enter description')
        }
        else if(resortDates.length == 0)
        {
          toast.error("Please enter resort timing information")
        }
        else if(resorts.length == 0)
        {
          toast.error("Please enter resort information")
        }
        else if(infoIds.length == 0)
        {
          toast.error("Please enter information")
        }
        else
        {
          const tour_info_details = infoIds.join(', ')
          const photo_details = selectedFilesIds.join(', ')
          const timing_details = selectedResortDateIds.join(', ')
          const resorts = selectedResortIds.join(", ")
          const formData = {
            id: id,
            city: city,
            country: country,
            title: title,
            slug: slug,
            days: days,
            number_of_guests: visitorCount,
            island_type: tourType,
            night_description: nightDescription,
            is_flight: isFlightIncluded ? 'Yes' : 'No',
            resort_dates: timing_details,
            resorts: resorts,
            tour_info_details: tour_info_details,
            photos_details: photo_details,
            banner_title: bannerTitle,
            banner_description : bannerDescription
          }
          axios
          .post('https://admin.trawelmart.com/admin/public/index.php/api/updateIsland', formData)
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status) {
                toast.success('Island updated successfully...')
                setTimeout(() => {
                  (window as any).location = '/apps/islandlanding/list-island'
                }, 1000)
              } else {
                toast.error(response.data.message)
              }
            }
          })
        }
    }

    function editTiming(editIndex:number, id: number)
    {
      setIsEditingTiming(true);
      setEditingTimingId(resortDates[editIndex].id);
      setEditingTimingIndex(editIndex);
      setStartDate(resortDates[editIndex].start_month);
      setEndDate(resortDates[editIndex].end_month);
      setAmount(resortDates[editIndex].amount);
    }

    const addResort = async () => 
  {
    if(resortName == '')
    {
      toast.error("Please enter resort name");
    }
    else if(resortRating == '')
    {
      toast.error("Please enter resort rating");
    }
    else if(resortOccuppancy == '')
    {
      toast.error("Please enter resort occupancy");
    }
    else if(resortVillaCount == '')
    {
      toast.error("Please enter No. of Villas");
    }
    else if(resortRoomSize == '')
    {
      toast.error("Please enter Room Size");
    }
    else if(resortImageFile.length == 0)
    {
      toast.error("Please select Resort Image")
    }
    else
    {
      if(isEditingResort)
      {
        const formData = new FormData();

        for (const file of resortImageFile)
        {
          formData.append('images', file)
        }

        formData.append('resort_name', resortName);
        formData.append('resort_rating', resortRating);
        formData.append('resort_occupancy', resortOccuppancy);
        formData.append('resort_villa_count', resortVillaCount);
        formData.append('resort_room_size', resortRoomSize);
        formData.append('id', editingResortId + '');

        const response =  await axios.post("https://admin.trawelmart.com/admin/public/index.php/api/updateResort", formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if(response.status == 200)
        {
          if(response.data.status)
          {
            const updatedResort = [...resorts];
            updatedResort[editingResortIndex].resort_name = response.data.resort.resort_name;
            updatedResort[editingResortIndex].resort_rating = response.data.resort.resort_rating;
            updatedResort[editingResortIndex].resort_occupancy = response.data.resort.resort_occupancy;
            updatedResort[editingResortIndex].resort_villa_count = response.data.resort.resort_villa_count;
            updatedResort[editingResortIndex].resort_room_size = response.data.resort.resort_room_size;
            setResorts(updatedResort);
            toast.success("Resort updated successfully...");
            setResortName('')
            setResortRating('')
            setResortOccuppancy('')
            setResortVillaCount('')
            setResortRoomSize('')
            setIsEditingResort(false)
          }
        }
      }
      else
      {
        const formData = new FormData();

        for (const file of resortImageFile)
        {
          formData.append('images', file)
        }

        formData.append('resort_name', resortName);
        formData.append('resort_rating', resortRating);
        formData.append('resort_occupancy', resortOccuppancy);
        formData.append('resort_villa_count', resortVillaCount);
        formData.append('resort_room_size', resortRoomSize);

        const response =  await axios.post("https://admin.trawelmart.com/admin/public/index.php/api/addResort", formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        if(response.status == 200)
        {
          if(response.data.status)
          {
            setResortImageFile([])
            setResorts((prevResort) => [...prevResort, response.data.resort])
            setSelectedResortIds((prevResortId) => [...prevResortId, response.data.resort.id])
            toast.success("Resort added successfully...");
            setResortName('')
            setResortRating('')
            setResortOccuppancy('')
            setResortVillaCount('')
            setResortRoomSize('')
            const resortImageFile = document.getElementById(
              'resortFileInput'
            ) as HTMLInputElement
            if (resortImageFile) {
              resortImageFile.value = ''
              if (resortImageFile.files) {
                resortImageFile.files = null as any // Clear files
              }
            }
          }
        }
      }
    }
  }

    function addDayData() {
        if (selectedDay == '') {
            toast.error('Please select a day')
        } else if (iconName == '') {
            toast.error('Please select an icon')
        } else if (dayTitle == '') {
            toast.error('Please enter the title')
        } else if (dayTag == '') {
            toast.error('Please enter the badge tag')
        } else if (dayHighlight == '') {
            toast.error('Please enter the highlight of day')
        } else if (dayDescription == '') {
            toast.error('Please enter the description for the day')
        } else {
            if (isEditingDay) {
                const formData = {
                    day: selectedDay,
                    day_icon: iconName,
                    day_title: dayTitle,
                    day_description: dayDescription,
                    id: editingDayId,
                    day_tag: dayTag,
                    day_highlight: dayHighlight,
                }
                axios
                    .post(
                        'https://admin.trawelmart.com/admin/public/index.php/api/updateDaywiseDetails',
                        formData
                    )
                    .then((response) => {
                        console.log(response.data)
                        if (response.status == 200) {
                            if (response.data.status) {
                                const existingIndex = dayData.findIndex((day) => day.id === response.data.day.id)
                                if (existingIndex !== -1) {
                                    // If an item with the same ID exists, replace it with the updated data
                                    const updatedDayData = [...dayData]
                                    updatedDayData[existingIndex] = response.data.day
                                    setDayData(updatedDayData)
                                } else {
                                    // If no item with the same ID exists, add it to the end of the array
                                    setDayData((prevDayData) => [...prevDayData, response.data.day])
                                }
                                setSelectedDay('')
                                setIconName('')
                                setDayTitle('')
                                setDayTag('')
                                setDayDescription('')
                                setDayHighlight('')
                                setIsEditingDay(false)
                                setEditingDayId('')
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            } else {
                const formData = {
                    day: selectedDay,
                    day_icon: iconName,
                    day_title: dayTitle,
                    day_description: dayDescription,
                    day_tag: dayTag,
                    day_highlight: dayHighlight,
                }
                axios
                    .post(
                        'https://admin.trawelmart.com/admin/public/index.php/api/addDaywiseDetails',
                        formData
                    )
                    .then((response) => {
                        console.log(response.data)
                        if (response.status == 200) {
                            if (response.data.status) {
                                setDayData((prevDayData) => [...prevDayData, response.data.day])
                                setDayIds((prevDayDataId) => [...prevDayDataId, response.data.day.id])
                                setSelectedDay('')
                                setIconName('')
                                setDayTitle('')
                                setDayTag('')
                                setDayHighlight('')
                                setDayDescription('')
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        }
    }

    function cancelEditingDay() {
        setEditingDayId('')
        setSelectedDay('')
        setIconName('')
        setDayTitle('')
        setDayTag('')
        setDayHighlight('')
        setDayDescription('')
        setIsEditingDay(false)
    }

    function cancelEditingInfo() {
        setEditingInfoId(0)
        setInfoTitle('')
        setInfoDescription('')
        setIsEditingInfo(false)
        setEditingInfoIndex(-1)
    }

    function cancelResortEdit()
    {
      setResortName('')
      setResortRating('')
      setResortOccuppancy('')
      setResortVillaCount('')
      setResortRoomSize('')
      setIsEditingResort(false)
      setEditingResortId(0)
      setEditingResortIndex(-1)
    }

    function addInfo() {
        if (infoTitle == '') {
            toast.error('Please enter title')
        } else if (infoDescription == '') {
            toast.error('Please enter description')
        } else {
            if (isEditingInfo) {
                const formData = { title: infoTitle, description: infoDescription, id: editingInfoId }
                axios
                    .post(
                        'https://admin.trawelmart.com/admin/public/index.php/api/updateTourInformation',
                        formData
                    )
                    .then((response) => {
                        if (response.status == 200) {
                            if (response.data.status) {
                                const existingIndex = infoData.findIndex(
                                    (info) => info.id === response.data.info.id
                                )
                                if (existingIndex !== -1) {
                                    // If an item with the same ID exists, replace it with the updated data
                                    const updatedInfoData = [...infoData]
                                    updatedInfoData[existingIndex] = response.data.info
                                    setInfoData(updatedInfoData)
                                } else {
                                    // If no item with the same ID exists, add it to the end of the array
                                    setInfoData((prevInfoData) => [...prevInfoData, response.data.info])
                                }
                                cancelEditingInfo();
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            } else {
                const formData = { title: infoTitle, description: infoDescription }
                axios
                    .post(
                        'https://admin.trawelmart.com/admin/public/index.php/api/addTourInformation',
                        formData
                    )
                    .then((response) => {
                        if (response.status == 200) {
                            if (response.data.status) {
                                setInfoData((prevInfoData) => [...prevInfoData, response.data.info])
                                setInfoIds((prevInfoId) => [...prevInfoId, response.data.info.id])
                                setInfoTitle('')
                                setInfoDescription('')
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        }
    }

    function addTourDetails() {
        if (title == '') {
            toast.error('Please enter the tour title')
        } else if (days == 0) {
            toast.error('Please enter the number of days')
        } else if (country == '') {
            toast.error('Please select the country')
        } else if (city == '') {
            toast.error('Please enter the city')
        } else if (slug == '') {
            toast.error('Please enter the slug')
        } else if (tourType == '') {
            toast.error('Please select the tour type')
        } else if (dayIds.length == 0) {
            toast.error('Please enter the daywise plan')
        } else if (infoIds.length == 0) {
            toast.error('Please enter the information details')
        } else if (selectedIncludes.length == 0) {
            toast.error('Please select atleast one included option')
        } else if (selectedExcludes.length == 0) {
            toast.error('Please select atleast one excluded option')
        } else if (selectedFilesIds.length == 0) {
            toast.error('Please upload atleast one image')
        } else if (selectedThumbnailFilesIds.length == 0) {
            toast.error('Please upload thumbnail image')
        } else {
            const days_details = dayIds.join(', ')
            const tour_info_details = infoIds.join(', ')
            const include_details = selectedIncludes.join(', ')
            const exclude_details = selectedExcludes.join(', ')
            const photo_details = selectedFilesIds.join(', ')
            const timing_details = selectedResortDateIds.join(', ')
            const thumbnail = selectedThumbnailFilesIds.join(", ")
            const formData = {
                tour_title: title,
                tour_days: days,
                tour_city: city,
                tour_type: tourType,
                tour_country: country,
                days_details: days_details,
                tour_info_details: tour_info_details,
                includes_details: include_details,
                excludes_details: exclude_details,
                photos_details: photo_details,
                id: id,
                slug: slug,
                night_description: nightDescription,
                is_flight: isFlightIncluded ? 'Yes' : 'No',
                is_resort: isResort ? 'Yes' : 'No',
                resort_dates: timing_details,
                thumbnail_image: thumbnail
            }
            axios
                .post('https://admin.trawelmart.com/admin/public/index.php/api/updateTourDetails', formData)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.status) {
                            toast.success('Tour details updated successfully...')
                            setTimeout(() => {
                                ; (window as any).location = '/apps/tourdetail/list-detail'
                            }, 1000)
                        } else {
                            toast.error(response.data.message)
                        }
                    }
                })
        }
    }

    function addInclusion() {
        if (inclusion == '') {
            toast.error('Please enter any inclusion')
        } else {
            const formData = { included: inclusion, icon: 'day1' }
            axios
                .post(
                    'https://admin.trawelmart.com/admin/public/index.php/api/addInclusionForItinery',
                    formData
                )
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.status) {
                            setSelectedIncludes([...selectedIncludes, response.data.includes.id])
                            setIncludesDetails([...includesDetails, response.data.includes])
                            setInclusion('')
                        }
                    }
                })
        }
    }

    function deleteIncludes(id: any, deleteIndex: any) {
        axios
            .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteInclusion/' + id)
            .then((response) => {
                if (response.data.status) {
                    toast.success('Deleted Successfully...')
                    setSelectedIncludes((prevInclude) => {
                        const updatedSelectedIncludes = prevInclude.filter((_, index) => index !== deleteIndex)
                        return updatedSelectedIncludes
                    })

                    setIncludesDetails((prevIncludeDetails) => {
                        const updatedIncludeDetails = prevIncludeDetails.filter(
                            (_, index) => index !== deleteIndex
                        )
                        return updatedIncludeDetails
                    })
                }
            })
    }

    function cancelResortPriceEdit()
    {
      setStartDate('')
      setEndDate('')
      setAmount('')
      setIsEditingTiming(false);
      setEditingTimingId(0);
      setEditingTimingIndex(-1);
    }

    function addResortPricing() {
      if (startDate == '') {
        toast.error('Please select start month')
      } else if (endDate == '') {
        toast.error('Please select end month')
      } else if (amount == '') {
        toast.error('Please enter the amount')
      } else {
        if(isEditingTiming)
        {
          const formData = {start_month: startDate, end_month: endDate, amount: amount, id: editingTimingId}
          axios
            .post('https://admin.trawelmart.com/admin/public/index.php/api/updateResortTiming', formData)
            .then((response) => {
              if (response.status == 200) {
                if (response.data.status) {
                  console.log(response.data);
                  toast.success('Timing updated successfully..')
                  const updatedResortPricing = [...resortDates];
                  updatedResortPricing[editingTimingIndex].start_month = response.data.timing.start_month;
                  updatedResortPricing[editingTimingIndex].end_month = response.data.timing.end_month;
                  updatedResortPricing[editingTimingIndex].amount = response.data.timing.amount;
                  setResortDates(updatedResortPricing)
                  cancelResortPriceEdit();
                }
              }
            })
        }
        else
        {
          const formData = {start_month: startDate, end_month: endDate, amount: amount}
          axios
            .post('https://admin.trawelmart.com/admin/public/index.php/api/addResortTiming', formData)
            .then((response) => {
              if (response.status == 200) {
                if (response.data.status) {
                  toast.success('Timing added successfully..')
                  setResortDates((prevResortDates) => [...prevResortDates, response.data.timing])
                  setSelectedResortDateIds((prevResortDateIds) => [
                    ...prevResortDateIds,
                    response.data.timing.id,
                  ])
                  setStartDate('')
                  setEndDate('')
                  setAmount('')
                }
              }
            })
        }
      }
    }

    function deletePricing(id: any, deleteIndex: any) {
        axios
            .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteDaywiseDetails/' + id)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.status) {
                        cancelResortPriceEdit();
                        setResortDates((prevResortDate) => {
                            const updatedDates = prevResortDate.filter((_, index) => index !== deleteIndex)
                            return updatedDates
                        })

                        setSelectedResortDateIds((prevDateIds) => {
                            const updatedDateIds = prevDateIds.filter((_, index) => index !== deleteIndex)
                            return updatedDateIds
                        })
                    }
                }
            })
    }

    function editResort(editIndex: number, id: number)
    {
      setIsEditingResort(true);
      setEditingResortId(resorts[editIndex].id);
      setEditingResortIndex(editIndex);
      setResortName(resorts[editIndex].resort_name);
      setResortOccuppancy(resorts[editIndex].resort_occupancy);
      setResortRating(resorts[editIndex].resort_rating);
      setResortRoomSize(resorts[editIndex].resort_room_size);
      setResortVillaCount(resorts[editIndex].resort_villa_count);
    }

    function editInfo(editIndex: number, id: number)
    {
      setIsEditingInfo(true);
      setEditingInfoId(infoData[editIndex].id);
      setEditingInfoIndex(editIndex);
      setInfoTitle(infoData[editIndex].title);
      setInfoDescription(infoData[editIndex].description);
    }

    return (
        <>
      <div className=' mb-5 mb-xl-10 mt-16'>
        <div className=''>
          {/* begin::Table container */}
          <div className='row lg:pr-3 px-4 mt-4'>
            <div className='col-lg-8'>
              <form>
                <div
                  className='card1 bg-white rounded mb-5 mb-xl-6 px-6'
                  id='kt_profile_details_view'
                >
                  <div className='row mb-9 pb-0 p-4 '>
                    <div className='col-lg-6 fv-row pr-4 pb-4 pt-4'>
                      <label className='text-lg pb-2 font-medium text-slate-500'>Country</label>
                      <select
                        className='form-control mb-3 mb-lg-0 '
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option value=''>Select Country</option>
                        {countriesData.map((country) => (
                          <option value={country.name} key={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-lg-6 fv-row pt-4'>
                      <label className=' text-lg pb-2 font-medium text-slate-500'>City</label>
                      <input
                        type='text'
                        className='border-black form-control   mb-3 mb-lg-0'
                        placeholder=''
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>

                    <div className='col-lg-12 mt-3'>
                      <label className='text-lg font-medium pb-2 text-slate-500'>Title</label>
                      <input
                        className='form-control mb-3 mb-lg-0 '
                        value={title}
                        placeholder=''
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    <div className='col-lg-12 pt-6'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <label className=' text-lg pb-2 font-medium text-slate-500'>Slug</label>
                          <input
                            type='text'
                            className='border-black form-control   mb-3 mb-lg-0'
                            placeholder=''
                            value={slug}
                            onChange={(e) => setSlug(e.target.value)}
                          />
                        </div>
                        <div className='mt-3 flex'>
                          <div className='text-teal-600 bg-rose-100 px-2 py-1 flex '>
                            <div className=''>
                              {/* <i className='las la-info-circle text-lime-500'></i> */}
                              <img
                                src='https://i.ibb.co/NrZ2L2C/R.png'
                                className='h-4 w-4'
                                alt=''
                              />
                            </div>
                            <span className='text-teal-600 px-2'>trawelmart.com/{slug}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=''>
                      <div className='col-lg-12 pt-6'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            <label className='text-lg font-medium pb-2 text-slate-500'>Days</label>
                            <input
                              type='number'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder=''
                              value={days}
                              onChange={(e) => setDays(parseInt(e.target.value, 10))}
                            />
                          </div>

                          <div className='col-lg-4 pt-1'>
                            <label className=' text-base pb-2 font-medium text-slate-500'>
                              Number of Guests Travelled
                            </label>
                            <input
                              type='number'
                              className='border-black form-control   mb-2 mb-lg-0'
                              value={visitorCount}
                              onChange={(e) => setVisitorCount(parseInt(e.target.value, 10))}
                            />
                          </div>

                          <div className='col-lg-4 fv-row pl-2'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>Type</label>
                            <select
                              className='form-control border-black mb-3 mb-lg-0 '
                              value={tourType}
                              onChange={(e) => setTourType(e.target.value)}
                            >
                              <option value='' selected hidden disabled>
                                Select
                              </option>
                              <option value='Standard'>Standard</option>
                              <option value='Luxury'>Luxury</option>
                              <option value='Premium'>Premium</option>
                              <option value='Group'>Group</option>
                            </select>
                          </div>

                          <div className=''>
                            <div className='col-lg-15'>
                              <div className='row'>
                                <div className='col-lg-15 fv-row px-4 mt-8'>
                                  <label className='pb-2 text-lg font-medium text-slate-500'>
                                    Night Description
                                  </label>
                                  <input
                                    type='text'
                                    className='border-black form-control   mb-3 mb-lg-0'
                                    placeholder=''
                                    value={nightDescription}
                                    onChange={(e) => setNightDescription(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className='row'>
                                <div className='col-lg-12 flex fv-row px-4 mt-6'>
                                  <label className='col-lg-3 pt-3 text-2xl font-medium text-slate-500 fs-6'>
                                    Is Flight Included?
                                  </label>
                                  <div className='col-lg-12 pt-4 -ml-6'>
                                    <input
                                      id='yes'
                                      type='radio'
                                      name='islandLanding'
                                      className='form-check-inline'
                                      checked={isFlightIncluded}
                                      onChange={(e) => setIsFlightIncluded(true)}
                                    />
                                    <label htmlFor='yes' className='text-slate-600'>
                                      Yes
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                      id='no'
                                      type='radio'
                                      name='islandLanding'
                                      className='form-check-inline'
                                      checked={!isFlightIncluded}
                                      onChange={(e) => setIsFlightIncluded(false)}
                                    />
                                    <label htmlFor='no' className='text-slate-600'>
                                      No
                                    </label>
                                  </div>
                                </div>
                                {/* <div className='col-lg-12 flex fv-row px-4 mt-6'>
                                  <label className='col-lg-3 pt-3 text-2xl font-medium text-slate-500 fs-6'>
                                    Is Cruise Included?
                                  </label>
                                  <div className='col-lg-12 pt-4 -ml-6'>
                                    <input
                                      id='yes'
                                      type='radio'
                                      name='isCruise'
                                      className='form-check-inline'
                                      checked={isCruise}
                                      onChange={(e) => setIsCruise(true)}
                                    />
                                    <label htmlFor='yes' className='text-slate-600'>
                                      Yes
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                      id='no'
                                      type='radio'
                                      name='isCruise'
                                      className='form-check-inline'
                                      checked={!isCruise}
                                      onChange={(e) => setIsCruise(false)}
                                    />
                                    <label htmlFor='no' className='text-slate-600'>
                                      No
                                    </label>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='card1 bg-white rounded mb-5 mb-xl-6 px-6'
                        id='kt_profile_details_view'
                      >
                        <div className='row mb-9 pb-0 p-4 '>
                          <div className='card-title pt-5'>
                            <h1 className=' pb-1 font-medium  text-xl  text-teal-600  mt-2'>
                              Island Banner Information
                            </h1>
                          </div>
                          <div className='border-b'></div>
                          <div className='col-lg-12 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>Title</label>
                            <input
                              type='text'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Title'
                              value={bannerTitle}
                              onChange={(e) => setBannerTitle(e.target.value)}
                            />
                          </div>
                          <div className='col-lg-12 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>Description</label>
                            <textarea
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Description'
                              value={bannerDescription}
                              onChange={(e) => setBannerDescription(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='card1 bg-white rounded mb-5 mb-xl-6 px-6'
                        id='kt_profile_details_view'
                      >
                        <div className='row mb-9 pb-0 p-4 '>
                          {/* <div className='col-lg-12'>
                            <div className='row'>
                              <div className='col-lg-12 flex fv-row px-4 mt-4'>
                                <label className='col-lg-2 text-2xl font-medium text-slate-500 fs-6'>
                                  Is Resort?
                                </label>
                                <div className='col-lg-4 pt-0 ml-13'>
                                  <input
                                    id='yes_1'
                                    type='radio'
                                    name='isResort'
                                    className='form-check-inline'
                                    checked={isResort}
                                    onChange={(e) => setIsResort(true)}
                                  />
                                  <label htmlFor='yes_1' className='text-slate-600'>
                                    Yes
                                  </label>
                                  &nbsp;&nbsp;&nbsp;
                                  <input
                                    id='no_1'
                                    type='radio'
                                    name='isResort'
                                    className='form-check-inline'
                                    checked={!isResort}
                                    onChange={(e) => setIsResort(false)}
                                  />
                                  <label htmlFor='no_1' className='text-slate-600'>
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className='card-title pt-5'>
                            <h1 className=' pb-1 font-medium  text-xl  text-teal-600  mt-2'>
                              Resort Timing Information
                            </h1>
                          </div>
                          <div className='border-b'></div>
                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>From</label>
                            <input
                              type='month'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Starting Month'
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>
                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>To</label>
                            <input
                              type='month'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Starting Month'
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>
                          <div className='col-lg-12 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Amount
                            </label>
                            <input
                              type='number'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Amount'
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </div>
                          <div className='pt-12'>
                            <button
                              type='button'
                              className='btn btn-primary mb-4 w-32 py-2'
                              onClick={addResortPricing}
                            >
                              {isEditingTiming ? 'Update' : 'Add'}
                            </button>
                          </div>
                          <div className='py-3 pt-6'>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                                <thead>
                                  <tr className='font-medium text-slate-600 bg-rose-100'>
                                    <th className='min-w-40px px-4 font-medium'>Start Month</th>
                                    <th className='min-w-40px px-4 font-medium'>End Month</th>
                                    <th className='min-w-150px font-medium'> Amount </th>
                                    <th className='min-w-20px font-medium'>Edit </th>
                                    <th className='min-w-20px font-medium'>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {resortDates.map((item, index) => (
                                    <tr>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).start_month}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).end_month}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).amount}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => editTiming(index, (item as any).id)}
                                          >
                                            <i
                                              className='fas fa-pencil text-primary'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => deletePricing((item as any).id, index)}
                                          >
                                            <i
                                              className='fas fa-trash text-danger'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Resort Information */}

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='card1 bg-white rounded  mb-5 mb-xl-8 px-6'
                        id='kt_profile_details_view'
                      >
                        <div className='row mb-9 pb-0 p-4 '>
                          <div className='card-title lg:pt-6'>
                            <h1 className='pt-2 pb-2 font-medium  text-xl  text-teal-600'>
                              Resort Information
                            </h1>
                          </div>
                          <div className='border-b'></div>
                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Resort Name
                            </label>
                            <input
                              type='text'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder='Resort Name'
                              value={resortName}
                              onChange={(e) => setResortName(e.target.value)}
                            />
                          </div>
                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Rating
                            </label>
                            <input
                              type='number'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Rating'
                              min={1}
                              max={5}
                              value={resortRating}
                              onChange={(e) => setResortRating(e.target.value)}
                            />
                          </div>

                          <div className='col-lg-4 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Occupancy
                            </label>
                            <input
                              type='text'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder='Occupancy'
                              value={resortOccuppancy}
                              onChange={(e) => setResortOccuppancy(e.target.value)}
                            />
                          </div>


                          <div className='col-lg-4 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              No of Villas
                            </label>
                            <input
                              type='text'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder='No. of Villas'
                              value={resortVillaCount}
                              onChange={(e) => setResortVillaCount(e.target.value)}
                            />
                          </div>


                          <div className='col-lg-4 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Room size
                            </label>
                            <input
                              type='text'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder='Room Size'
                              value={resortRoomSize}
                              onChange={(e) => setResortRoomSize(e.target.value)}
                            />
                          </div>

                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Resort Image
                            </label>
                            <input
                              type='file'
                              id='resortFileInput'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder=''
                              onChange={handleResortFileChange}
                            />
                          </div>

                          <div className='pt-12'>
                            <button
                              type='button'
                              className='btn btn-primary w-32 py-2'
                              onClick={addResort}
                            >
                              {isEditingResort ? 'Update' : 'Add'}
                            </button>
                          </div>
                          <div className='py-3 pt-6'>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                                <thead>
                                  <tr className='font-medium text-slate-600 bg-rose-100'>
                                    <th className='min-w-40px font-medium'>Resort Name </th>
                                    <th className='min-w-40px font-medium'>Rating</th>
                                    <th className='min-w-40px font-medium'>Occupancy </th>
                                    <th className='min-w-40px font-medium'>No of Villas </th>
                                    <th className='min-w-40px font-medium'>Room Size</th>
                                    <th className='min-w-40px font-medium'>Image</th>
                                    <th className='min-w-20px font-medium'>Edit</th>
                                    <th className='min-w-20px font-medium'>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {resorts.map((item, index) => (
                                    <tr>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).resort_name}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            {(item as any).resort_rating}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            {(item as any).resort_occupancy}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            {(item as any).resort_villa_count}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            {(item as any).resort_room_size}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            {(item as any).image ? 
                                              <a
                                                href={
                                                  'https://admin.trawelmart.com/admin/public/' +
                                                  (item as any).image
                                                }
                                                target='_blank'
                                              >
                                                View Image
                                              </a>
                                            :
                                              'No Image'
                                            }
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => editResort(index, (item as any).id)}
                                          >
                                            <i
                                              className='fas fa-pencil text-primary'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => deleteResort((item as any).id, index)}
                                          >
                                            <i
                                              className='fas fa-trash text-danger'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

               

                {/* Tour Information */}

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='card1 bg-white rounded  mb-5 mb-xl-8 px-6'
                        id='kt_profile_details_view'
                      >
                        <div className='row mb-9 pb-0 p-4 '>
                          <div className='card-title lg:pt-6'>
                            <h1 className='pt-2 pb-2 font-medium  text-xl  text-teal-600'>
                              Tour Information
                            </h1>
                          </div>
                          <div className='border-b'></div>
                          <div className='col-lg-12 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Title
                            </label>
                            <input
                              type='text'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder='About This Tour'
                              value={infoTitle}
                              onChange={(e) => setInfoTitle(e.target.value)}
                            />
                          </div>

                          <div className='pb-12 pt-8 px-2'>
                            <h6 className=' pb-2 font-medium text-slate-500'>Description</h6>
                            <ReactQuill
                              className='h-60'
                              value={infoDescription}
                              onChange={handleChange}
                              modules={{
                                toolbar: [
                                  [{header: '1'}, {header: '2'}, {font: []}],
                                  ['bold', 'italic', 'underline'],
                                  [{list: 'ordered'}, {list: 'bullet'}],
                                  ['link', 'image'],
                                  [{align: []}],
                                  ['clean'],
                                  ['blockquote'],
                                  [{size: ['small', false, 'large', 'huge']}], // Font size options
                                ],
                              }}
                            />
                            {/*<div className='output pt-32'>
                      <h3>Preview:</h3>
                      <div dangerouslySetInnerHTML={{__html: editorHtml}} />
                    </div>*/}
                          </div>
                          <div className='pt-12'>
                            <button
                              type='button'
                              className='btn btn-primary w-32 py-2'
                              onClick={addInfo}
                            >
                              {isEditingInfo ? 'Update' : 'Add'}
                            </button>
                          </div>
                          <div className='py-3 pt-6'>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                                <thead>
                                  <tr className='font-medium text-slate-600 bg-rose-100'>
                                    <th className='min-w-40px px-4 font-medium'>Title</th>
                                    <th className='min-w-150px font-medium'> Description </th>
                                    <th className='min-w-20px font-medium'>Edit</th>
                                    <th className='min-w-20px font-medium'>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {infoData.map((item, index) => (
                                    <tr>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).title}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: truncateDescription(item.description, 40),
                                              }}
                                              title={item.description}
                                            ></div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => editInfo(index, (item as any).id)}
                                          >
                                            <i
                                              className='fas fa-pencil text-primary'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => deleteInfoData((item as any).id, index)}
                                          >
                                            <i
                                              className='fas fa-trash text-danger'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='pt-6 pb-12 text-left'>
                    <button
                      type='button'
                      className='btn btn-primary mx-2 w-52 py-2'
                      onClick={UpdateIsland}
                    >
                      Update Details
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className='col-lg-4 '>
              <div className='col-lg-12'>

                <div
                  className='card1 bg-white rounded  mb-5 mb-xl-6 px-6 mt-2 pt-0 pb-6'
                  id='kt_profile_details_view'
                >
                  <div className='card-title pt-1 border-b mb-5'>
                    <h1 className='text-lg font-medium text-slate-500 pb-2 pt-3'>Upload Photos</h1>
                  </div>
                  <div className='flex'>
                    <div>
                      <input
                        id='photosInputFiles'
                        type='file'
                        className='border-black form-control mb-3 mb-lg-0'
                        multiple
                        onChange={handleFileChange}
                        placeholder=''
                      />
                    </div>
                    <div>
                      <button
                        className='btn btn-primary ml-5 my-0 mt-1 py-2'
                        type='button'
                        onClick={handleUpload}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    {selectedFilesIds.map((item, index) => (
                      <div className='col-md-3 text-center mb-6'>
                        <a href='#!' onClick={() => deleteImages(index)}>
                          <i
                            className='las la-times'
                            style={{
                              fontSize: '12px',
                              marginTop: '10px',
                              color: 'black',
                              marginLeft: '60px',
                              border: 'white 4px solid',
                              backgroundColor: '#ffffff',
                              borderRadius: '10px',
                              zIndex: '50',
                              position: 'relative', // Added position: relative
                            }}
                          ></i>
                        </a>
                        <a
                          href={'https://admin.trawelmart.com/admin/public/' + item}
                          key={index}
                          target='_blank'
                        >
                          <img
                            src={'https://admin.trawelmart.com/admin/public/' + item}
                            style={{
                              maxWidth: '75px',
                              width: '100%',
                              marginTop: '-11px',
                              marginLeft: '0px',
                              height: '50px',
                              border: '#dddee1 4px solid',
                              gap: '10px',
                              // minWidth: '60px',
                            }}
                            key={index}
                            alt={'Image ' + index}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    )
}
export { EditIsland }
