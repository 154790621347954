/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {Modal, Toast} from 'react-bootstrap'
import {toast} from 'react-toastify'
import { error } from 'console'
// import 'bootstrap/dist/css/bootstrap.min.css';

type Props = {
  className: string
}

const TablesWidget5: React.FC<Props> = ({className}) => {
  const [upcomingTours, setUpcomingTours] = useState([])
  const [completedTours, setCompletedTours] = useState([])
  const [customerName, setCustomerName] = useState('')
  const [openBookingId, setOpenBookingId] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const {id} = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedItemId, setSelectedItemId] = useState(null)
  const [pricingData, setPricingData] = useState(null)

  function ircloseModal() {
    setSelectedItemId(null)
    setPricingData(null)
  }

  const iropenModal = async (itemId: any) => {
    await setSelectedItemId(itemId)
  }

  const fetchPriceData = async (itemId: any) => {
    try {
      const response = await axios.get(
        'https://admin.trawelmart.com/admin/public/index.php/api/getPricingListByPackageForWebsite/' +
          itemId
      )
      if (response.status == 200) {
        const data = response.data
        setPricingData(data)
      }
    } catch (error) {
      console.error('Error fetching additional data:', error)
      throw error // You might want to handle the error appropriately in your application
    }
  }

  const formatPrice = (price: string) => {
    // Convert the input string to a number
    const amount = parseFloat(price.replace(/,/g, ''))

    // Ensure amount is a valid number
    if (isNaN(amount)) {
      return 'Invalid input'
    }

    // Use toLocaleString to format the number with Indian numbering system
    const formattedAmount = amount.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    return formattedAmount
  }

  useEffect(() => {
    const fetchData = async () => {
      if (selectedItemId !== null) {
        await fetchPriceData(selectedItemId)
      }
    }

    fetchData()
  }, [selectedItemId])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (option: string) => {
    setSelectedOption(option)
    setIsOpen(false) // Close the dropdown after selecting an option
  }

  const handleBookingDetailsClick = (index: any) => {
    setOpenBookingId((prevId) => (prevId === index ? null : index))
  }

  const handleCheckPaymentStatus = (order_id: any) => {
    const formData = { ref: order_id }
    axios.post("https://admin.trawelmart.com/admin/public/index.php/api/check-payment-status", formData)
    .then((response) => {
      if(response.status == 200)
      {
        if(response.data.status)
        {
          toast.error(response.data.error_desc)
        }
        else
        {
          response.data.order_bank_response == "Transaction is Successful" ? toast.success(response.data.order_bank_response) : toast.error(response.data.order_bank_response);
        }
      }
    })
  }

  useEffect(() => {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/getUpcomingTours/' + id)
      .then((response) => {
        if (response.status == 200) {
          setUpcomingTours(response.data.upcoming_tours)
        }
      })

    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/getCompletedTours/' + id)
      .then((response) => {
        if (response.status == 200) {
          setCompletedTours(response.data.completed_tours)
        }
      })

    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/getUserProfile/' + id)
      .then((response) => {
        console.log(response.data)
        if (response.status == 200) {
          if (response.data.status) {
            setCustomerName(response.data.user.name)
          }
        }
      })
  }, [])

  function updateTourStatus(tour_id: number) {
    const formData = {id: tour_id, status: selectedOption}

    axios
      .post('https://admin.trawelmart.com/admin/public/index.php/api/updateTourStatus', formData)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            toast.success(response.data.message)
          } else {
            toast.error(response.data.message)
          }
        }
      })
  }

  return (
    <>
      <div className={`card1 bg-white rounded p-9 ${className}`}>
        {/* begin::Header */}
        {upcomingTours.map((item: any, index: number) => (
          <>
            <div className='card-header border-0 my-5'>
              <div className='col-lg-12'>
                <div className='card1 border-neutral-300 rounded p-5'>
                  <div className=' h-8  rounded text-sm uppercase font1 text-orange-500 font-semibold'>
                    {item.package.tour_type}
                  </div>
                  <h5 className='font-semibold text-lg1 text-teal-600 mt-1 mb-5 '>
                    <a
                      href={'https://trawelmart.com/' + item.package.slug}
                      target='_blank'
                      className='text-black text-xl'
                    >
                      {item.package.tour_title}
                      <span>
                        <i className='las la-external-link-alt text-black pl-2 font-bold text-2xl'></i>
                      </span>
                    </a>
                  </h5>
                  <div className='text-left col-lg-12 xl:mt-4 md:mt-4 mt-2 items-center xl:gap-12'>
                    <div className='col-lg-12 flex'>
                      <div className='col-lg-2 border-r p-1 '>
                        <h4 className='font1 font-semibold text-slate-600 text-base1'>Booked on</h4>
                        <div className='font-medium text-slate-500 font text-sm'>
                          {new Intl.DateTimeFormat('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          }).format(new Date(item.checkout.created_at))}
                        </div>
                      </div>
                      <div className='col-lg-3 border-r p-1 ml-2'>
                        <h4 className='font1 font-semibold text-slate-600 text-base1'>
                          Start Date
                        </h4>
                        <div className='font-medium text-slate-500 font text-sm'>{`${moment(
                          item.checkout.check_in_date
                        ).format('MMM D')}`}</div>
                      </div>
                      <div className='col-lg-3 border-r p-1 ml-2'>
                        <h4 className='font1 font-semibold text-slate-600 text-base1'>End Date</h4>
                        <div className='font-medium text-slate-500 font text-sm'>{`${moment(
                          item.checkout.check_out_date
                        ).format('MMM D')}`}</div>
                      </div>
                      {/* <div className='col-lg-3 xl:border-r lg:border-r md:border-r p-1 ml-1'>
                      <h4 className='font1 font-medium text-slate-500 text-base1'></h4>
                      <div className='font-medium text-slate-500 font text-sm'></div>
                    </div> */}
                      <div className='col-lg-6 flex ml-4'>
                        <button
                          className='ml-3 text-white bg-orange-400 px-5 font-medium  text-base h-12 ml-2 rounded'
                          onClick={() => handleBookingDetailsClick(item.checkout.id)}
                        >
                          Booking Details
                        </button>
                        <a
                          href={
                            'https://admin.trawelmart.com/admin/public/index.php/api/downloadInvoice/' +
                            item.checkout.id
                          }
                          style={{color: 'white'}}
                          target='_blank'
                        >
                          <button className='ml-3 text-white bg-primary px-5 font-medium  text-base h-12 ml-2 rounded '>
                            Download
                          </button>
                        </a>
                        {item.checkout.order_id && item.checkout.bank_ref && 
                          (<button
                            className='ml-3 text-black bg-secondary px-5 font-medium  text-base h-12 ml-2 rounded'
                            onClick={(e) => handleCheckPaymentStatus(item.checkout.order_id)}
                          >
                            Check Payment Status
                          </button>)
                        }
                      </div>
                    </div>
                  </div>
                  {/* Render the new card1 type box below if showBookingDetails is true */}
                </div>
              </div>
            </div>
            {openBookingId === item.checkout.id && (
              <div className='card1 flex col-lg-12 bg-white rounded p-5 mt-4'>
                {/* Content for the new card goes here */}
                <div className='col-lg-12 flex'>
                  <div className='col-lg-8'>
                    <div className='uppercase font1 text-orange-500 font-semibold text-sm mr-3 pt-2'>Upcoming tours</div>
                    <div className=' h-8  rounded text-sm uppercase font1 text-orange-500 font-semibold'>
                      {item.package.tour_type}
                    </div>
                    <h5 className='font-semimbold text-lg1 text-teal-600 mt-1 mb-5'>
                      <a
                        href={'https://trawelmart.com/' + item.package.slug}
                        target='_blank'
                        className='text-black text-xl'
                      >
                        {item.package.tour_title}
                        <span>
                          <i className='las la-external-link-alt text-black pl-2 font-bold text-2xl'></i>
                        </span>
                      </a>
                    </h5>
                    <div className='col-lg-12'>
                      <div className='flex'>
                        <div className='col-lg-3 border-r p-1 '>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            Booked on
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {new Intl.DateTimeFormat('en-US', {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                            }).format(new Date(item.checkout.created_at))}
                          </div>
                        </div>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            Start Date
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>{`${moment(
                            item.checkout.check_in_date
                          ).format('MMM D')}`}</div>
                        </div>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            End Date
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>{`${moment(
                            item.checkout.check_out_date
                          ).format('MMM D')}`}</div>
                        </div>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>Status</h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {item.checkout.status}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 mt-6'>
                      <div className='flex'>
                        <div className='col-lg-6 border-r p-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>Guests</h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {item.checkout.adult} Adults | {item.checkout.children} Childrens |{' '}
                            {item.checkout.infant} Infants
                          </div>
                        </div>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>Rooms</h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {item.checkout.total_rooms}{' '}
                          </div>
                        </div>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            Departure
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {item.checkout.starting_place}{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 mt-6'>
                      <div className='flex'>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            Booking Person
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {' '}
                            {customerName}
                          </div>
                        </div>
                        <div className='col-lg-3 p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            Form Number
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            TWT000{item.checkout.id}
                          </div>
                        </div>
                        <div className='col-lg-3 p-1 '>
                          <div className=''>
                            <h4 className='font1 font-semibold text-slate-600 text-base1'>
                              Change Status
                            </h4>
                            <div className='col-lg-12 flex items-center justify-between'>
                              <select
                                className='col-lg-12 bg-white border-neutral-300 rounded'
                                onChange={(e) => handleOptionClick(e.target.value)}
                              >
                                <option>Select a Status</option>
                                <option value='Booked'> Booked </option>
                                <option value='Under Process'> Under Process </option>
                                <option value='Booking Confirmed'> Booking Confirmed </option>
                                <option value='On Hold/Cancel'> On Hold/Cancel </option>
                              </select>
                              <button
                                className='ml-4 bg-primary px-12 py-2 rounded text-white text-base font-medium'
                                type='button'
                                onClick={() => updateTourStatus(item.checkout.id)}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          {/* <h4
                            onClick={toggleDropdown}
                            className='cursor-pointer font1 font-semibold text-slate-600 text-base1'
                          >
                            Status <i className='fa fa-angle-down'></i>
                          </h4>
                          {isOpen && (
                            <div className=''>
                              <ul className=' cursor-pointer list-decimal'>
                                <li
                                  className='text-slate-500'
                                  onClick={() => handleOptionClick('Booked')}
                                >
                                  Booked
                                </li>
                                <li
                                  className='text-slate-500'
                                  onClick={() => handleOptionClick('Under Process')}
                                >
                                  Under Process
                                </li>
                                <li
                                  className='text-slate-500'
                                  onClick={() => handleOptionClick('Booking Confirmed')}
                                >
                                  Booking Confirmed
                                </li>
                                <li
                                  className='text-slate-500'
                                  onClick={() => handleOptionClick('On Hold/Cancel')}
                                >
                                  On Hold/Cancel
                                </li>
                              </ul>
                            </div>
                          )}
                          {selectedOption && (
                            <div className='font-medium text-slate-500 font text-sm'>
                              Selected Option: {selectedOption}
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 mt-6'>
                      <div className='flex'>
                        <div className='col-lg-3 p-1 ml-1'>
                          <h4 className='font1 font-medium text-slate-500 text-base1'></h4>
                          <div className='font-medium text-slate-500 font text-sm'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 card1 rounded p-5'>
                    <div className='font-semibold underline  text-xl  text-teal-600 '>Payment</div>
                    <div className='flex justify-between'>
                      <div className=''>
                        <h4 className='font1 font-semibold text-slate-600 mt-6 text-base1 '>
                          Total Amount
                        </h4>
                        <div className='font-bold text-black font text-lg pt-1'>
                          ₹{item.checkout.total_amount}
                        </div>
                      </div>
                      <div>
                        <h4 className='font1 font-semibold text-slate-600 mt-6 text-base1'>
                          Amount Paid
                        </h4>
                        <div className='font-bold text-green font text-lg pt-1'>
                          ₹{item.checkout.paid_amount ? item.checkout.paid_amount : '0'}
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-12 flex mt-6 justify-between items-center'>
                      <div>
                        <div className='font-semibold'>Balance Amount</div>
                        <div className=' text-danger font-extrabold text-3xl -pt-2'>
                          ₹
                          {item.checkout.paid_amount
                            ? item.checkout.total_amount - item.checkout.paid_amount
                            : item.checkout.total_amount}
                        </div>
                      </div>

                      <div className='mt-4 text-center'>
                        {/* <a href="" className='text-teal-500 underline cursur-pointer'>View Price Breakup</a> */}
                        <div>
                          <p
                            className='bg-primary px-4 py-2 text-white rounded text-base font-semibold'
                            onClick={() => iropenModal((item as any).package.id)}
                          >
                            Price Breakup
                          </p>
                          <Modal
                            show={selectedItemId == (item as any).package.id}
                            onHide={ircloseModal}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Trawel Mart's Detailed Tour Price</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <h2 className='font-semibold text-base pb-2'>
                                Prices & discounts are Per Person for Indian Nationals only.
                              </h2>

                              <table key='table' className='table'>
                                <table
                                  className='rounded-2xl'
                                  style={{width: '560px', border: '1px solid #d9d7d7'}}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          border: '1px solid #d9d7d7',
                                          padding: '10px',
                                          fontSize: '13px',
                                          fontWeight: '500',
                                          textAlign: 'left',
                                          width: '230px',
                                          backgroundColor: '#f9f9f9',
                                        }}
                                      >
                                        Premium Packages
                                      </th>
                                      <th
                                        style={{
                                          border: '1px solid #d9d7d7',
                                          padding: '10px',
                                          fontSize: '13px',
                                          fontWeight: '500',
                                          textAlign: 'center',
                                          backgroundColor: '#f9f9f9',
                                        }}
                                      >
                                        Price
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          border: '1px solid #d9d7d7',
                                          padding: '10px',
                                          fontSize: '13px',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                          backgroundColor: '#f9f9f9',
                                          width: '230px',
                                        }}
                                      >
                                        Adult
                                      </td>
                                      <td
                                        style={{
                                          border: '1px solid #d9d7d7',
                                          padding: '10px',
                                          fontWeight: '500',
                                          fontSize: '14px',
                                          textAlign: 'center',
                                          backgroundColor: '#f9f9f9',
                                        }}
                                      >
                                        {pricingData
                                          ? formatPrice((pricingData as any).adult)
                                          : '0'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          border: '1px solid #d9d7d7',
                                          padding: '10px',
                                          fontSize: '13px',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                          width: '210px',
                                          backgroundColor: 'rgb(249, 249, 249)',
                                        }}
                                      >
                                        Child With Bed
                                      </td>
                                      <td
                                        style={{
                                          border: '1px solid #d9d7d7',
                                          padding: '10px',
                                          fontWeight: '500',
                                          fontSize: '14px',
                                          textAlign: 'center',
                                          backgroundColor: '#f9f9f9',
                                        }}
                                      >
                                        {pricingData
                                          ? formatPrice((pricingData as any).child)
                                          : '0'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          border: '1px solid #d9d7d7',
                                          padding: '10px',
                                          fontSize: '13px',
                                          fontWeight: '400',
                                          textAlign: 'left',
                                          backgroundColor: '#f9f9f9',
                                          width: '230px',
                                        }}
                                      >
                                        Child Without Bed
                                      </td>
                                      <td
                                        style={{
                                          border: '1px solid #d9d7d7',
                                          padding: '10px',
                                          fontWeight: '500',
                                          fontSize: '14px',
                                          textAlign: 'center',
                                          backgroundColor: '#f9f9f9',
                                        }}
                                      >
                                        {pricingData
                                          ? formatPrice((pricingData as any).infant)
                                          : '0'}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </table>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                type='button'
                                className='bg-danger text-white px-4 py-2 rounded'
                                onClick={ircloseModal}
                              >
                                Close
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
        {completedTours.map((item: any, index: number) => (
          <>
            <div className='card-header border-0 my-5'>
              <div className='col-lg-12'>
                <div className='card1 border-neutral-300 rounded p-5'>
                  <div className=' h-8  rounded text-sm uppercase font1 text-orange-500 font-semibold'>
                    {item.package.tour_type}
                  </div>
                  <h5 className='font-semibold  mt-1 mb-5 '>
                    <a
                      href={'https://trawelmart.com/' + item.package.slug}
                      target='_blank'
                      className='text-black text-xl'
                    >
                      {item.package.tour_title}
                      <span>
                        <i className='las la-external-link-alt text-black pl-2 font-bold lg:text-2xl'></i>
                      </span>
                    </a>
                  </h5>
                  <div className='text-left col-lg-12 xl:mt-4 md:mt-4 mt-2 items-center xl:gap-12'>
                    <div className='col-lg-12 flex'>
                      <div className='col-lg-2 border-r p-1 '>
                        <h4 className='font1 font-semibold text-slate-600 text-base1'>Booked on</h4>
                        <div className='font-medium text-slate-500 font text-sm'>
                          {new Intl.DateTimeFormat('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          }).format(new Date(item.checkout.created_at))}
                        </div>
                      </div>
                      <div className='col-lg-3 border-r p-1 ml-2'>
                        <h4 className='font1 font-semibold text-slate-600 text-base1'>
                          Start Date
                        </h4>
                        <div className='font-medium text-slate-500 font text-sm'>{`${moment(
                          item.checkout.check_in_date
                        ).format('MMM D')}`}</div>
                      </div>
                      <div className='col-lg-3 border-r p-1 ml-2'>
                        <h4 className='font1 font-semibold text-slate-600 text-base1'>End Date</h4>
                        <div className='font-medium text-slate-500 font text-sm'>{`${moment(
                          item.checkout.check_out_date
                        ).format('MMM D')}`}</div>
                      </div>
                      {/* <div className='col-lg-3 xl:border-r lg:border-r md:border-r p-1 ml-1'>
                      <h4 className='font1 font-medium text-slate-500 text-base1'></h4>
                      <div className='font-medium text-slate-500 font text-sm'></div>
                    </div> */}
                      <div className='col-lg-6 flex ml-3'>
                        <button
                          className='ml-3 text-white bg-orange-400 px-5 h-12 ml-2 rounded font-medium text-base'
                          onClick={() => handleBookingDetailsClick(item.checkout.id)}
                        >
                          Booking Details
                        </button>
                        <button className='ml-3 text-white bg-primary px-5 h-12 ml-2 rounded font-medium text-base'>
                          <a
                            href={
                              'https://admin.trawelmart.com/admin/public/index.php/api/downloadInvoice/' +
                              item.checkout.id
                            }
                            style={{color: 'white'}}
                            target='_blank'
                          >
                            Download
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Render the new card1 type box below if showBookingDetails is true */}
                </div>
              </div>
            </div>
            {openBookingId === item.checkout.id && (
              <div className='card1 flex col-lg-12 bg-white rounded p-5 mt-4'>
                {/* Content for the new card goes here */}
                <div className='col-lg-12 flex'>
                  <div className='col-lg-8'>
                    <div className='flex'>
                      <div className='uppercase font1 text-orange-500 font-semibold text-sm mr-3 pt-2'>Completed tours</div>
                      <div className='w-24 h-8 border-orange-400 pt-2 pl-3 rounded text-sm uppercase font1 text-orange-500 font-semibold'>
                        {item.package.tour_type}
                      </div>
                    </div>
                    <h5 className='font-semibold text-lg1 text-teal-600 mt-1 mb-5 mt-2'>
                      <a
                        href={'https://trawelmart.com/' + item.package.slug}
                        target='_blank'
                        className='text-lg text-black'
                      >
                        {item.package.tour_title}
                        <span>
                          <i className='las la-external-link-alt text-black pl-2 font-bold lg:text-2xl'></i>
                        </span>
                      </a>
                    </h5>
                    <div className='col-lg-12'>
                      <div className='flex'>
                        <div className='col-lg-3 border-r p-1 '>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            Booked on
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {new Intl.DateTimeFormat('en-US', {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                            }).format(new Date(item.checkout.created_at))}
                          </div>
                        </div>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            Start Date
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>{`${moment(
                            item.checkout.check_in_date
                          ).format('MMM D')}`}</div>
                        </div>

                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            End Date
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>{`${moment(
                            item.checkout.check_out_date
                          ).format('MMM D')}`}</div>
                        </div>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>Status</h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {item.checkout.status}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 mt-6'>
                      <div className='flex'>
                        <div className='col-lg-3 border-r p-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>Guests</h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {item.checkout.adult} Adults | {item.checkout.children} Childrens |{' '}
                            {item.checkout.infant} Infants
                          </div>
                        </div>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>Rooms</h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {item.checkout.total_rooms}{' '}
                          </div>
                        </div>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            Departure
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {item.checkout.starting_place}{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 mt-6'>
                      <div className='flex'>
                        <div className='col-lg-3 border-r p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            Booking Person
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            {' '}
                            {customerName}
                          </div>
                        </div>
                        <div className='col-lg-3 p-1 ml-1'>
                          <h4 className='font1 font-semibold text-slate-600 text-base1'>
                            Form Number
                          </h4>
                          <div className='font-medium text-slate-500 font text-sm'>
                            TWT000{item.checkout.id}
                          </div>
                        </div>

                        <div className='col-lg-3 p-1 '>
                          <div className=''>
                            <h4 className='font1 font-semibold text-slate-600 text-base1'>
                              Change Status
                            </h4>
                            <div className='col-lg-12 flex items-center justify-between'>
                              <select
                                className='col-lg-12 bg-white border-neutral-300 rounded'
                                onChange={(e) => handleOptionClick(e.target.value)}
                              >
                                <option selected>Select a Status</option>
                                <option value='Booked'> Booked </option>
                                <option value='Under Process'> Under Process </option>
                                <option value='Booking Confirmed'> Booking Confirmed </option>
                                <option value='On Hold/Cancel'> On Hold/Cancel </option>
                              </select>
                              <button
                                className='ml-4 bg-primary px-12 py-2 rounded text-white text-base font-medium'
                                type='button'
                                onClick={() => updateTourStatus(item.checkout.id)}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* <div className='col-lg-3 p-1 ml-1'>
                          <h4
                            onClick={toggleDropdown}
                            className='font1 font-medium cursor-pointer text-slate-500 text-base1'
                          >
                            Status<i className='fa fa-angle-down'></i>
                          </h4>
                          {isOpen && (
                            <div className=''>
                              <ul className='xl:-ml-4'>
                                <li
                                  className='text-slate-500'
                                  onClick={() => handleOptionClick('Booked')}
                                >
                                  Booked
                                </li>
                                <li
                                  className='text-slate-500'
                                  onClick={() => handleOptionClick('Under Process')}
                                >
                                  Under Process
                                </li>
                                <li
                                  className='text-slate-500'
                                  onClick={() => handleOptionClick('Booking Confirmed')}
                                >
                                  Booking Confirmed
                                </li>
                                <li
                                  className='text-slate-500'
                                  onClick={() => handleOptionClick('On Hold/Cancel')}
                                >
                                  On Hold/Cancel
                                </li>
                              </ul>
                            </div>
                          )}
                          {selectedOption && (
                            <div className='font-medium text-slate-500 font text-sm'>
                              Selected Option: {selectedOption}
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div className='col-lg-12 mt-6'>
                      <div className='flex'>
                        <div className='col-lg-3 p-1 ml-1'>
                          <h4 className='font1 font-medium text-slate-500 text-base1'></h4>
                          <div className='font-medium text-slate-500 font text-sm'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 card1 rounded p-5'>
                    <div className='font-semibold underline  text-xl  text-teal-600 '>Payment</div>

                    <div className='flex justify-between'>
                      <div className=''>
                        <h4 className='font1 font-semibold text-slate-600 mt-6 text-base1 '>
                          Total Amount
                        </h4>
                        <div className='font-bold text-black font text-lg pt-1'>
                          ₹{item.checkout.total_amount}
                        </div>
                      </div>
                      <div>
                        <h4 className='font1 font-semibold text-slate-600 mt-6 text-base1 '>
                          Amount Paid
                        </h4>
                        <div className='font-bold text-black font text-lg pt-1'>
                          ₹{item.checkout.paid_amount ? item.checkout.paid_amount : '0'}
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-12 flex mt-6 items-center justify-between'>
                      <div className=''>
                        <div className='font-semibold'>Balance Amount</div>
                        <div className='text-danger font-extrabold text-3xl -pt-2'>
                          ₹
                          {item.checkout.paid_amount
                            ? item.checkout.total_amount - item.checkout.paid_amount
                            : item.checkout.total_amount}
                        </div>
                      </div>
                      <div className='text-center'>
                        {/* <a href="#!" className='text-teal-500 underline cursur-pointer'>View Price Breakup</a> */}
                        <p
                          className='bg-primary px-4 py-2 text-white rounded text-base font-semibold'
                          onClick={() => iropenModal((item as any).package.id)}
                        >
                          Price Breakup
                        </p>
                        <Modal
                          show={selectedItemId == (item as any).package.id}
                          onHide={ircloseModal}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Trawel Mart's Detailed Tour Price</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <h4 className='font-semibold pb-2 text-base'>
                              Prices & discounts are Per Person for Indian Nationals only.
                            </h4>
                            <table key='table' className='table'>
                              <table
                                className='rounded-2xl'
                                style={{width: '560px', border: '1px solid #d9d7d7'}}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        border: '1px solid #d9d7d7',
                                        padding: '10px',
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        textAlign: 'left',
                                        width: '230px',
                                        backgroundColor: '#f9f9f9',
                                      }}
                                    >
                                      Premium Packages
                                    </th>
                                    <th
                                      style={{
                                        border: '1px solid #d9d7d7',
                                        padding: '10px',
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        textAlign: 'center',
                                        backgroundColor: '#f9f9f9',
                                      }}
                                    >
                                      Price
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        border: '1px solid #d9d7d7',
                                        padding: '10px',
                                        fontSize: '13px',
                                        fontWeight: '400',
                                        textAlign: 'left',
                                        backgroundColor: '#f9f9f9',
                                        width: '230px',
                                      }}
                                    >
                                      Adult
                                    </td>
                                    <td
                                      style={{
                                        border: '1px solid #d9d7d7',
                                        padding: '10px',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        backgroundColor: '#f9f9f9',
                                      }}
                                    >
                                      {pricingData ? formatPrice((pricingData as any).adult) : '0'}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        border: '1px solid #d9d7d7',
                                        padding: '10px',
                                        fontSize: '13px',
                                        fontWeight: '400',
                                        textAlign: 'left',
                                        width: '210px',
                                        backgroundColor: 'rgb(249, 249, 249)',
                                      }}
                                    >
                                      Child With Bed
                                    </td>
                                    <td
                                      style={{
                                        border: '1px solid #d9d7d7',
                                        padding: '10px',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        backgroundColor: '#f9f9f9',
                                      }}
                                    >
                                      {pricingData ? formatPrice((pricingData as any).child) : '0'}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        border: '1px solid #d9d7d7',
                                        padding: '10px',
                                        fontSize: '13px',
                                        fontWeight: '400',
                                        textAlign: 'left',
                                        backgroundColor: '#f9f9f9',
                                        width: '230px',
                                      }}
                                    >
                                      Child Without Bed
                                    </td>
                                    <td
                                      style={{
                                        border: '1px solid #d9d7d7',
                                        padding: '10px',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        backgroundColor: '#f9f9f9',
                                      }}
                                    >
                                      {pricingData ? formatPrice((pricingData as any).infant) : '0'}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </table>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              type='button'
                              onClick={ircloseModal}
                              className='bg-danger text-white px-4 py-2 rounded'
                            >
                              Close
                            </button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </>
  )
}

export {TablesWidget5}
