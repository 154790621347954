/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='document'
        title={intl.formatMessage({ id: 'Dashboard' })}
        fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItem
        to='/apps/user/enquiries'
        icon='document'
        title={intl.formatMessage({ id: 'Enquiries' })}
        fontIcon='bi-app-indicator'
      />
      {/*<SidebarMenuItem
        to='/apps/user/add-package'
        icon='plus'
        title={intl.formatMessage({ id: 'Add Package' })}
        fontIcon='bi-app-indicator'
    />*/}
      <SidebarMenuItem
        to='/apps/user/list-package'
        icon='dropbox'
        title={intl.formatMessage({ id: 'Package' })}
        fontIcon='bi-app-indicator'
      />
      {/*<SidebarMenuItem
        to='/apps/user/add-adult-price-config'
        icon='plus'
        title={intl.formatMessage({ id: 'Add Adult Pricing' })}
        fontIcon='bi-app-indicator'
      />*/}
      <SidebarMenuItem
        to='/apps/user/list-adult-price-config'
        icon='price-tag'
        title={intl.formatMessage({ id: 'Adult Pricing' })}
        fontIcon='bi-app-indicator'
      />
      {/*<SidebarMenuItem
        to='/apps/user/add-room-price-config'
        icon='minus'
        title={intl.formatMessage({ id: 'Add Room Pricing' })}
        fontIcon='bi-app-indicator'
    />*/}
      <SidebarMenuItem
        to='/apps/user/list-room-price-config'
        icon='discount'
        title={intl.formatMessage({ id: 'Room Pricing' })}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/apps/itinerary/list-includes'
        icon='add-item'
        title={intl.formatMessage({ id: 'Includes' })}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/apps/exclude/list-exclude'
        icon='delete-folder'
        title={intl.formatMessage({ id: 'Exclude' })}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/apps/tourdetail/list-detail'
        icon='message-text-2'
        title={intl.formatMessage({ id: 'Itinerary' })}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/apps/landing/list-landing'
        icon='folder-up'
        title={intl.formatMessage({ id: 'Landing' })}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/apps/islandlanding/list-island'
        icon='folder-up'
        title={intl.formatMessage({ id: 'Island Landing' })}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Customer'
        icon='profile-circle'
        fontIcon='bi-person'  
      >
        {/* <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Co Travellers' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/tour-history' title='Tour History' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/activities' title='Activities' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/payment-history' title='Billing and Payment' hasBullet={true} /> */}
        <SidebarMenuItem to='/crafted/account/customerlist' title='Customers' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/management'
        title={intl.formatMessage({ id: 'Management' })}
        fontIcon='bi-chat-left'
        icon='bi bi-person-add'
      >
        <SidebarMenuItem to='/apps/management/department/departmentlist' title='Department' hasBullet={true} />
        <SidebarMenuItem to='/apps/management/designation/designation-list' title='Designation' hasBullet={true} />
        <SidebarMenuItem to='/apps/management/employee/employee-list' title='Employee' hasBullet={true} />
        <SidebarMenuItem to='/apps/management/organization/organizationn' title='Organization' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* <SidebarMenuItem
        to='/auth/logout'
        icon='abstract-3'
        title={intl.formatMessage({id: 'logout'})}
        fontIcon='bi-app-indicator'
      /> */}
      {/*<SidebarMenuItem
        to='/auth/logout'
        icon='exit-left'
        title={intl.formatMessage({id: 'Logout'})}
        fontIcon='bi-app-indicator'
      />*/}

      {/*<div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>*/}

      {/* <SidebarMenuItemWithSub
        to='/apps/employee'
        title='Employee'
        fontIcon='bi-chat-left'
        icon='bi bi-person-add'
      >
        {/* <SidebarMenuItem to='/apps/employee/add-employee' title='Add Employee' hasBullet={true} /> 
        <SidebarMenuItem to='/apps/employee/employee-list' title='Employee List' hasBullet={true} />
       
        {/* <SidebarMenuItem to='/apps/employee/holidays' title='Holidays' hasBullet={true} />
        <SidebarMenuItem to='/apps/employee/leave' title='Leave' hasBullet={true} />
        <SidebarMenuItem to='/apps/employee/leave-settings' title='Leave Settings' hasBullet={true} />
        <SidebarMenuItem to='/apps/employee/attendance-admin' title='Attendance-Admin' hasBullet={true} />
        <SidebarMenuItem to='/apps/employee/TimeSheet' title='TimeSheet' hasBullet={true} /> 

       
       
      </SidebarMenuItemWithSub>*/}

      {/* <SidebarMenuItemWithSub
        to='/apps/organization'
        title='Organization'
        fontIcon='bi-chat-left'
        icon='bi bi-person-add'
      >
        {/* <SidebarMenuItem to='/apps/organization/organization-profile' title='Organization Profile' hasBullet={true} /> 
        
        <SidebarMenuItem to='/apps/organization/organization-list' title='Organization List' hasBullet={true} />

       
       
      </SidebarMenuItemWithSub>*/}

      {/* <SidebarMenuItemWithSub
        to='/apps/user'
        title='user'
        fontIcon='bi-chat-left'
        icon='bi bi-person-add'
      >
        {/* <SidebarMenuItem to='/apps/user/add-user' title='Add User' hasBullet={true} />
        <SidebarMenuItem to='/apps/user/user-list' title='User list' hasBullet={true} />
        <SidebarMenuItem to='/apps/user/list-adult-price-config' title='Adult Pricing' hasBullet={true} />
       
        <SidebarMenuItem to='/apps/user/list-room-price-config' title='Room Pricing' hasBullet={true} />
       
        <SidebarMenuItem to='/apps/user/list-package' title='Tour Package' hasBullet={true} />
        <SidebarMenuItem to='/apps/user/enquiries' title='Enquiries' hasBullet={true} />
       {/* <SidebarMenuItem to='/apps/user/add-package' title='Add Tour Package' hasBullet={true} />
        <SidebarMenuItem to='/apps/user/edit-package' title='Edit Tour Package' hasBullet={true} /> 
        
       
      </SidebarMenuItemWithSub>

      {/*<SidebarMenuItemWithSub
        to='/apps/settings'
        title='Settings'
        fontIcon='bi-chat-left'
        icon='bi bi-person-add'
      >
        <SidebarMenuItem to='/apps/settings/department' title='Department' hasBullet={true} />
        {/* <SidebarMenuItem to='/apps/settings/viewdepartment' title='View Department' hasBullet={true} /> 
        <SidebarMenuItem to='/apps/settings/designation' title='Designation' hasBullet={true} />
        {/* <SidebarMenuItem to='/apps/settings/viewdesignation' title='View Designation' hasBullet={true} /> 
       
      </SidebarMenuItemWithSub>*/}

      {/* <SidebarMenuItemWithSub
        to='/apps/driver'
        title='Driver'
        fontIcon='bi-chat-left'
        icon='bi bi-person-add'
      >
        <SidebarMenuItem to='/apps/driver/driver-list' title='Driver List' hasBullet={true} />
       
       
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/fleets'
        title='Fleets'
        fontIcon='bi-chat-left'
        icon='bi bi-truck'
      >
        <SidebarMenuItem to='/apps/fleets/fleets-list' title='Fleets List' hasBullet={true} />
        <SidebarMenuItem to='/apps/fleets/assign-trips' title='Assign Trips' hasBullet={true} />
       
      </SidebarMenuItemWithSub>
      
      <SidebarMenuItemWithSub
        to='/apps/customers'
        title='Customers'
        fontIcon='bi-chat-left'
        icon='bi bi-truck'
      >
        <SidebarMenuItem to='/apps/customers/customersprofile' title='Customers Profile' hasBullet={true} />
        <SidebarMenuItem to='/apps/customers/customerslist' title='Customers List' hasBullet={true} />
       
       
       
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/purchase'
        title='Purchase Tracking'
        icon='bi bi-handbag'
      >
      
       
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/breakdown'
        title='Breakdown'
        fontIcon='bi-chat-left'
        icon='bi bi-tools'
      >
        <SidebarMenuItem to='/apps/Breakdown/breakdown-request' title='Breakdown Request' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/maintenance'
        title='Maintenance & Service'
        fontIcon='bi-chat-left'
        icon='bi bi-clipboard-data'
      >
        <SidebarMenuItem to='/apps/Maintenance/maintenance-service' title='Maintenance Service Request' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/accident'
        title='Accident'
        fontIcon='bi-chat-left'
        icon='bi bi-ev-front'
      >
        <SidebarMenuItem to='/apps/accident/accident-request' title='Accident Request' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/plan'
        title='Plan'
        fontIcon='bi-chat-left'
        icon='bi bi-ev-front'
      >
        <SidebarMenuItem to='/apps/plan/add-plan' title='Add Plan' hasBullet={true} />
        <SidebarMenuItem to='/apps/plan/add-plan' title= 'List plan' hasBullet={true} />
      </SidebarMenuItemWithSub>


      <SidebarMenuItemWithSub
        to='/apps/insurance'
        title='Insurance'
        fontIcon='bi-chat-left'
        icon='bi bi-card-checklist'
      >
        <SidebarMenuItem to='/apps/insurance/renewel-purchase' title='Renewel & Purchase' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/claim'
        title='Claim & Refund'
        fontIcon='bi-chat-left'
        icon='bi bi-wallet2'
      >
        <SidebarMenuItem to='/apps/claim/claim-status' title='Claim Status' hasBullet={true} />
        <SidebarMenuItem to='/apps/claim/insurance-claim' title='Insurance Claim' hasBullet={true} />
        <SidebarMenuItem to='/apps/claim/other-claim' title='Other Claim' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/reminder'
        title='Reminder'
        fontIcon='bi-chat-left'
        icon='bi bi-alarm'
      >
        <SidebarMenuItem to='/apps/reminder/expiry' title='Expiry' hasBullet={true} />
        <SidebarMenuItem to='/apps/reminder/warranty' title='Warranty' hasBullet={true} />
        <SidebarMenuItem to='/apps/reminder/in-action' title='In Action' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/payment'
        title='Payment'
        fontIcon='bi-chat-left'
        icon='bi bi-credit-card'
      >
        <SidebarMenuItem to='/apps/payment/payment-invoice' title='Payment & Invoice' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/reports'
        title='Reports'
        fontIcon='bi-chat-left'
        icon='bi bi-flag'
      >
        <SidebarMenuItem to='/apps/reports/trip-summary' title='Trip Summary' hasBullet={true} />
        <SidebarMenuItem to='/apps/reports/fuel-summary' title='Fuel Summary' hasBullet={true} />
        <SidebarMenuItem to='/apps/reports/tyre-management' title='Tyre Management' hasBullet={true} />
        <SidebarMenuItem to='/apps/reports/fire-extinguisher' title='Fire Extinguisher' hasBullet={true} />
        <SidebarMenuItem to='/apps/reports/fleet-health ' title='Fleet Health Analytics' hasBullet={true} />
        <SidebarMenuItem to='/apps/reports/maintenance-log' title='Maintenance Log' hasBullet={true} />
      </SidebarMenuItemWithSub>


      <SidebarMenuItemWithSub
        to='/apps/ecard'
        title='E-Credit Card'
        fontIcon='bi-chat-left'
        icon='bi bi-credit-card-2-front'
      >
        <SidebarMenuItem to='/apps/ecard/ecredit-card' title='E-Credit Card Centre' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/performance'
        title='Performance'
        fontIcon='bi-chat-left'
        icon='bi bi-clipboard2-check'
      >
        <SidebarMenuItem to='/apps/performance/performance-suggestion' title='Performance & Suggestion' hasBullet={true} />
        
      </SidebarMenuItemWithSub> */}

      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className=''>
            <KTIcon iconName='' className='' />
          </span>
          <span className='menu-title'> </span>
        </a>
      </div>
    </>


  )
}

export { SidebarMenuMain }
