import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {AddIncludes} from './components/AddIncludes'
import {EditIncludes} from './components/EditIncludes'
import {ListIncludes} from './components/ListIncludes'

const maintenanceBreadCrumbs: Array<PageLink> = [
  {
    title: '',
    path: '/apps/itinerary/add-includes',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ItineraryPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='add-includes'
          element={
            <>
              <AddIncludes />
            </>
          }
        />

        <Route
          path='edit-includes/:id'
          element={
            <>
              <EditIncludes />
            </>
          }
        />

        <Route
          path='list-includes'
          element={
            <>
              <ListIncludes />
            </>
          }
        />

        <Route index element={<Navigate to='/apps/itinerary/add-includes' />} />
      </Route>
    </Routes>
  )
}

export default ItineraryPage
