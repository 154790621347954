/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'


const ToolbarAccounting: FC = () => {
  const [progress, setProgress] = useState<string>('1')
  const [filter, setFilter] = useState<string>('1')

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  return (
    <>
     
    
        
    </>
  )
}

export {ToolbarAccounting}
