import axios from 'axios'
import React, {FC, useEffect, useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import {toast} from 'react-toastify'

const ListDetail: FC = () => {
  interface DataRow {
    id: string
    tour_title: string
    tour_city: string
    status: string
    // Add other properties here if needed
  }

  const columns: TableColumn<DataRow>[] = [
    {
      name: 'Title',
      selector: (row: DataRow) => row.tour_title,
      sortable: true,
    },
    {
      name: 'City',
      selector: (row: DataRow) => row.tour_city,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row: DataRow) => row.status,
      sortable: true,
      cell: (row: DataRow) => (
        <button onClick={() => changeTourStatus(row.id)}>
          <i
            className={`fa-solid fa-toggle-${row.status == 'Active' ? 'on' : 'off'}`}
            style={{
              color: row.status == 'Active' ? 'green' : 'red', // Change icon color based on isActive
              fontSize: '28px',
            }}
          />
        </button>
      ),
    },
    {
      name: 'Actions',
      selector: (row: DataRow) => row.status,
      sortable: true,
      cell: (row: DataRow) => (
        <div className=' flex px-4'>
          <a href={'edit-detail/' + row.id}>
            <i className='fas fa-pencil text-primary' style={{fontSize: '18px',marginTop: '4px'}}></i>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='#!' onClick={() => deleteTourDetails(row.id)}>
            <i className='las la-trash-alt text-danger' style={{fontSize: '23px'}}></i>
          </a>
        </div>
      ),
    },
  ]

  const [toursList, setToursList] = useState([])
  const [searchText, setSearchText] = useState<string>('')

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredData = searchText
  ? toursList.filter((item: any) => {
      const concatenatedValues = Object.values(item).join(' ').toLowerCase();
      return concatenatedValues.includes(searchText.toLowerCase());
    })
  : toursList;

  useEffect(() => {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/getTourDetails')
      .then((response) => {
        if (response.data.status) {
          setToursList(response.data.tours)
        }
      })
  }, [])

  function changeTourStatus(id: any) {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/changeTourStatus/' + id)
      .then((response) => {
        if (response.data.status) {
          const updatedToursList = [...toursList]
          toast.success('Tour Details Status changed')
          const index = updatedToursList.findIndex((tourDetails: any) => tourDetails.id === id)
          if (index !== -1) {
            // Toggle the status
            ;(updatedToursList[index] as any).status =
              (updatedToursList[index] as any).status === 'Active' ? 'Inactive' : 'Active'

            setToursList(updatedToursList)
          }
        }
      })
  }

  function deleteTourDetails(id: any) {
    console.log('Deleting...')

    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteTourDetails/' + id)
      .then((response) => {
        if (response.data.status) {
          toast.success('Deleted Successfully...')
          setTimeout(() => {
            ;(window as any).location = '/apps/tourdetail/list-detail'
          }, 1000)
        }
      })
  }

  return (
    <>
      {/* Modal Start */}

      <div className='card1 bg-white rounded p-4 mb-5 mb-xl-10 mt-16'>
        <div
          className='card-header border-0 cursor-pointer flex items-center justify-between'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title '>
            <h1 className='font-medium text-xl  text-teal-600  ml-2'>List Tour Detail</h1>
          </div>
          <div className='pt-6'>
            <a href='/apps/tourdetail/tour-detail'>
              <button type='button' className='btn btn-sm btn-primary'>
                Add New
              </button>
            </a>
          </div>
        </div>

        <div className='card1 rounded mt-3'>
          {/* begin::Table container */}
          <div className='table-responsive1'>
            {/* begin::Table */}
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              customStyles={{
                headRow: {
                  style: {
                    backgroundColor: 'rgb(253 242 248);',
                  },
                },
              }}
              subHeader
              subHeaderComponent={
                // You can customize the subheader component for search
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleSearch}
                  style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', backgroundColor: '#fff' }}
                />
              }
            />
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}
export {ListDetail}
