import React, {FC, useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import styles
import {KTIcon} from '../../../../../_metronic/helpers'
import {toast} from 'react-toastify'
import axios from 'axios'
import countriesData from '../../../../../countries.json'

const AddIsland: FC = () => {
  interface AddDayApiResponse {
    status: boolean
    day: {
      id: number
      day: string
      day_icon: string
      day_title: string
      day_tag: string
      day_highlight: string
      day_description: string 
      status: string
      created_at: string
      updated_at: string
      // Other properties of the day object
    }
  }

  interface AddInfoApiResponse {
    status: boolean
    info: {
      id: number
      title: string
      description: string
      status: string
      created_at: string
      updated_at: string
      // Other properties of the day object
    }
  }

  interface AddTimingApiResponse {
    id: number
    start_month: string
    end_month: string
    amount: string
    created_at: string
    updated_at: string
  }

  const [showPopup, setShowPopup] = useState(false)
  const openPopup = () => {
    setShowPopup(true)
  }
  const closePopup = () => {
    setShowPopup(false)
  }

  const truncateDescription = (description: string, maxLength: number) => {
    if (description.length <= maxLength) {
      return description
    }

    const truncatedText = description.substring(0, maxLength)
    return `${truncatedText}...`
  }

  const [editorHtml, setEditorHtml] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [title, setTitle] = useState('')
  const [visitorCount, setVisitorCount] = useState(0)
  const [days, setDays] = useState(0)
  const [city, setCity] = useState('')
  const [slug, setSlug] = useState('')
  const [nightDescription, setNightDescription] = useState('')
  const [country, setCountry] = useState('')
  const [tourType, setTourType] = useState('')
  const [daysOptions, setDaysOptions] = useState<string[]>([])
  const [selectedDay, setSelectedDay] = useState('')
  const [iconName, setIconName] = useState('')
  const [dayTitle, setDayTitle] = useState('')
  const [dayTag, setDayTag] = useState('')
  const [dayHighlight, setDayHighlight] = useState('')
  const [dayDescription, setDayDescription] = useState('')
  const [dayData, setDayData] = useState<AddDayApiResponse['day'][]>([])
  const [dayIds, setDayIds] = useState<number[]>([])
  const [infoTitle, setInfoTitle] = useState('')
  const [infoDescription, setInfoDescription] = useState('')
  const [infoData, setInfoData] = useState<AddInfoApiResponse['info'][]>([])
  const [infoIds, setInfoIds] = useState<number[]>([])
  const [includes, setIncludes] = useState([])
  const [inclusion, setInclusion] = useState('')
  const [includesDetails, setIncludesDetails] = useState<any[]>([])
  const [excludes, setExcludes] = useState([])
  const [selectedIncludes, setSelectedIncludes] = useState<string[]>([])
  const [selectedIncludesText, setSelectedIncludesText] = useState<string[]>([])
  const [selectedExcludes, setSelectedExcludes] = useState<string[]>([])
  const [selectedExcludesText, setSelectedExcludesText] = useState<string[]>([])
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [selectedThumbnailFiles, setSelectedThumbnailFiles] = useState<File[]>([])
  const [selectedFilesIds, setSelectedFilesIds] = useState<String[]>([])
  const [selectedThumbnailFilesIds, setSelectedThumbnailFilesIds] = useState<String[]>([])
  const [packageList, setPackageList] = useState([])
  const [adultPrice, setAdultPrice] = useState('')
  const [childPrice, setChildPrice] = useState('')
  const [infantPrice, setInfantPrice] = useState('')
  const [isFlightIncluded, setIsFlightIncluded] = useState(false)
  const [isCruise, setIsCruise] = useState(false)
  const [isResort, setIsResort] = useState(false)
  const [bannerTitle, setBannerTitle] = useState('')
  const [bannerDescription, setBannerDescription] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [amount, setAmount] = useState('')
  const [resortDates, setResortDates] = useState<AddTimingApiResponse[]>([])
  const [selectedResortDateIds, setSelectedResortDateIds] = useState<String[]>([])
  const [selectedResortIds, setSelectedResortIds] = useState<String[]>([])
  const [resortName, setResortName] = useState('');
  const [resortRating, setResortRating] = useState('');
  const [resortOccuppancy, setResortOccuppancy] = useState('');
  const [resortVillaCount, setResortVillaCount] = useState('');
  const [resortRoomSize, setResortRoomSize] = useState('');
  const [resorts, setResorts] = useState<any[]>([]); 
  const [resortImageFile, setResortImageFile] = useState<File[]>([])
  const [isEditingTiming, setIsEditingTiming] = useState(false);
  const [editingTimingId, setEditingTimingId] = useState(0);
  const [editingTimingIndex, setEditingTimingIndex] = useState(-1);
  const [isEditingResort, setIsEditingResort] = useState(false);
  const [editingResortId, setEditingResortId] = useState(0);
  const [editingResortIndex, setEditingResortIndex] = useState(-1);
  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [editingInfoId, setEditingInfoId] = useState(0);
  const [editingInfoIndex, setEditingInfoIndex] = useState(-1)


  function addAddIslands() {

    if(city == '')
    {
      toast.error('Please enter the city')
    }
    else if(country == '')
    {
      toast.error('Please select the country')
    }
    else if(title == '')
    {
      toast.error('Please enter the tour title')
    }
    else if(slug == "")
    {
      toast.error('Please enter the slug')
    }
    else if(days == 0)
    {
      toast.error('Please enter the number of days')
    }
    else if(visitorCount == 0)
    {
      toast.error('Please enter the number of guests travelled')
    }
    else if(tourType == '')
    {
      toast.error('Please select the tour type')
    }
    else if(bannerTitle == '')
    {
      toast.error('Please enter title')
    }
    else if(bannerDescription == '')
    {
      toast.error('Please enter description')
    }
    else if(resortDates.length == 0)
    {
      toast.error("Please enter resort timing information")
    }
    else if(resorts.length == 0)
    {
      toast.error("Please enter resort information")
    }
    else if(infoIds.length == 0)
    {
      toast.error("Please enter information")
    }
    else
    {
      const tour_info_details = infoIds.join(', ')
      const photo_details = selectedFilesIds.join(', ')
      const timing_details = selectedResortDateIds.join(', ')
      const resorts = selectedResortIds.join(", ")
      const formData = {
        city: city,
        country: country,
        title: title,
        slug: slug,
        days: days,
        number_of_guests: visitorCount,
        island_type: tourType,
        night_description: nightDescription,
        is_flight: isFlightIncluded ? 'Yes' : 'No',
        resort_dates: timing_details,
        resorts: resorts,
        tour_info_details: tour_info_details,
        photos_details: photo_details,
        banner_title: bannerTitle,
        banner_description : bannerDescription
      }
      axios
      .post('https://admin.trawelmart.com/admin/public/index.php/api/addIsland', formData)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            toast.success('Island added successfully...')
            setTimeout(() => {
              (window as any).location = '/apps/islandlanding/list-island'
            }, 1000)
          } else {
            toast.error(response.data.message)
          }
        }
      })
    }
  }

  function addResortPricing() {
    if (startDate == '') {
      toast.error('Please select start month')
    } else if (endDate == '') {
      toast.error('Please select end month')
    } else if (amount == '') {
      toast.error('Please enter the amount')
    } else {
      if(isEditingTiming)
      {
        const formData = {start_month: startDate, end_month: endDate, amount: amount, id: editingTimingId}
        axios
          .post('https://admin.trawelmart.com/admin/public/index.php/api/updateResortTiming', formData)
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status) {
                console.log(response.data);
                toast.success('Timing updated successfully..')
                const updatedResortPricing = [...resortDates];
                updatedResortPricing[editingTimingIndex].start_month = response.data.timing.start_month;
                updatedResortPricing[editingTimingIndex].end_month = response.data.timing.end_month;
                updatedResortPricing[editingTimingIndex].amount = response.data.timing.amount;
                setResortDates(updatedResortPricing)
                setStartDate('')
                setEndDate('')
                setAmount('')
                setIsEditingTiming(false);
                setEditingTimingId(0);
                setEditingTimingIndex(-1);
              }
            }
          })
      }
      else
      {
        const formData = {start_month: startDate, end_month: endDate, amount: amount}
        axios
          .post('https://admin.trawelmart.com/admin/public/index.php/api/addResortTiming', formData)
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status) {
                toast.success('Timing added successfully..')
                setResortDates((prevResortDates) => [...prevResortDates, response.data.timing])
                setSelectedResortDateIds((prevResortDateIds) => [
                  ...prevResortDateIds,
                  response.data.timing.id,
                ])
                setStartDate('')
                setEndDate('')
                setAmount('')
              }
            }
          })
      }
    }
  }

  const addResort = async () => 
  {
    if(resortName == '')
    {
      toast.error("Please enter resort name");
    }
    else if(resortRating == '')
    {
      toast.error("Please enter resort rating");
    }
    else if(resortOccuppancy == '')
    {
      toast.error("Please enter resort occupancy");
    }
    else if(resortVillaCount == '')
    {
      toast.error("Please enter No. of Villas");
    }
    else if(resortRoomSize == '')
    {
      toast.error("Please enter Room Size");
    }
    else if(resortImageFile.length == 0)
    {
      toast.error("Please select Resort Image")
    }
    else
    {
      if(isEditingResort)
      {
        const formData = new FormData();

        for (const file of resortImageFile)
        {
          formData.append('images', file)
        }

        formData.append('resort_name', resortName);
        formData.append('resort_rating', resortRating);
        formData.append('resort_occupancy', resortOccuppancy);
        formData.append('resort_villa_count', resortVillaCount);
        formData.append('resort_room_size', resortRoomSize);
        formData.append('id', editingResortId + '');

        const response =  await axios.post("https://admin.trawelmart.com/admin/public/index.php/api/updateResort", formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if(response.status == 200)
        {
          if(response.data.status)
          {
            const updatedResort = [...resorts];
            updatedResort[editingResortIndex].resort_name = response.data.resort.resort_name;
            updatedResort[editingResortIndex].resort_rating = response.data.resort.resort_rating;
            updatedResort[editingResortIndex].resort_occupancy = response.data.resort.resort_occupancy;
            updatedResort[editingResortIndex].resort_villa_count = response.data.resort.resort_villa_count;
            updatedResort[editingResortIndex].resort_room_size = response.data.resort.resort_room_size;
            setResorts(updatedResort);
            toast.success("Resort updated successfully...");
            cancelResortEdit()
          }
        }
      }
      else
      {
        const formData = new FormData();

        for (const file of resortImageFile)
        {
          formData.append('images', file)
        }

        formData.append('resort_name', resortName);
        formData.append('resort_rating', resortRating);
        formData.append('resort_occupancy', resortOccuppancy);
        formData.append('resort_villa_count', resortVillaCount);
        formData.append('resort_room_size', resortRoomSize);

        const response =  await axios.post("https://admin.trawelmart.com/admin/public/index.php/api/addResort", formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        if(response.status == 200)
        {
          if(response.data.status)
          {
            setResortImageFile([])
            setResorts((prevResort) => [...prevResort, response.data.resort])
            setSelectedResortIds((prevResortId) => [...prevResortId, response.data.resort.id])
            toast.success("Resort added successfully...");
            cancelResortEdit()
            const resortImageFile = document.getElementById(
              'resortFileInput'
            ) as HTMLInputElement
            if (resortImageFile) {
              resortImageFile.value = ''
              if (resortImageFile.files) {
                resortImageFile.files = null as any // Clear files
              }
            }
          }
        }
      }
    }
  }
  
  function deletePricing(id: any, deleteIndex: any) {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteDaywiseDetails/' + id)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setResortDates((prevResortDate) => {
              const updatedDates = prevResortDate.filter((_, index) => index !== deleteIndex)
              return updatedDates
            })

            setSelectedResortDateIds((prevDateIds) => {
              const updatedDateIds = prevDateIds.filter((_, index) => index !== deleteIndex)
              return updatedDateIds
            })
          }
        }
      })
  }

  function deleteInfoData(id: any, deleteIndex: any) {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteTourInformation/' + id)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setInfoData((prevInfoData) => {
              const updatedInfoData = prevInfoData.filter((_, index) => index !== deleteIndex)
              return updatedInfoData
            })

            setInfoIds((prevInfoDataId) => {
              const updatedInfoDataId = prevInfoDataId.filter((_, index) => index !== deleteIndex)
              return updatedInfoDataId
            })
          }
        }
      })
  }

  function cancelResortEdit()
    {
      setResortName('')
      setResortRating('')
      setResortOccuppancy('')
      setResortVillaCount('')
      setResortRoomSize('')
      setResortImageFile([])
      setIsEditingResort(false)
      setEditingResortId(0)
      setEditingResortIndex(-1)
    }

  function deleteResort(id: any, deleteIndex: any) {
    axios
    .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteNewResort/' + id)
    .then((response) => {
      if (response.status == 200) {
        if (response.data.status) {
          cancelResortEdit();
          setResorts((prevResort) => {
            const updatedResort = prevResort.filter((_, index) => index !== deleteIndex)
            return updatedResort
          })
          setSelectedResortIds((prevResortId) => {
            const updatedResortId = prevResortId.filter((_, index) => index !== deleteIndex)
            return updatedResortId
          })
        }
      }
    })
  }

  function addInfo() {
    if (infoTitle == '') {
      toast.error('Please enter title')
    } else if (infoDescription == '') {
      toast.error('Please enter description')
    } else {
      if(isEditingInfo)
      {
        const formData = {title: infoTitle, description: infoDescription, id: editingInfoId}
        axios
          .post(
            'https://admin.trawelmart.com/admin/public/index.php/api/updateTourInformation',
            formData
          )
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status) {
                const updatedInfo = [...infoData];
                updatedInfo[editingInfoIndex].title = response.data.info.title;
                updatedInfo[editingInfoIndex].description = response.data.info.description;
                setInfoData(updatedInfo);
                toast.success("Tour Information updated Successfully...");
                setInfoTitle('')
                setInfoDescription('')
              }
            }
          })
          .catch((error) => {
            console.error(error)
          })
        }
        else
        {
          const formData = {title: infoTitle, description: infoDescription}
          axios
          .post(
            'https://admin.trawelmart.com/admin/public/index.php/api/addTourInformation',
            formData
            )
            .then((response) => {
              if (response.status == 200) {
                if (response.data.status) {
                  setInfoData((prevInfoData) => [...prevInfoData, response.data.info])
                  setInfoIds((prevInfoId) => [...prevInfoId, response.data.info.id])
                  toast.success("Tour Information added Successfully...");
                  setIsEditingInfo(false);
                  setInfoTitle('')
                  setInfoDescription('')
              }
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }

  const handleResortFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setResortImageFile([...resortImageFile, ...Array.from(e.target.files)])
    }
  }

  function deleteImages(index: number) {
    const newFileIds = [...selectedFilesIds]

    newFileIds.splice(index, 1)

    setSelectedFilesIds(newFileIds)
  }

  useEffect(() => {
    const newDaysOptions: string[] = []

    for (let i = 1; i <= days; i++) {
      newDaysOptions.push(`Day ${i}`)
    }

    setDaysOptions(newDaysOptions)
  }, [days])

  useEffect(() => {
    if (title != '') {
      setSlug(title.toLowerCase().replaceAll(' ', '-'))
    } else {
      setSlug('')
    }
  }, [title])


  const [activeTab, setActiveTab] = useState('Tab2')

  const handleTabClick = (tabName: React.SetStateAction<string>) => {
    setActiveTab(tabName)
  }

  const handleChange = (html: any) => {
    setInfoDescription(html)
  }

  const handleChange1 = (html: any) => {
    setDayDescription(html)
  }

  const handleThumbnailFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedThumbnailFiles([...selectedThumbnailFiles, ...Array.from(e.target.files)])
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles([...selectedFiles, ...Array.from(e.target.files)])
    }
  }

  const handleUpload = async () => {
    if (selectedFiles.length == 0) {
      toast.error('Please select atleast one file')
    } else {
      const formData = new FormData()

      for (const file of selectedFiles) {
        formData.append('images[]', file)
      }

      try {
        const response = await axios.post(
          'https://admin.trawelmart.com/admin/public/index.php/api/uploadIslandImages',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        if (response.data.status) {
          const photosInput = document.getElementById('photosInputFiles') as HTMLInputElement
          if (photosInput) {
            photosInput.value = ''
            if (photosInput.files) {
              photosInput.files = null as any // Clear files
            }
          }
          setSelectedFilesIds((prevFileIds) => [...prevFileIds, ...response.data.files])
          setSelectedFiles([])
        } else {
          toast.error('Some error occurred')
        }
      } catch (error) {
        console.error('Upload failed ', error)
      }
    }
  }

  function editTiming(editIndex:number, id: number)
  {
    setIsEditingTiming(true);
    setEditingTimingId(resortDates[editIndex].id);
    setEditingTimingIndex(editIndex);
    setStartDate(resortDates[editIndex].start_month);
    setEndDate(resortDates[editIndex].end_month);
    setAmount(resortDates[editIndex].amount);
  }

  function editResort(editIndex: number, id: number)
  {
    setIsEditingResort(true);
    setEditingResortId(resorts[editIndex].id);
    setEditingResortIndex(editIndex);
    setResortName(resorts[editIndex].resort_name);
    setResortOccuppancy(resorts[editIndex].resort_occupancy);
    setResortRating(resorts[editIndex].resort_rating);
    setResortRoomSize(resorts[editIndex].resort_room_size);
    setResortVillaCount(resorts[editIndex].resort_villa_count);
  }

  function editInfo(editIndex: number, id: number)
  {
    setIsEditingInfo(true);
    setEditingInfoId(infoData[editIndex].id);
    setEditingInfoIndex(editIndex);
    setInfoTitle(infoData[editIndex].title);
    setInfoDescription(infoData[editIndex].description);
  }

  return (
    <>
      <div className=' mb-5 mb-xl-10 mt-16'>
        <div className=''>
          {/* begin::Table container */}
          <div className='row lg:pr-3 px-4 mt-4'>
            <div className='col-lg-8'>
              <form>
                <div
                  className='card1 bg-white rounded mb-5 mb-xl-6 px-6'
                  id='kt_profile_details_view'
                >
                  <div className='row mb-9 pb-0 p-4 '>
                    <div className='col-lg-6 fv-row pr-4 pb-4 pt-4'>
                      <label className='text-lg pb-2 font-medium text-slate-500'>Country</label>
                      <select
                        className='form-control mb-3 mb-lg-0 '
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option value=''>Select Country</option>
                        {countriesData.map((country) => (
                          <option value={country.name} key={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-lg-6 fv-row pt-4'>
                      <label className=' text-lg pb-2 font-medium text-slate-500'>City</label>
                      <input
                        type='text'
                        className='border-black form-control   mb-3 mb-lg-0'
                        placeholder=''
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>

                    <div className='col-lg-12 mt-3'>
                      <label className='text-lg font-medium pb-2 text-slate-500'>Title</label>
                      <input
                        className='form-control mb-3 mb-lg-0 '
                        value={title}
                        placeholder=''
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    <div className='col-lg-12 pt-6'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <label className=' text-lg pb-2 font-medium text-slate-500'>Slug</label>
                          <input
                            type='text'
                            className='border-black form-control   mb-3 mb-lg-0'
                            placeholder=''
                            value={slug}
                            onChange={(e) => setSlug(e.target.value)}
                          />
                        </div>
                        <div className='mt-3 flex'>
                          <div className='text-teal-600 bg-rose-100 px-2 py-1 flex '>
                            <div className=''>
                              {/* <i className='las la-info-circle text-lime-500'></i> */}
                              <img
                                src='https://i.ibb.co/NrZ2L2C/R.png'
                                className='h-4 w-4'
                                alt=''
                              />
                            </div>
                            <span className='text-teal-600 px-2'>trawelmart.com/{slug}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=''>
                      <div className='col-lg-12 pt-6'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            <label className='text-lg font-medium pb-2 text-slate-500'>Days</label>
                            <input
                              type='number'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder=''
                              value={days}
                              onChange={(e) => setDays(parseInt(e.target.value, 10))}
                            />
                          </div>

                          <div className='col-lg-4 pt-1'>
                            <label className=' text-base pb-2 font-medium text-slate-500'>
                              Number of Guests Travelled
                            </label>
                            <input
                              type='number'
                              className='border-black form-control   mb-2 mb-lg-0'
                              value={visitorCount}
                              onChange={(e) => setVisitorCount(parseInt(e.target.value, 10))}
                            />
                          </div>

                          <div className='col-lg-4 fv-row pl-2'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>Type</label>
                            <select
                              className='form-control border-black mb-3 mb-lg-0 '
                              value={tourType}
                              onChange={(e) => setTourType(e.target.value)}
                            >
                              <option value='' selected hidden disabled>
                                Select
                              </option>
                              <option value='Standard'>Standard</option>
                              <option value='Luxury'>Luxury</option>
                              <option value='Premium'>Premium</option>
                              <option value='Group'>Group</option>
                            </select>
                          </div>

                          <div className=''>
                            <div className='col-lg-15'>
                              <div className='row'>
                                <div className='col-lg-15 fv-row px-4 mt-8'>
                                  <label className='pb-2 text-lg font-medium text-slate-500'>
                                    Night Description
                                  </label>
                                  <input
                                    type='text'
                                    className='border-black form-control   mb-3 mb-lg-0'
                                    placeholder=''
                                    value={nightDescription}
                                    onChange={(e) => setNightDescription(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className='row'>
                                <div className='col-lg-12 flex fv-row px-4 mt-6'>
                                  <label className='col-lg-3 pt-3 text-2xl font-medium text-slate-500 fs-6'>
                                    Is Flight Included?
                                  </label>
                                  <div className='col-lg-12 pt-4 -ml-6'>
                                    <input
                                      id='yes'
                                      type='radio'
                                      name='islandLanding'
                                      className='form-check-inline'
                                      checked={isFlightIncluded}
                                      onChange={(e) => setIsFlightIncluded(true)}
                                    />
                                    <label htmlFor='yes' className='text-slate-600'>
                                      Yes
                                    </label>
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                      id='no'
                                      type='radio'
                                      name='islandLanding'
                                      className='form-check-inline'
                                      checked={!isFlightIncluded}
                                      onChange={(e) => setIsFlightIncluded(false)}
                                    />
                                    <label htmlFor='no' className='text-slate-600'>
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className='col-lg-12 flex fv-row px-4 mt-6'>
                              <label className='col-lg-3 pt-3 text-2xl font-medium text-slate-500 fs-6'>
                                Is Cruise Included?
                              </label>
                              <div className='col-lg-12 pt-4 -ml-6'>
                                <input
                                  id='yes'
                                  type='radio'
                                  name='isCruise'
                                  className='form-check-inline'
                                  checked={isCruise}
                                  onChange={(e) => setIsCruise(true)}
                                />
                                <label htmlFor='yes' className='text-slate-600'>
                                  Yes
                                </label>
                                &nbsp;&nbsp;&nbsp;
                                <input
                                  id='no'
                                  type='radio'
                                  name='isCruise'
                                  className='form-check-inline'
                                  checked={!isCruise}
                                  onChange={(e) => setIsCruise(false)}
                                />
                                <label htmlFor='no' className='text-slate-600'>
                                  No
                                </label>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='card1 bg-white rounded mb-5 mb-xl-6 px-6'
                        id='kt_profile_details_view'
                      >
                        <div className='row mb-9 pb-0 p-4 '>
                          <div className='card-title pt-5'>
                            <h1 className=' pb-1 font-medium  text-xl  text-teal-600  mt-2'>
                              Island Banner Information
                            </h1>
                          </div>
                          <div className='border-b'></div>
                          <div className='col-lg-12 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>Title</label>
                            <input
                              type='text'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Title'
                              value={bannerTitle}
                              onChange={(e) => setBannerTitle(e.target.value)}
                            />
                          </div>
                          <div className='col-lg-12 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>Description</label>
                            <textarea
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Description'
                              value={bannerDescription}
                              onChange={(e) => setBannerDescription(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='card1 bg-white rounded mb-5 mb-xl-6 px-6'
                        id='kt_profile_details_view'
                      >
                        <div className='row mb-9 pb-0 p-4 '>
                          {/* <div className='col-lg-12'>
                            <div className='row'>
                              <div className='col-lg-12 flex fv-row px-4 mt-4'>
                                <label className='col-lg-2 text-2xl font-medium text-slate-500 fs-6'>
                                  Is Resort?
                                </label>
                                <div className='col-lg-4 pt-0 ml-13'>
                                  <input
                                    id='yes_1'
                                    type='radio'
                                    name='isResort'
                                    className='form-check-inline'
                                    checked={isResort}
                                    onChange={(e) => setIsResort(true)}
                                  />
                                  <label htmlFor='yes_1' className='text-slate-600'>
                                    Yes
                                  </label>
                                  &nbsp;&nbsp;&nbsp;
                                  <input
                                    id='no_1'
                                    type='radio'
                                    name='isResort'
                                    className='form-check-inline'
                                    checked={!isResort}
                                    onChange={(e) => setIsResort(false)}
                                  />
                                  <label htmlFor='no_1' className='text-slate-600'>
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className='card-title pt-5'>
                            <h1 className=' pb-1 font-medium  text-xl  text-teal-600  mt-2'>
                              Resort Timing Information
                            </h1>
                          </div>
                          <div className='border-b'></div>
                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>From</label>
                            <input
                              type='month'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Starting Month'
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>
                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>To</label>
                            <input
                              type='month'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Starting Month'
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>
                          <div className='col-lg-12 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Amount
                            </label>
                            <input
                              type='number'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Amount'
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </div>
                          <div className='pt-12'>
                            <button
                              type='button'
                              className='btn btn-primary mb-4 w-32 py-2'
                              onClick={addResortPricing}
                            >
                              {isEditingTiming ? 'Update' : 'Add'}
                            </button>
                          </div>
                          <div className='py-3 pt-6'>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                                <thead>
                                  <tr className='font-medium text-slate-600 bg-rose-100'>
                                    <th className='min-w-40px px-4 font-medium'>Start Month</th>
                                    <th className='min-w-40px px-4 font-medium'>End Month</th>
                                    <th className='min-w-150px font-medium'> Amount </th>
                                    <th className='min-w-20px font-medium'>Edit </th>
                                    <th className='min-w-20px font-medium'>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {resortDates.map((item, index) => (
                                    <tr>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).start_month}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).end_month}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).amount}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => editTiming(index, (item as any).id)}
                                          >
                                            <i
                                              className='fas fa-pencil text-primary'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => deletePricing((item as any).id, index)}
                                          >
                                            <i
                                              className='fas fa-trash text-danger'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Resort Information */}

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='card1 bg-white rounded  mb-5 mb-xl-8 px-6'
                        id='kt_profile_details_view'
                      >
                        <div className='row mb-9 pb-0 p-4 '>
                          <div className='card-title lg:pt-6'>
                            <h1 className='pt-2 pb-2 font-medium  text-xl  text-teal-600'>
                              Resort Information
                            </h1>
                          </div>
                          <div className='border-b'></div>
                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Resort Name
                            </label>
                            <input
                              type='text'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder='Resort Name'
                              value={resortName}
                              onChange={(e) => setResortName(e.target.value)}
                            />
                          </div>
                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Rating
                            </label>
                            <input
                              type='number'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Rating'
                              min={1}
                              max={5}
                              value={resortRating}
                              onChange={(e) => setResortRating(e.target.value)}
                            />
                          </div>

                          <div className='col-lg-4 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Occupancy
                            </label>
                            <input
                              type='text'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder='Occupancy'
                              value={resortOccuppancy}
                              onChange={(e) => setResortOccuppancy(e.target.value)}
                            />
                          </div>


                          <div className='col-lg-4 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              No of Villas
                            </label>
                            <input
                              type='text'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder='No. of Villas'
                              value={resortVillaCount}
                              onChange={(e) => setResortVillaCount(e.target.value)}
                            />
                          </div>


                          <div className='col-lg-4 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Room size
                            </label>
                            <input
                              type='text'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder='Room Size'
                              value={resortRoomSize}
                              onChange={(e) => setResortRoomSize(e.target.value)}
                            />
                          </div>

                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Resort Image
                            </label>
                            <input
                              type='file'
                              id='resortFileInput'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder=''
                              onChange={handleResortFileChange}
                            />
                          </div>

                          
                          <div className='pt-12'>
                            <button
                              type='button'
                              className='btn btn-primary w-32 py-2'
                              onClick={addResort}
                            >
                              {isEditingResort ? 'Update' : 'Add'}
                            </button>
                          </div>
                          <div className='py-3 pt-6'>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                                <thead>
                                  <tr className='font-medium text-slate-600 bg-rose-100'>
                                    <th className='min-w-40px font-medium'>Resort Name </th>
                                    <th className='min-w-40px font-medium'>Rating</th>
                                    <th className='min-w-40px font-medium'>Occupancy </th>
                                    <th className='min-w-40px font-medium'>No of Villas </th>
                                    <th className='min-w-40px font-medium'>Room Size</th>
                                    <th className='min-w-40px font-medium'>Image</th>
                                    <th className='min-w-20px font-medium'>Edit</th>
                                    <th className='min-w-20px font-medium'>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {resorts.map((item, index) => (
                                    <tr>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).resort_name}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            {(item as any).resort_rating}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            {(item as any).resort_occupancy}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            {(item as any).resort_villa_count}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            {(item as any).resort_room_size}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            {(item as any).image ? 
                                              <a
                                                href={
                                                  'https://admin.trawelmart.com/admin/public/' +
                                                  (item as any).image
                                                }
                                                target='_blank'
                                              >
                                                View Image
                                              </a>
                                            :
                                              'No Image'
                                            }
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => editResort(index, (item as any).id)}
                                          >
                                            <i
                                              className='fas fa-pencil text-primary'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => deleteResort((item as any).id, index)}
                                          >
                                            <i
                                              className='fas fa-trash text-danger'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

               

                {/* Tour Information */}

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='card1 bg-white rounded  mb-5 mb-xl-8 px-6'
                        id='kt_profile_details_view'
                      >
                        <div className='row mb-9 pb-0 p-4 '>
                          <div className='card-title lg:pt-6'>
                            <h1 className='pt-2 pb-2 font-medium  text-xl  text-teal-600'>
                              Tour Information
                            </h1>
                          </div>
                          <div className='border-b'></div>
                          <div className='col-lg-12 fv-row px-2 pt-6'>
                            <label className=' text-lg pb-2 font-medium text-slate-500'>
                              Title
                            </label>
                            <input
                              type='text'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder='About This Tour'
                              value={infoTitle}
                              onChange={(e) => setInfoTitle(e.target.value)}
                            />
                          </div>

                          <div className='pb-12 pt-8 px-2'>
                            <h6 className=' pb-2 font-medium text-slate-500'>Description</h6>
                            <ReactQuill
                              className='h-60'
                              value={infoDescription}
                              onChange={handleChange}
                              modules={{
                                toolbar: [
                                  [{header: '1'}, {header: '2'}, {font: []}],
                                  ['bold', 'italic', 'underline'],
                                  [{list: 'ordered'}, {list: 'bullet'}],
                                  ['link', 'image'],
                                  [{align: []}],
                                  ['clean'],
                                  ['blockquote'],
                                  [{size: ['small', false, 'large', 'huge']}], // Font size options
                                ],
                              }}
                            />
                            {/*<div className='output pt-32'>
                      <h3>Preview:</h3>
                      <div dangerouslySetInnerHTML={{__html: editorHtml}} />
                    </div>*/}
                          </div>
                          <div className='pt-12'>
                            <button
                              type='button'
                              className='btn btn-primary w-32 py-2'
                              onClick={addInfo}
                            >
                              {isEditingInfo ? 'Update' : 'Add'}
                            </button>
                          </div>
                          <div className='py-3 pt-6'>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                                <thead>
                                  <tr className='font-medium text-slate-600 bg-rose-100'>
                                    <th className='min-w-40px px-4 font-medium'>Title</th>
                                    <th className='min-w-150px font-medium'> Description </th>
                                    <th className='min-w-20px font-medium'>Edit</th>
                                    <th className='min-w-20px font-medium'>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {infoData.map((item, index) => (
                                    <tr>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).title}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: truncateDescription(item.description, 40),
                                              }}
                                              title={item.description}
                                            ></div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => editInfo(index, (item as any).id)}
                                          >
                                            <i
                                              className='fas fa-pencil text-primary'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => deleteInfoData((item as any).id, index)}
                                          >
                                            <i
                                              className='fas fa-trash text-danger'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='pt-6 pb-12 text-left'>
                    <button
                      type='button'
                      className='btn btn-primary mx-2 w-52 py-2'
                      onClick={addAddIslands}
                    >
                      Add Details
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className='col-lg-4 '>
              <div className='col-lg-12'>

                <div
                  className='card1 bg-white rounded  mb-5 mb-xl-6 px-6 mt-2 pt-0 pb-6'
                  id='kt_profile_details_view'
                >
                  <div className='card-title pt-1 border-b mb-5'>
                    <h1 className='text-lg font-medium text-slate-500 pb-2 pt-3'>Upload Photos</h1>
                  </div>
                  <div className='flex'>
                    <div>
                      <input
                        id='photosInputFiles'
                        type='file'
                        className='border-black form-control mb-3 mb-lg-0'
                        multiple
                        onChange={handleFileChange}
                        placeholder=''
                      />
                    </div>
                    <div>
                      <button
                        className='btn btn-primary ml-5 my-0 mt-1 py-2'
                        type='button'
                        onClick={handleUpload}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    {selectedFilesIds.map((item, index) => (
                      <div className='col-md-3 text-center mb-6'>
                        <a href='#!' onClick={() => deleteImages(index)}>
                          <i
                            className='las la-times'
                            style={{
                              fontSize: '12px',
                              marginTop: '10px',
                              color: 'black',
                              marginLeft: '60px',
                              border: 'white 4px solid',
                              backgroundColor: '#ffffff',
                              borderRadius: '10px',
                              zIndex: '50',
                              position: 'relative', // Added position: relative
                            }}
                          ></i>
                        </a>
                        <a
                          href={'https://admin.trawelmart.com/admin/public/' + item}
                          key={index}
                          target='_blank'
                        >
                          <img
                            src={'https://admin.trawelmart.com/admin/public/' + item}
                            style={{
                              maxWidth: '75px',
                              width: '100%',
                              marginTop: '-11px',
                              marginLeft: '0px',
                              height: '50px',
                              border: '#dddee1 4px solid',
                              gap: '10px',
                              // minWidth: '60px',
                            }}
                            key={index}
                            alt={'Image ' + index}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export {AddIsland}
