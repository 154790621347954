import axios from 'axios'
import React, {FC, useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import styles
import {useParams} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {toast} from 'react-toastify'
import countriesData from '../../../../../countries.json'

const EditDetail: FC = () => {
  interface AddDayApiResponse {
    status: boolean
    day: {
      id: number
      day: string
      day_icon: string
      day_title: string
      day_tag: string
      day_highlight: string
      day_description: string
      status: string
      created_at: string
      updated_at: string
      // Other properties of the day object
    }
  }

  interface AddInfoApiResponse {
    status: boolean
    info: {
      id: number
      title: string
      description: string
      status: string
      created_at: string
      updated_at: string
      // Other properties of the day object
    }
  }

  interface AddTimingApiResponse {
    id: number
    start_month: string
    end_month: string
    amount: string
    created_at: string
    updated_at: string
  }

  const [showPopup, setShowPopup] = useState(false)
  const openPopup = () => {
    setShowPopup(true)
  }
  const closePopup = () => {
    setShowPopup(false)
  }

  const truncateDescription = (description: string, maxLength: number) => {
    if (description.length <= maxLength) {
      return description
    }

    const truncatedText = description.substring(0, maxLength)
    return `${truncatedText}...`
  }

  const [editorHtml, setEditorHtml] = useState('')

  const [isActive, setIsActive] = useState(false)
  const [title, setTitle] = useState('')
  const [visitorCount, setVisitorCount] = useState(0)
  const [days, setDays] = useState(0)
  const [city, setCity] = useState('')
  const [slug, setSlug] = useState('')
  const [nightDescription, setNightDescription] = useState('')
  const [country, setCountry] = useState('')
  const [tourType, setTourType] = useState('')
  const [daysOptions, setDaysOptions] = useState<string[]>([])
  const [selectedDay, setSelectedDay] = useState('')
  const [iconName, setIconName] = useState('')
  const [dayTitle, setDayTitle] = useState('')
  const [dayTag, setDayTag] = useState('')
  const [dayHighlight, setDayHighlight] = useState('')
  const [dayDescription, setDayDescription] = useState('')
  const [dayData, setDayData] = useState<AddDayApiResponse['day'][]>([])
  const [dayIds, setDayIds] = useState<number[]>([])
  const [isEditingDay, setIsEditingDay] = useState(false)
  const [editingDayId, setEditingDayId] = useState('')
  const [infoTitle, setInfoTitle] = useState('')
  const [infoDescription, setInfoDescription] = useState('')
  const [infoData, setInfoData] = useState<AddInfoApiResponse['info'][]>([])
  const [infoIds, setInfoIds] = useState<number[]>([])
  const [isEditingInfo, setIsEditingInfo] = useState(false)
  const [editingInfoId, setEditingInfoId] = useState('')
  const [includes, setIncludes] = useState([])
  const [excludes, setExcludes] = useState([])
  const [inclusion, setInclusion] = useState('')
  const [includesDetails, setIncludesDetails] = useState<any[]>([])
  const [selectedIncludes, setSelectedIncludes] = useState<string[]>([])
  const [selectedIncludesText, setSelectedIncludesText] = useState<string[]>([])
  const [selectedExcludes, setSelectedExcludes] = useState<string[]>([])
  const [selectedExcludesText, setSelectedExcludesText] = useState<string[]>([])
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [selectedThumbnailFiles, setSelectedThumbnailFiles] = useState<File[]>([])
  const [selectedFilesIds, setSelectedFilesIds] = useState<String[]>([])
  const [selectedThumbnailFilesIds, setSelectedThumbnailFilesIds] = useState<String[]>([])
  const [packageList, setPackageList] = useState([])
  const [adultPrice, setAdultPrice] = useState('')
  const [childPrice, setChildPrice] = useState('')
  const [infantPrice, setInfantPrice] = useState('')
  const [isFlightIncluded, setIsFlightIncluded] = useState(false)
  const [isResort, setIsResort] = useState(false)
  const [isCruise, setIsCruise] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [amount, setAmount] = useState('')
  const [resortDates, setResortDates] = useState<AddTimingApiResponse[]>([])
  const [selectedResortDateIds, setSelectedResortDateIds] = useState<String[]>([])

  const handleToggle = () => {
    setIsActive(!isActive)
  }

  const handleIncludeCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = event.target.value
    if (event.target.checked) {
      setSelectedIncludes([...selectedIncludes, value])
      setSelectedIncludesText([...selectedIncludesText, (includes[index] as any).included])
    } else {
      setSelectedIncludes(selectedIncludes.filter((item) => item !== value))
      setSelectedIncludesText(
        selectedIncludesText.filter((item) => item !== (includes[index] as any).included)
      )
    }
  }

  const handleExcludeCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = event.target.value
    if (event.target.checked) {
      setSelectedExcludes([...selectedExcludes, value])
      setSelectedExcludesText([...selectedExcludesText, (excludes[index] as any).excluded])
    } else {
      setSelectedExcludes(selectedExcludes.filter((item) => item !== value))
      setSelectedExcludesText(
        selectedExcludesText.filter((item) => item !== (excludes[index] as any).excluded)
      )
    }
  }

  const icons = [
    'day1',
    'day2',
    'day3',
    'day4',
    'day5',
    'day6',
    'day7',
    'day8',
    'day9',
    'day10',
    'day11',
    'day12',
    'day13',
    'day14',
    'day15',
    'day16',
    'day17',
    'day18',
    'day19',
    'day20',
    'day21',
    'day22',
    'day23',
    'day24',
    'day25',
    'day26',
    'day27',
  ]

  const handleSearchClick = () => {
    // Implement your search logic here
    alert('Search button clicked')
  }

  const iconClass = isActive ? 'active-icon' : 'inactive-icon'

  const [pricingList, setPricingList] = useState([])
  const [activeTab, setActiveTab] = useState('Tab2')

  const {id} = useParams()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles([...selectedFiles, ...Array.from(e.target.files)])
    }
  }

  const handleThumbnailFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedThumbnailFiles([...selectedThumbnailFiles, ...Array.from(e.target.files)])
    }
  }

  const handleThumbnailUpload = async () => {
    if (selectedThumbnailFiles.length == 0) {
      toast.error('Please select atleast one file')
    } else {
      const formData = new FormData()

      for (const file of selectedThumbnailFiles) {
        formData.append('images[]', file)
      }

      try {
        const response = await axios.post(
          'https://admin.trawelmart.com/admin/public/index.php/api/uploadTourThumbnailImage',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        if (response.data.status) {
          const photosInput = document.getElementById('thumbnailInputFiles') as HTMLInputElement
          if (photosInput) {
            photosInput.value = ''
            if (photosInput.files) {
              photosInput.files = null as any // Clear files
            }
          }
          setSelectedThumbnailFilesIds((prevFileIds) => [...prevFileIds, ...response.data.files])
          setSelectedThumbnailFiles([])
        } else {
          toast.error('Some error occurred')
        }
      } catch (error) {
        console.error('Upload failed ', error)
      }
    }
  }

  function deleteThumbnailImage()
  {
    setSelectedThumbnailFilesIds([]);
  }

  const handleUpload = async () => {
    if (selectedFiles.length == 0) {
      toast.error('Please select atleast one file')
    } else {
      const formData = new FormData()

      for (const file of selectedFiles) {
        formData.append('images[]', file)
      }

      try {
        const response = await axios.post(
          'https://admin.trawelmart.com/admin/public/index.php/api/uploadTourImages',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        if (response.data.status) {
          const photosInput = document.getElementById(
            'photosInputFiles'
          ) as HTMLInputElement
          if (photosInput) {
            photosInput.value = ''
            if (photosInput.files) {
              photosInput.files = null as any // Clear files
            }
          }
          setSelectedFilesIds((prevFileIds) => [...prevFileIds, ...response.data.files]);
          setSelectedFiles([]);
        } else {
          toast.error('Some error occurred')
        }
      } catch (error) {
        console.error('Upload failed ', error)
      }
    }
  }

  function editDayData(day: any, index: any) {
    setIsEditingDay(true)
    setSelectedDay(dayData[index].day)
    setDayTitle(dayData[index].day_title)
    setDayTag(dayData[index].day_tag)
    setDayHighlight(dayData[index].day_highlight)
    setIconName(dayData[index].day_icon)
    setDayDescription(dayData[index].day_description)
    setEditingDayId(day)
  }

  function editInfoData(info: any, index: any) {
    setIsEditingInfo(true)
    setInfoTitle(infoData[index].title)
    setInfoDescription(infoData[index].description)
    setEditingInfoId(info)
  }

  function deleteDayData(id: any, deleteIndex: any) {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteDaywiseDetails/' + id)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setDayData((prevDayData) => {
              const updatedDayData = prevDayData.filter((_, index) => index !== deleteIndex)
              return updatedDayData
            })

            setDayIds((prevDayDataId) => {
              const updatedDayDataId = prevDayDataId.filter((_, index) => index !== deleteIndex)
              return updatedDayDataId
            })
          }
        }
      })
  }

  function deleteInfoData(id: any, deleteIndex: any) {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteTourInformation/' + id)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setInfoData((prevInfoData) => {
              const updatedInfoData = prevInfoData.filter((_, index) => index !== deleteIndex)
              return updatedInfoData
            })

            setInfoIds((prevInfoDataId) => {
              const updatedInfoDataId = prevInfoDataId.filter((_, index) => index !== deleteIndex)
              return updatedInfoDataId
            })
          }
        }
      })
  }

  function deleteImages(index: number) {
    const newFileIds = [...selectedFilesIds]
    newFileIds.splice(index, 1)
    setSelectedFilesIds(newFileIds)
  }

  useEffect(() => {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/getExclusions')
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setExcludes(response.data.exclusions)
          }
        }
      })
  }, [])

  // useEffect(() => {
  //   axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getInclusions")
  //     .then((response) => {
  //       if (response.status == 200) {
  //         if (response.data.status) {
  //           setIncludes(response.data.inclusions);
  //         }
  //       }
  //     })
  // }, []);

  useEffect(() => {
    const newDaysOptions: string[] = []

    for (let i = 1; i <= days; i++) {
      newDaysOptions.push(`Day ${i}`)
    }

    setDaysOptions(newDaysOptions)
  }, [days])

  useEffect(() => {
    const accordionItemToClose = document.querySelector('#icons_accordion')

    if (accordionItemToClose) {
      // Remove the 'show' class from the accordion body to close it
      const accordionBody = accordionItemToClose.querySelector('.accordion-collapse')
      ;(accordionBody as any).classList.remove('show')
    }
  }, [iconName])

  useEffect(() => {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/getTourDetail/' + id)
      .then((response) => {
        if (response.data.status) {
          response.data.tour.tour_title ? setTitle(response.data.tour.tour_title) : setTitle('')
          response.data.tour.visited_people_count
            ? setVisitorCount(response.data.tour.visited_people_count)
            : setVisitorCount(0)
          setDays(response.data.tour.tour_days)
          setCity(response.data.tour.tour_city)
          response.data.tour.slug ? setSlug(response.data.tour.slug) : setSlug('')
          setCountry(response.data.tour.tour_country)
          setTourType(response.data.tour.tour_type)
          setDayIds(response.data.tour.days_details.split(', '))
          setInfoIds(response.data.tour.tour_info_details.split(', '))
          setSelectedExcludes(response.data.tour.excludes_details.split(', '))
          setSelectedIncludes(response.data.tour.includes_details.split(', '))
          setSelectedFilesIds(response.data.tour.photos_details.split(', '))
          setIncludesDetails(response.data.includes)
          response.data.tour.night_description
            ? setNightDescription(response.data.tour.night_description)
            : setNightDescription('')
          response.data.tour.is_flight
            ? setIsFlightIncluded(response.data.tour.is_flight === 'Yes')
            : setIsFlightIncluded(false)
          response.data.tour.is_resort
            ? setIsResort(response.data.tour.is_resort === 'Yes')
            : setIsResort(false)
          response.data.tour.is_cruise
            ? setIsCruise(response.data.tour.is_cruise === 'Yes')
            : setIsCruise(false)
          response.data.timing ? setResortDates(response.data.timing) : setResortDates([])
          response.data.tour.resort_dates
            ? setSelectedResortDateIds(response.data.tour.resort_dates.split(', '))
            : setSelectedResortDateIds([])
          response.data.tour.thumbnail_image ? setSelectedThumbnailFilesIds(response.data.tour.thumbnail_image.split(", ")) : setSelectedThumbnailFilesIds([])
        }
      })
  }, [])

  useEffect(() => {
    if (city != '' && country != '') {
      const formData = {city: city, country: country}
      axios
        .post('https://admin.trawelmart.com/admin/public/index.php/api/getPackagesByCity', formData)
        .then((response) => {
          console.log('response is ' + response.data.status)
          if (response.data.status) {
            setPackageList(response.data.packages)
          } else {
            setPackageList([])
          }
        })
    }
  }, [country, city])

  useEffect(() => {
    if (title != '') {
      const index = packageList.findIndex((obj) => (obj as any).package_name === title)
      if (packageList[index]) {
        axios
          .get(
            'https://admin.trawelmart.com/admin/public/index.php/api/getPricingListByPackage/' +
              (packageList[index] as any).id
          )
          .then((response) => {
            setAdultPrice((response.data as any).adult)
            setChildPrice((response.data as any).child)
            setInfantPrice((response.data as any).infant)
          })
      }
    }
  }, [title, packageList])

  useEffect(() => {
    setDayData([])
    const axiosRequests = dayIds.map((dayId) => {
      return axios
        .get('https://admin.trawelmart.com/admin/public/index.php/api/getDaywiseDetail/' + dayId)
        .then((response) => {
          if (response.data.status) {
            return response.data.day
          }
          return null
        })
    })

    Promise.all(axiosRequests).then((dayResponses) => {
      // Use a Set to track unique day.id values
      const uniqueDayIds = new Set()
      const filteredDayData = dayResponses.filter((day) => {
        if (day && !uniqueDayIds.has(day.id)) {
          uniqueDayIds.add(day.id)
          return true
        }
        return false
      })

      setDayData(filteredDayData)
    })
  }, [dayIds])

  useEffect(() => {
    setInfoData([])
    const axiosRequests = infoIds.map((infoId) => {
      return axios
        .get('https://admin.trawelmart.com/admin/public/index.php/api/getTourInformation/' + infoId)
        .then((response) => {
          if (response.data.status) {
            return response.data.info
          }
          return null
        })
    })

    Promise.all(axiosRequests).then((infoResponses) => {
      // Use a Set to track unique day.id values
      const uniqueInfoIds = new Set()
      const filteredInfoData = infoResponses.filter((info) => {
        if (info && !uniqueInfoIds.has(info.id)) {
          uniqueInfoIds.add(info.id)
          return true
        }
        return false
      })

      setInfoData(filteredInfoData)
    })
  }, [infoIds])

  const handleTabClick = (tabName: React.SetStateAction<string>) => {
    setActiveTab(tabName)
  }

  const handleChange = (html: any) => {
    setInfoDescription(html)
  }

  const handleChange1 = (html: any) => {
    setDayDescription(html)
  }

  function addDayData() {
    if (selectedDay == '') {
      toast.error('Please select a day')
    } else if (iconName == '') {
      toast.error('Please select an icon')
    } else if (dayTitle == '') {
      toast.error('Please enter the title')
    } else if (dayTag == '') {
      toast.error('Please enter the badge tag')
    } else if (dayHighlight == '') {
      toast.error('Please enter the highlight of day')
    } else if (dayDescription == '') {
      toast.error('Please enter the description for the day')
    } else {
      if (isEditingDay) {
        const formData = {
          day: selectedDay,
          day_icon: iconName,
          day_title: dayTitle,
          day_description: dayDescription,
          id: editingDayId,
          day_tag: dayTag,
          day_highlight: dayHighlight,
        }
        axios
          .post(
            'https://admin.trawelmart.com/admin/public/index.php/api/updateDaywiseDetails',
            formData
          )
          .then((response) => {
            console.log(response.data)
            if (response.status == 200) {
              if (response.data.status) {
                const existingIndex = dayData.findIndex((day) => day.id === response.data.day.id)
                if (existingIndex !== -1) {
                  // If an item with the same ID exists, replace it with the updated data
                  const updatedDayData = [...dayData]
                  updatedDayData[existingIndex] = response.data.day
                  setDayData(updatedDayData)
                } else {
                  // If no item with the same ID exists, add it to the end of the array
                  setDayData((prevDayData) => [...prevDayData, response.data.day])
                }
                setSelectedDay('')
                setIconName('')
                setDayTitle('')
                setDayTag('')
                setDayDescription('')
                setDayHighlight('')
                setIsEditingDay(false)
                setEditingDayId('')
              }
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        const formData = {
          day: selectedDay,
          day_icon: iconName,
          day_title: dayTitle,
          day_description: dayDescription,
          day_tag: dayTag,
          day_highlight: dayHighlight,
        }
        axios
          .post(
            'https://admin.trawelmart.com/admin/public/index.php/api/addDaywiseDetails',
            formData
          )
          .then((response) => {
            console.log(response.data)
            if (response.status == 200) {
              if (response.data.status) {
                setDayData((prevDayData) => [...prevDayData, response.data.day])
                setDayIds((prevDayDataId) => [...prevDayDataId, response.data.day.id])
                setSelectedDay('')
                setIconName('')
                setDayTitle('')
                setDayTag('')
                setDayHighlight('')
                setDayDescription('')
              }
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }

  function cancelEditingDay() {
    setEditingDayId('')
    setSelectedDay('')
    setIconName('')
    setDayTitle('')
    setDayTag('')
    setDayHighlight('')
    setDayDescription('')
    setIsEditingDay(false)
  }

  function cancelEditingInfo() {
    setEditingInfoId('')
    setInfoTitle('')
    setInfoDescription('')
    setIsEditingInfo(false)
  }

  function addInfo() {
    if (infoTitle == '') {
      toast.error('Please enter title')
    } else if (infoDescription == '') {
      toast.error('Please enter description')
    } else {
      if (isEditingInfo) {
        const formData = {title: infoTitle, description: infoDescription, id: editingInfoId}
        axios
          .post(
            'https://admin.trawelmart.com/admin/public/index.php/api/updateTourInformation',
            formData
          )
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status) {
                const existingIndex = infoData.findIndex(
                  (info) => info.id === response.data.info.id
                )
                if (existingIndex !== -1) {
                  // If an item with the same ID exists, replace it with the updated data
                  const updatedInfoData = [...infoData]
                  updatedInfoData[existingIndex] = response.data.info
                  setInfoData(updatedInfoData)
                } else {
                  // If no item with the same ID exists, add it to the end of the array
                  setInfoData((prevInfoData) => [...prevInfoData, response.data.info])
                }
                setInfoTitle('')
                setInfoDescription('')
                setIsEditingInfo(false)
                setEditingInfoId('')
              }
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        const formData = {title: infoTitle, description: infoDescription}
        axios
          .post(
            'https://admin.trawelmart.com/admin/public/index.php/api/addTourInformation',
            formData
          )
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status) {
                setInfoData((prevInfoData) => [...prevInfoData, response.data.info])
                setInfoIds((prevInfoId) => [...prevInfoId, response.data.info.id])
                setInfoTitle('')
                setInfoDescription('')
              }
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }

  function addTourDetails() {
    if (title == '') {
      toast.error('Please enter the tour title')
    } else if (days == 0) {
      toast.error('Please enter the number of days')
    } else if (country == '') {
      toast.error('Please select the country')
    } else if (city == '') {
      toast.error('Please enter the city')
    } else if (slug == '') {
      toast.error('Please enter the slug')
    } else if (tourType == '') {
      toast.error('Please select the tour type')
    } else if (dayIds.length == 0) {
      toast.error('Please enter the daywise plan')
    } else if (infoIds.length == 0) {
      toast.error('Please enter the information details')
    } else if (selectedIncludes.length == 0) {
      toast.error('Please select atleast one included option')
    } else if (selectedExcludes.length == 0) {
      toast.error('Please select atleast one excluded option')
    } else if (selectedFilesIds.length == 0) {
      toast.error('Please upload atleast one image')
    } else if (selectedThumbnailFilesIds.length == 0) {
      toast.error('Please upload thumbnail image')
    } else {
      const days_details = dayIds.join(', ')
      const tour_info_details = infoIds.join(', ')
      const include_details = selectedIncludes.join(', ')
      const exclude_details = selectedExcludes.join(', ')
      const photo_details = selectedFilesIds.join(', ')
      const timing_details = selectedResortDateIds.join(', ')
      const thumbnail = selectedThumbnailFilesIds.join(", ")
      const formData = {
        tour_title: title,
        tour_days: days,
        tour_city: city,
        tour_type: tourType,
        tour_country: country,
        days_details: days_details,
        tour_info_details: tour_info_details,
        includes_details: include_details,
        excludes_details: exclude_details,
        photos_details: photo_details,
        id: id,
        slug: slug,
        night_description: nightDescription,
        is_flight: isFlightIncluded ? 'Yes' : 'No',
        is_resort: isResort ? 'Yes' : 'No',
        is_cruise: isCruise ? 'Yes' : 'No',
        resort_dates: timing_details,
        thumbnail_image : thumbnail
      }
      axios
        .post('https://admin.trawelmart.com/admin/public/index.php/api/updateTourDetails', formData)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              toast.success('Tour details updated successfully...')
              setTimeout(() => {
                ;(window as any).location = '/apps/tourdetail/list-detail'
              }, 1000)
            } else {
              toast.error(response.data.message)
            }
          }
        })
    }
  }

  function addInclusion() {
    if (inclusion == '') {
      toast.error('Please enter any inclusion')
    } else {
      const formData = {included: inclusion, icon: 'day1'}
      axios
        .post(
          'https://admin.trawelmart.com/admin/public/index.php/api/addInclusionForItinery',
          formData
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              setSelectedIncludes([...selectedIncludes, response.data.includes.id])
              setIncludesDetails([...includesDetails, response.data.includes])
              setInclusion('')
            }
          }
        })
    }
  }

  function deleteIncludes(id: any, deleteIndex: any) {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteInclusion/' + id)
      .then((response) => {
        if (response.data.status) {
          toast.success('Deleted Successfully...')
          setSelectedIncludes((prevInclude) => {
            const updatedSelectedIncludes = prevInclude.filter((_, index) => index !== deleteIndex)
            return updatedSelectedIncludes
          })

          setIncludesDetails((prevIncludeDetails) => {
            const updatedIncludeDetails = prevIncludeDetails.filter(
              (_, index) => index !== deleteIndex
            )
            return updatedIncludeDetails
          })
        }
      })
  }

  function addResortPricing() {
    if (startDate == '') {
      toast.error('Please select start month')
    } else if (endDate == '') {
      toast.error('Please select end month')
    } else if (amount == '') {
      toast.error('Please enter the amount')
    } else {
      const formData = {start_month: startDate, end_month: endDate, amount: amount}
      axios
        .post('https://admin.trawelmart.com/admin/public/index.php/api/addResortTiming', formData)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status) {
              toast.success('Timing added successfully..')
              setResortDates((prevResortDates) => [...prevResortDates, response.data.timing])
              setSelectedResortDateIds((prevResortDateIds) => [
                ...prevResortDateIds,
                response.data.timing.id,
              ])
              setStartDate('')
              setEndDate('')
              setAmount('')
            }
          }
        })
    }
  }

  function deletePricing(id: any, deleteIndex: any) {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteDaywiseDetails/' + id)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status) {
            setResortDates((prevResortDate) => {
              const updatedDates = prevResortDate.filter((_, index) => index !== deleteIndex)
              return updatedDates
            })

            setSelectedResortDateIds((prevDateIds) => {
              const updatedDateIds = prevDateIds.filter((_, index) => index !== deleteIndex)
              return updatedDateIds
            })
          }
        }
      })
  }

  return (
    <>
      <div className=' mb-5 mb-xl-10 mt-16'>
        <div className=''>
          {/* begin::Table container */}
          <div className='row lg:pr-3 px-4 mt-4'>
            <div className='col-lg-8'>
              <form>
                <div
                  className='card1 bg-white rounded mb-5 mb-xl-6 px-6'
                  id='kt_profile_details_view'
                >
                  <div className='row mb-9 pb-0 p-4 '>
                    <div className='col-lg-6 fv-row pr-4 pb-4 pt-4'>
                      <label className='text-lg pb-2 font-medium text-slate-500'>Country</label>
                      <select
                        className='form-control mb-3 mb-lg-0 '
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option value=''>Select Country</option>
                        {countriesData.map((country) => (
                          <option value={country.name} key={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-lg-6 fv-row pt-4'>
                      <label className=' text-lg font-medium pb-2 text-slate-500'>City</label>
                      <input
                        type='text'
                        className='border-black form-control   mb-3 mb-lg-0'
                        placeholder=''
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                    <div className='col-lg-12 mt-3'>
                      <label className=' text-lg font-medium pb-2 text-slate-500'>Title</label>
                      <select
                        className='form-control mb-3 mb-lg-0 '
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      >
                        <option value=''>Select Title</option>
                        {packageList.map((item, index) => (
                          <option value={(item as any).package_name} key={index}>
                            {(item as any).package_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className='pt-6'>
                      <div className='col-lg-12  '>
                        <label className=' text-lg font-medium pb-2 text-slate-500'>Slug</label>
                        <input
                          type='text'
                          className='border-black form-control   mb-3 mb-lg-0'
                          placeholder=''
                          value={slug}
                          onChange={(e) => setSlug(e.target.value)}
                        />
                      </div>
                      {/* <div className='mt-2'>
                                <span className='text-teal-600 bg-rose-100'>
                                  trawelmart.com/{slug}
                                </span>
                                </div> */}
                      <div className='mt-3 flex'>
                        <div className='text-teal-600 bg-rose-100 px-2 py-1 flex '>
                          <div className=''>
                            {/* <i className='las la-info-circle text-lime-500'></i> */}
                            <img src='https://i.ibb.co/NrZ2L2C/R.png' className='h-4 w-4' alt='' />
                          </div>
                          <span className='text-teal-600 px-2'>trawelmart.com/{slug}</span>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <div className='col-lg-12 pt-6'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            <label className='text-lg font-medium pb-2 text-slate-500'>Days</label>
                            <input
                              type='number'
                              className='border-black form-control   mb-3 mb-lg-0'
                              placeholder=''
                              value={days}
                              onChange={(e) => setDays(parseInt(e.target.value, 10))}
                            />
                          </div>
                          <div className='col-lg-4'>
                            <label className=' text-lg font-medium pb-2 text-slate-500'>
                              Number of Guests
                            </label>
                            <input
                              type='number'
                              className='border-black form-control   mb-3 mb-lg-0'
                              value={visitorCount}
                              onChange={(e) => setVisitorCount(parseInt(e.target.value, 10))}
                            />
                          </div>

                          <div className='col-lg-4 fv-row pl-2'>
                            <label className=' text-lg font-medium pb-2 text-slate-500'>Type</label>
                            <select
                              className='form-control border-black mb-3 mb-lg-0 '
                              value={tourType}
                              onChange={(e) => setTourType(e.target.value)}
                            >
                              <option value='' selected hidden disabled>
                                Select
                              </option>
                              <option value='Standard'>Standard</option>
                              <option value='Luxury'>Luxury</option>
                              <option value='Premium'>Premium</option>
                              <option value='Group'>Group</option>
                            </select>
                          </div>

                          <div className=''>
                            <div className='col-lg-15'>
                              <div className='row'>
                                <div className='col-lg-15 fv-row px-4 mt-8'>
                                  <label className='pb-2 text-lg font-medium text-slate-500'>
                                    Night Description
                                  </label>
                                  <input
                                    type='text'
                                    className='border-black form-control   mb-3 mb-lg-0'
                                    placeholder=''
                                    value={nightDescription}
                                    onChange={(e) => setNightDescription(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='col-lg-12'>
                              <div className='row'>
                                <div className='col-lg-12 flex fv-row px-4 mt-5'>
                                  <label className='col-lg-3 pt-3 text-2xl font-medium text-slate-500 fs-6'>
                                    Is Flight Included?
                                  </label>
                                  <div className='col-lg-3 pt-4 -ml-6'>
                                    <input
                                      id='yes'
                                      type='radio'
                                      name='islandLanding'
                                      className='form-check-inline'
                                      checked={isFlightIncluded}
                                      onChange={(e) => setIsFlightIncluded(true)}
                                    />
                                    <label htmlFor='yes' className='text-slate-500'>Yes</label>
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                      id='no'
                                      type='radio'
                                      name='islandLanding'
                                      className='form-check-inline'
                                      checked={!isFlightIncluded}
                                      onChange={(e) => setIsFlightIncluded(false)}
                                    />
                                    <label htmlFor='no' className='text-slate-500'>No</label>
                                  </div>
                                </div>
                                <div className='col-lg-12 flex fv-row px-4 mt-5'>
                                  <label className='col-lg-3 pt-3 text-2xl font-medium text-slate-500 fs-6'>
                                    Is Cruise Included?
                                  </label>
                                  <div className='col-lg-3 pt-4 -ml-6'>
                                    <input
                                      id='yes'
                                      type='radio'
                                      name='isCruise'
                                      className='form-check-inline'
                                      checked={isCruise}
                                      onChange={(e) => setIsCruise(true)}
                                    />
                                    <label htmlFor='yes' className='text-slate-500'>Yes</label>
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                      id='no'
                                      type='radio'
                                      name='isCruise'
                                      className='form-check-inline'
                                      checked={!isCruise}
                                      onChange={(e) => setIsCruise(false)}
                                    />
                                    <label htmlFor='no' className='text-slate-500'>No</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='card1 bg-white rounded mb-5 mb-xl-6 px-6'
                        id='kt_profile_details_view'
                      >
                        <div className='row mb-9 pb-0 p-4 '>
                          <div className='col-lg-12'>
                            <div className='row'>
                              <div className='col-lg-12 flex fv-row px-4 mt-4'>
                                <label className='col-lg-2 text-2xl font-medium text-slate-500 fs-6'>
                                  Is Resort?
                                </label>
                                <div className='col-lg-4 pt-0 ml-13'>
                                  <input
                                    id='yes_1'
                                    type='radio'
                                    name='isResort'
                                    className='form-check-inline'
                                    checked={isResort}
                                    onChange={(e) => setIsResort(true)}
                                  />
                                  <label htmlFor='yes_1' className='text-slate-500'>Yes</label>
                                  &nbsp;&nbsp;&nbsp;
                                  <input
                                    id='no_1'
                                    type='radio'
                                    name='isResort'
                                    className='form-check-inline'
                                    checked={!isResort}
                                    onChange={(e) => setIsResort(false)}
                                  />
                                  <label htmlFor='no_1' className='text-slate-500'>No</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='card-title lg:pt-6'>
                            <h1 className='font-medium  text-xl  text-teal-600  mt-6'>
                              Resort Timing Information
                            </h1>
                          </div>
                          <div className='border-b'></div>
                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg font-medium pb-2 text-slate-500'>From</label>
                            <input
                              type='month'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Starting Month'
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>
                          <div className='col-lg-6 fv-row px-2 pt-6'>
                            <label className=' text-lg font-medium pb-2 text-slate-500'>To</label>
                            <input
                              type='month'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Starting Month'
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>
                          <div className='col-lg-12 fv-row px-2 pt-6'>
                            <label className=' text-lg font-medium pb-2 text-slate-500'>
                              Amount
                            </label>
                            <input
                              type='number'
                              className='border-black form-control mb-3 mb-lg-0'
                              placeholder='Amount'
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </div>
                          <div className='pt-10'>
                            <button
                              type='button'
                              className='btn btn-primary mb-4 w-32 py-2'
                              onClick={addResortPricing}
                            >
                              Add
                            </button>
                          </div>
                          <div className='py-3 pt-6'>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                                <thead>
                                  <tr className='font-medium text-slate-600 bg-rose-100'>
                                    <th className='min-w-40px px-4 font-medium'>Start Month</th>
                                    <th className='min-w-40px px-4 font-medium'>End Month</th>
                                    <th className='min-w-150px font-medium'> Amount </th>
                                    <th className='min-w-20px font-medium'>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {resortDates.map((item, index) => (
                                    <tr>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).start_month}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).end_month}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column px-4 pl-4'>
                                            {(item as any).amount}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className=' flex px-4'>
                                          <a
                                            href='#!'
                                            onClick={() => deletePricing((item as any).id, index)}
                                          >
                                            <i
                                              className='fas fa-trash text-danger'
                                              style={{fontSize: '18px'}}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div
                        className='card1 bg-white rounded mb-5 mb-xl-6 px-6'
                        id='kt_profile_details_view'
                      >
                        <div className='row mb-9 pb-0 p-4 '>
                          <div className='row pt-2'>
                            <div className='card-title lg:pt-6'>
                              <h1 className='font-medium  text-xl  text-teal-600 '>
                                Itinerary Description
                              </h1>
                            </div>
                            <div className='border-b'></div>
                            <div className='col-lg-6 fv-row pr-4 pt-6'>
                              <label className=' text-lg font-medium pb-2 text-slate-500'>
                                Days
                              </label>
                              <select
                                className='form-control border-black mb-3 mb-lg-0 '
                                value={selectedDay}
                                onChange={(e) => setSelectedDay(e.target.value)}
                              >
                                <option value='' selected hidden disabled>
                                  Select
                                </option>
                                {daysOptions.map((item, index) => (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className='col-lg-6 fv-row pr-4 pt-12 mt-2'>
                              <div
                                className='accordion '
                                id='icons_accordion'
                                style={{
                                  border: '1px solid rgb(214 214 214 / 49%)',
                                  borderRadius: '5px',
                                }}
                              >
                                <div className='accordion-item'>
                                  <h4 className='accordion-header' id='icons_accordion_header'>
                                    <button
                                      className='icons_accordion_button fs-8 collapsed p-4 text-sm fw-medium'
                                      type='button'
                                      data-bs-toggle='collapse'
                                      data-bs-target='#icons_accordion_body'
                                      aria-expanded='false'
                                      aria-controls='icons_accordion_body'
                                      onClick={openPopup}
                                    >
                                      Choose Icon (Click to open/close)
                                    </button>
                                  </h4>
                                  <div
                                    id='icons_accordion_body'
                                    className='accordion-collapse collapse'
                                    aria-labelledby='icons_accordion_header'
                                    data-bs-parent='#icons_accordion'
                                  >
                                    {showPopup && (
                                      <div className='popup '>
                                        <div className='popup-content'>
                                          <span
                                            className='close text-primary text-2xl font-bold'
                                            onClick={closePopup}
                                          >
                                            &times;
                                          </span>
                                          <div className='accordion-body'>
                                            {icons.map((icon) => (
                                              <button
                                                key={icon}
                                                onClick={() => setIconName(icon)}
                                                type='button'
                                                style={{
                                                  border:
                                                    icon === iconName
                                                      ? '2px solid #0585c8'
                                                      : undefined,
                                                }}
                                              >
                                                <img
                                                  className='mr-3 mt-2 mb-2 ml-1 p-1'
                                                  src={'/media/icons/custom/' + icon + '.png'}
                                                  style={{
                                                    maxWidth: '75px',
                                                    width: '100%',
                                                    height: 'auto',
                                                  }}
                                                />
                                              </button>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-12 fv-row px-2 mt-4'>
                              <label className=' text-lg font-medium pb-2 text-slate-500'>
                                Title
                              </label>
                              <input
                                type='text'
                                className='border-black form-control   mb-3 mb-lg-0'
                                placeholder=''
                                value={dayTitle}
                                onChange={(e) => setDayTitle(e.target.value)}
                              />
                            </div>

                            <div className='col-lg-6 fv-row px-2 mt-4'>
                              <label className=' text-lg font-medium pb-2 text-slate-500'>
                                Tag
                              </label>
                              <input
                                type='text'
                                className='border-black form-control   mb-3 mb-lg-0'
                                placeholder=''
                                value={dayTag}
                                onChange={(e) => setDayTag(e.target.value)}
                              />
                            </div>
                            <div className='col-lg-6 fv-row px-2 mt-4'>
                              <label className=' text-lg font-medium pb-2 text-slate-500'>
                                Highlight
                              </label>
                              <input
                                type='text'
                                className='border-black form-control   mb-3 mb-lg-0'
                                placeholder=''
                                value={dayHighlight}
                                onChange={(e) => setDayHighlight(e.target.value)}
                              />
                            </div>
                            <div className='pb-12 pt-12'>
                              <h6 className=' text-slate-500'>Description</h6>
                              <ReactQuill
                                className='h-60'
                                value={dayDescription}
                                onChange={handleChange1}
                                modules={{
                                  toolbar: [
                                    [{header: '1'}, {header: '2'}, {font: []}],
                                    ['bold', 'italic', 'underline'],
                                    [{list: 'ordered'}, {list: 'bullet'}],
                                    ['link', 'image'],
                                    [{align: []}],
                                    ['clean'],
                                    ['blockquote'],
                                    [{size: ['small', false, 'large', 'huge']}], // Font size options
                                  ],
                                }}
                              />
                              {/*<div className='output pt-32'>
                      <h3>Preview:</h3>
                      <div dangerouslySetInnerHTML={{__html: editorHtml}} />
                    </div>*/}
                            </div>
                            <div className='pt-6'>
                              <button
                                type='button'
                                className='btn btn-success w-32 py-2 mx-2'
                                onClick={addDayData}
                              >
                                {isEditingDay ? 'Update' : 'Add'}
                              </button>

                              {isEditingDay && (
                                <button
                                  type='button'
                                  className='btn btn-danger mx-2'
                                  onClick={cancelEditingDay}
                                >
                                  Cancel
                                </button>
                              )}
                            </div>
                            <div className='py-3 pt-12'>
                              {/* begin::Table container */}
                              <div className='table-responsive'>
                                {/* begin::Table */}
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                                  {/* begin::Table head */}
                                  <thead>
                                    <tr className='fw-bold text-slate-600 bg-rose-100'>
                                      <th className='min-w-40px px-4 font-medium'>Day</th>
                                      <th className='min-w-150px font-medium'>Title </th>
                                      <th className='min-w-20px font-medium'>Tag</th>
                                      <th className='min-w-20px font-medium'>Highlight</th>
                                      <th className='min-w-150px font-medium'>Description </th>
                                      <th className='min-w-20px font-medium'>Actions</th>
                                    </tr>
                                  </thead>
                                  {/* end::Table head */}
                                  {/* begin::Table body */}
                                  <tbody>
                                    {dayData.map((item, index) => (
                                      <tr>
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column px-4'>
                                              {(item as any).day}
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                              {(item as any).day_title}
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                              {(item as any).day_tag}
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                              {(item as any).day_highlight}
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: truncateDescription(
                                                    item.day_description,
                                                    30
                                                  ),
                                                }}
                                                title={item.day_description}
                                              ></div>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className=' flex px-4'>
                                            <a
                                              href='#!'
                                              onClick={() => editDayData((item as any).id, index)}
                                            >
                                              <i
                                                className='fas fa-pencil text-primary'
                                                style={{fontSize: '18px'}}
                                              ></i>
                                            </a>
                                            &nbsp;&nbsp;&nbsp;
                                            <a
                                              href='#!'
                                              onClick={() => deleteDayData((item as any).id, index)}
                                            >
                                              <i
                                                className='fas fa-trash text-danger'
                                                style={{fontSize: '18px'}}
                                              ></i>
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  {/* end::Table body */}
                                </table>
                                {/* end::Table */}
                              </div>
                              {/* end::Table container */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className='card1 rounded bg-white mb-5 mb-xl-6 px-6 pb-10'
                  id='kt_profile_details_view'
                >
                  <div className='row mb-9 pb-0 p-4 '>
                    <div className='card-title pt-2'>
                      <h1 className='font-medium  text-xl  text-teal-600 '>Tour Information</h1>
                    </div>
                    <div className='border-b'></div>
                    <div className='col-lg-12 fv-row px-2 pt-6'>
                      <label className=' text-lg font-medium pb-2 text-slate-500'>Title</label>
                      <input
                        type='text'
                        className='border-black form-control   mb-3 mb-lg-0'
                        placeholder='About This Tour'
                        value={infoTitle}
                        onChange={(e) => setInfoTitle(e.target.value)}
                      />
                    </div>

                    <div className='pb-12 pt-12'>
                      <h6 className=' text-slate-500'>Description</h6>
                      <ReactQuill
                        className='h-60'
                        value={infoDescription}
                        onChange={handleChange}
                        modules={{
                          toolbar: [
                            [{header: '1'}, {header: '2'}, {font: []}],
                            ['bold', 'italic', 'underline'],
                            [{list: 'ordered'}, {list: 'bullet'}],
                            ['link', 'image'],
                            [{align: []}],
                            ['clean'],
                            ['blockquote'],
                            [{size: ['small', false, 'large', 'huge']}], // Font size options
                          ],
                        }}
                      />
                      {/*<div className='output pt-32'>
                      <h3>Preview:</h3>
                      <div dangerouslySetInnerHTML={{__html: editorHtml}} />
                    </div>*/}
                    </div>
                    <div className='pt-12'>
                      <button
                        type='button'
                        className='btn btn-success w-32 py-2 mx-2'
                        onClick={addInfo}
                      >
                        {isEditingInfo ? 'Update' : 'Add'}
                      </button>
                      {isEditingInfo && (
                        <button
                          type='button'
                          className='btn btn-danger mx-2'
                          onClick={cancelEditingInfo}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                    <div className='py-3 pt-6'>
                      <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                          <thead>
                            <tr className='fw-bold text-slate-600 bg-rose-100'>
                              <th className='min-w-40px px-4 font-medium'>Title</th>
                              <th className='min-w-150px font-medium'> Description </th>
                              <th className='min-w-20px font-medium'>Actions</th>
                            </tr>
                          </thead>

                          <tbody>
                            {infoData.map((item, index) => (
                              <tr>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column px-4'>
                                      {(item as any).title}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: truncateDescription(item.description, 20),
                                        }}
                                        title={item.description}
                                      ></div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className=' flex px-4'>
                                    <a
                                      href='#!'
                                      onClick={() => editInfoData((item as any).id, index)}
                                    >
                                      <i
                                        className='fas fa-pencil text-primary'
                                        style={{fontSize: '18px'}}
                                      ></i>
                                    </a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a
                                      href='#!'
                                      onClick={() => deleteInfoData((item as any).id, index)}
                                    >
                                      <i
                                        className='fas fa-trash text-danger'
                                        style={{fontSize: '18px'}}
                                      ></i>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='pt-4 pb-12 text-left'>
                  <button
                    type='button'
                    className='btn btn-success w-36 py-2 mx-2'
                    onClick={addTourDetails}
                  >
                    Update Details
                  </button>
                  <button
                    type='button'
                    className='btn btn-danger w-32 py-2 mx-2'
                    onClick={() => ((window as any).location = '/dashboard')}
                  >
                    Cancel
                  </button>
                </div> */}
              </form>
            </div>

            <div className='col-lg-4 '>
              <div className='col-lg-12'>
                <div
                  className='card1 rounded bg-white mb-5 mb-xl-6 px-6 pb-10'
                  id='kt_profile_details_view'
                >
                   <div className='flex col-lg-12'>
                    <div className='col-lg-4 card-title pt-5'>
                      <h1 className='font-medium text-xl  text-slate-500'>Pricing Details</h1>
                    </div>
                    <div className='col-lg-3 mt-5 ml-24'>
                      <button
                        type='button'
                        className='btn btn-primary ml-5 my-0 -mt-3 py-2 -mr-2'
                        onClick={addTourDetails}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  <div className='border-b pb-2'></div>

                  {/*<label className='text-lg font-medium text-black pt-4'>Adult Pricing</label>
                  <input
                    type='text'
                    className='border-black form-control mb-3 mb-lg-0'
                    placeholder=''
                    value={adultPrice}
                  />
                  <label className='text-lg font-medium text-black pt-4'>Children Pricing</label>
                  <input
                    type='text'
                    className='border-black form-control mb-3 mb-lg-0'
                    placeholder=''
                    value={childPrice}
                  />
                  <label className='text-lg font-medium text-black pt-4'>Infant Pricing</label>
                  <input
                    type='text'
                    className='border-black form-control mb-3 mb-lg-0'
                    placeholder=''
                    value={infantPrice}
                  />*/}

                  <div className='d-flex flex-column flex-grow-1 pt-6'>
                    <div className='d-flex flex-wrap'>
                      <div className='card1 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 ml-0'>
                        <div className='d-flex align-items-center w-max'>
                          <i className='las la-suitcase-rolling fs-3 text-success me-2 ml-2'></i>
                          <div className='fs-2 fw-bolder text-slate-500 ml-3'>{adultPrice}</div>
                          <div className='fw-bold fs-6 text-gray-400 ml-5'>Adult Pricing</div>
                        </div>
                      </div>
                      <div className='card1 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 ml-0'>
                        <div className='d-flex align-items-center w-max'>
                          <i className='las la-plane-departure fs-3 text-danger me-2 ml-2'></i>
                          <div className='fs-2 fw-bolder text-slate-500 ml-3'>{childPrice}</div>
                          <div className='fw-bold fs-6 text-gray-400 ml-5'>Children Pricing</div>
                        </div>
                      </div>
                      <div className='card1 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 ml-0'>
                        <div className='d-flex align-items-center w-max'>
                          <i className='las la-bed fs-3 text-warning me-2 ml-2'></i>
                          <div className='fs-2 fw-bolder text-slate-500 ml-3 '>{infantPrice}</div>
                          <div className='fw-bold fs-6 text-gray-400 ml-6'>Infant Pricing</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className='card1 bg-white rounded  mb-5 mb-xl-6 px-6 mt-8 pt-0 pb-6'
                  id='kt_profile_details_view'
                >
                  <div className='card-title pt-1 border-b mb-5'>
                    <h1 className='text-lg font-medium text-slate-500 pb-2 pt-3'>Upload Thumbnail</h1>
                  </div>
                  <div className='flex'>
                    <div>
                      <input
                        id='thumbnailInputFiles'
                        type='file'
                        className='border-black form-control mb-3 mb-lg-0'
                        onChange={handleThumbnailFileChange}
                        placeholder=''
                      />
                    </div>
                    <div>
                      <button
                        className='btn btn-primary ml-5 my-0 mt-1 py-2'
                        type='button'
                        onClick={handleThumbnailUpload}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    {selectedThumbnailFilesIds.map((item, index) => (
                      <div className='col-md-3 text-center mb-6'>
                        <a href='#!' onClick={deleteThumbnailImage}>
                          <i
                            className='las la-times'
                            style={{
                              fontSize: '12px',
                              marginTop: '10px',
                              color: 'black',
                              marginLeft: '60px',
                              border: 'white 4px solid',
                              backgroundColor: '#ffffff',
                              borderRadius: '10px',
                              zIndex: '50',
                              position: 'relative', // Added position: relative
                            }}
                          ></i>
                        </a>
                        <a
                          href={'https://admin.trawelmart.com/admin/public/' + item}
                          key={index}
                          target='_blank'
                        >
                          <img
                            src={'https://admin.trawelmart.com/admin/public/' + item}
                            style={{
                              maxWidth: '75px',
                              width: '100%',
                              marginTop: '-11px',
                              marginLeft: '0px',
                              height: '50px',
                              border: '#dddee1 4px solid',
                              gap: '10px',
                              // minWidth: '60px',
                            }}
                            key={index}
                            alt={'Image ' + index}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className='card1 bg-white rounded mb-5 mb-xl-6 px-6 pb-10'
                  id='kt_profile_details_view'
                >
                  <div className='border-b mb-5'>
                    <label className='text-lg mb-0 font-medium text-slate-500 pb-2 pt-5'>
                      Upload Photos
                    </label>
                  </div>
                  <div className='flex'>
                    <div>
                      <input
                        id='photosInputFiles'
                        type='file'
                        className='border-black form-control mb-3 mb-lg-0'
                        multiple
                        onChange={handleFileChange}
                        placeholder=''
                      />
                    </div>
                    <div>
                      <button
                        className='btn btn-primary ml-5 my-0 mt-1 py-2'
                        type='button'
                        onClick={handleUpload}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  {/* <div className='row'>
                    {selectedFilesIds.map((item, index) => (
                      <div className='col-md-3 text-center mb-6'>
                        <div>
                          <a href='#!' onClick={() => deleteImages(index)}>
                            <i
                              className='las la-times'
                              style={{
                                fontSize: '16px',
                                marginTop: '10px',
                                color: 'black',
                                marginLeft: '70px',
                                border: 'black 3px',
                                backgroundColor: '#87CEEB',
                                borderRadius: '10px',
                                zIndex: '50',
                              }}
                            ></i>
                          </a>
                        </div>
                        <a
                          href={'https://admin.trawelmart.com/admin/public/' + item}
                          key={index}
                          target='_blank'
                        >
                          <img
                            src={'https://admin.trawelmart.com/admin/public/' + item}
                            style={{
                              maxWidth: '75px',
                              width: '100%',
                              marginTop: '-5px',
                              marginLeft: '10px',
                              height: '50px',
                              minWidth: '70px',
                              border: 'grey 1px solid',
                            }}
                            key={index}
                            alt={'Image ' + index}
                          />
                        </a>

                        <a href='#!' onClick={() => deleteImages(index)}>
                          <i
                            className='fas fa-trash text-danger'
                            style={{fontSize: '16px', marginTop: '10px'}}
                          ></i>
                        </a>
                      </div>
                    ))}
                  </div> */}
                  <div className='row'>
                    {selectedFilesIds.map((item, index) => (
                      <div className='col-md-3 text-center mb-0'>
                        <div style={{position: 'relative'}}>
                          <a href='#!' onClick={() => deleteImages(index)}>
                            <i
                              className='las la-times'
                              style={{
                                fontSize: '12px',
                                marginTop: '10px',
                                color: 'black',
                                marginLeft: '60px',
                                border: 'white 4px solid',
                                backgroundColor: '#ffffff',
                                borderRadius: '10px',
                                zIndex: '50',
                                position: 'relative', // Added position: relative
                              }}
                            ></i>
                          </a>
                          <a
                            href={'https://admin.trawelmart.com/admin/public/' + item}
                            key={index}
                            target='_blank'
                          >
                            <img
                              src={'https://admin.trawelmart.com/admin/public/' + item}
                              style={{
                                maxWidth: '75px',
                                width: '100%',
                                marginTop: '-11px',
                                marginLeft: '0px',
                                height: '50px',
                                border: '#dddee1 4px solid',
                                gap: '10px',
                                // minWidth: '60px',
                              }}
                              key={index}
                              alt={'Image ' + index}
                            />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className='card1 bg-white rounded mb-5 mb-xl-6 px-6 pb-4'
                  id='kt_profile_details_view'
                >
                  <div className='card-title lg:pt-4'>
                    <h1 className='text-xl font-medium text-slate-500'>Includes</h1>
                  </div>
                  <div className='border-b'></div>
                  <div className='py-3 row'>
                    <div className='col-lg-12 pt-4 mb-4 flex'>
                      {/* <div>
                      <label className=' text-lg font-medium pb-2 text-slate-500'>Title</label>
                      </div> */}
                      <div className='col-lg-9'>
                        <input
                          type='text'
                          className='border-black form-control w-60 -mt-2 mb-3  mb-lg-0'
                          placeholder='Inclusion'
                          value={inclusion}
                          onChange={(e) => setInclusion(e.target.value)}
                        />
                      </div>
                      <div className='col-lg-3 '>
                        <button
                          type='button'
                          className='btn btn-primary ml-5 my-0 -mt-3 py-2'
                          onClick={addInclusion}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gy-4 table-bordered'>
                        <thead className='bg-rose-100 text-slate-600'>
                          {/* <th className='font-medium'>S.No</th> */}
                          <th className='pl-4 font-medium'>Included</th>
                          <th className='pl-2 font-medium'>Actions</th>
                        </thead>
                        <tbody>
                          {includesDetails.map((item: any, index: number) => (
                            <tr className='pl-4' key={index}>
                              {/* <td className='pl-4 text-center'>{index + 1}</td> */}
                              <td className='pl-4'>{(item as any).included}</td>
                              <td className='ml-4 '>
                                <a
                                  href='#!'
                                  onClick={() => deleteIncludes((item as any).id, index)}
                                >
                                  <i
                                    className='las la-trash-alt text-danger'
                                    style={{fontSize: '18px', marginLeft: '10px'}}
                                  ></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="col-lg-6">
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                          <thead className='bg-neutral-50 '>
                            <th className='pl-4'>S.No</th>
                            <th className='pl-4'>Excluded</th>
                          </thead>
                          <tbody>
                            {selectedExcludesText.map((item: any, index: number) => (
                              <tr className='pl-4' key={index}>
                                <td className='pl-4'>{index + 1}</td>
                                <td className='pl-4'>{item}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div> */}
                  </div>
                </div>

                {/* <div className='card mb-5 mb-xl-8 px-6 pb-10' id='kt_profile_details_view'>
                  <div className='card-title lg:pt-8 '>
                    <h1 className='fw-bolder text-xl text-slate-500'>Excludes</h1>
                  </div>
                  <div className='border-b'></div>
                  <div className='overflow-y-auto'>
                    <div className='content h-32 overflow-y-scroll w-4/12'>
                     
                      {activeTab === 'Tab2' && (
                        <p className='text-left'>
                          {excludes.map((item, index) => (
                            <>
                              <input
                                type='checkbox'
                                id={'excludes_' + index}
                                key={index}
                                name='exclusions[]'
                                value={String((item as any).id)}
                                onChange={(event) => handleExcludeCheckboxChange(event, index)}
                                checked={selectedExcludes.includes(String((item as any).id))}
                              />
                              <label className='pl-2' htmlFor={'excludes_' + index}>
                                {' '}
                                {(item as any).excluded}
                              </label>
                              <br />
                            </>
                          ))}
                        </p>
                      )}
                    </div>
                  </div>
                </div> */}

                <div
                  className='card1 bg-white rounded mb-5 mb-xl-6 px-6 pb-10'
                  id='kt_profile_details_view'
                >
                  <div className='card-title lg:pt-8'>
                    <h1 className='font-medium text-xl text-slate-500'>Excludes</h1>
                  </div>
                  <div className='border-b mb-4'></div>
                  <div className='overflow-y-auto card1 rounded' style={{maxHeight: '350px'}}>
                    <div className='content h-32 overflow-y-scroll w-4/12'>
                      {activeTab === 'Tab2' && (
                        <div className='text-left'>
                          {excludes.map((item, index) => (
                            <div key={index} className='flex'>
                              <input
                                type='checkbox'
                                id={'excludes_' + index}
                                name='exclusions[]'
                                value={String((item as any).id)}
                                onChange={(event) => handleExcludeCheckboxChange(event, index)}
                                checked={selectedExcludes.includes(String((item as any).id))}
                                className='mt-1 h-6 w-6 ml-2'
                              />
                              <label className='pl-2 text-lg' htmlFor={'excludes_' + index}>
                                {(item as any).excluded}
                              </label>
                              <br />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<div className='card mb-5 mb-xl-10'>
            <div
              className='card-header border-0 cursor-pointer'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title '>
                <h1 className='fw-bolder '>Itinerary Description</h1>
              </div>
            </div>

            <div className='py-3'>
              {/* begin::Table container 
              <div className='table-responsive'>
                {/* begin::Table 
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                  {/* begin::Table head 
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-40px px-4'>Day</th>
                      <th className='min-w-150px'>Title </th>
                      <th className='min-w-20px'>Tag</th>
                      <th className='min-w-20px'>Highlight</th>
                      <th className='min-w-150px'>Description </th>
                      <th className='min-w-20px'>Actions</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
          {/* begin::Table body 
                  <tbody>
                    {dayData.map((item, index) => (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column px-4'>
                              {(item as any).day}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {(item as any).day_title}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {(item as any).day_tag}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {(item as any).day_highlight}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <div dangerouslySetInnerHTML={{ __html: item.day_description }}></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className=' flex px-4'>
                            <a href="#!" onClick={() => editDayData((item as any).id, index)}>
                              <i className='fas fa-pencil text-primary' style={{ fontSize: '18px' }}></i>
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="#!" onClick={() => deleteDayData((item as any).id, index)}>
                              <i className='fas fa-trash text-danger' style={{ fontSize: '18px' }}></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {/* end::Table body 
                </table>
                {/* end::Table 
              </div>
              {/* end::Table container *
            </div>
          </div>*/}

          {/* About This Tour Content and table \

          <div className='col-lg-8 fv-row px-2'>
            <label className=' text-lg font-medium text-black'>Title</label>
            <input
              type='text'
              className='border-black form-control   mb-3 mb-lg-0'
              placeholder='About This Tour'
              value={infoTitle}
              onChange={(e) => setInfoTitle(e.target.value)}
            />
          </div>

          <div className='pb-12 pt-12'>
            <h6 className=''>Description</h6>
            <ReactQuill
              className='h-60'
              value={infoDescription}
              onChange={handleChange}
              modules={{
                toolbar: [
                  [{ header: '1' }, { header: '2' }, { font: [] }],
                  ['bold', 'italic', 'underline'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link', 'image'],
                  [{ align: [] }],
                  ['clean'],
                  ['blockquote'],
                  [{ size: ['small', false, 'large', 'huge'] }], // Font size options
                ],
              }}
            />
            {/*<div className='output pt-32'>
                      <h3>Preview:</h3>
                      <div dangerouslySetInnerHTML={{__html: editorHtml}} />
                    </div>
          </div>
          <div className='pt-12'>
            <button type='button' className='btn btn-success mx-2' onClick={addInfo}>
              {isEditingInfo ? 'Update' : 'Add'}
            </button>
            {isEditingInfo && (
              <button type='button' className='btn btn-danger mx-2' onClick={cancelEditingInfo}>
                Cancel
              </button>
            )}
          </div>*/}

          {/*<div className=' mb-5 mb-xl-10 pt-12'>
            <div
              className='card-header border-0 cursor-pointer'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title '>
                <h1 className='fw-bolder '>Further Information</h1>
              </div>
            </div>

            <div className='py-3'>

              <div className='table-responsive'>

                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>

                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-40px px-4'>Title</th>
                      <th className='min-w-150px'> Description </th>
                      <th className='min-w-20px'>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {infoData.map((item, index) => (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column px-4'>
                              {(item as any).title}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className=' flex px-4'>
                            <a href="#!" onClick={() => editInfoData((item as any).id, index)}>
                              <i className='fas fa-pencil text-primary' style={{ fontSize: '18px' }}></i>
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="#!" onClick={() => deleteInfoData((item as any).id, index)}>
                              <i className='fas fa-trash text-danger' style={{ fontSize: '18px' }}></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </table>

              </div>

            </div>
          </div>*/}

          {/* About This Tour Content Ends \

          <div className='App'>
            <div className='tab-container'>
              <div
                className={`tab ${activeTab === 'Tab1' ? 'active' : ''}`}
                onClick={() => handleTabClick('Tab1')}
              >
                Includes
              </div>
              <div
                className={`tab ${activeTab === 'Tab2' ? 'active' : ''}`}
                onClick={() => handleTabClick('Tab2')}
              >
                Excludes
              </div>
              <div className="search-container">
                <input type="text" placeholder="Search..." className='border-none py-4' />
                <button onClick={handleSearchClick}>
                  <i className="fas fa-search pl-2"></i>
                </button>
              </div>
            </div>

            <div className='content h-32 overflow-y-scroll w-6/12'>
              {activeTab === 'Tab1' && (
                <p className='text-left'>
                  {includes.map((item, index) => (
                    <>
                      <input type='checkbox' id={'includes_' + index} key={index} name='inclusions[]' value={String((item as any).id)} onChange={(event) => handleIncludeCheckboxChange(event, index)} checked={selectedIncludes.includes(String((item as any).id))} />
                      <label className='pl-2' htmlFor={'includes_' + index}>
                        {' '}
                        {(item as any).included}
                      </label>
                      <br />
                    </>
                  ))}
                </p>
              )}
              {activeTab === 'Tab2' && (
                <p className='text-left'>
                  {excludes.map((item, index) => (
                    <>
                      <input type='checkbox' id={'excludes_' + index} key={index} name='exclusions[]' value={String((item as any).id)} onChange={(event) => handleExcludeCheckboxChange(event, index)} checked={selectedExcludes.includes(String((item as any).id))} />
                      <label className='pl-2' htmlFor={'excludes_' + index}>
                        {' '}
                        {(item as any).excluded}
                      </label>
                      <br />
                    </>
                  ))}
                </p>
              )}
            </div>
          </div>

        </div>
        <div className='card-body py-3 row'>
          <div className="col-lg-6">
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
              <thead>
                <th>S.No</th>
                <th>Included</th>
              </thead>
              <tbody>
                {selectedIncludesText.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-lg-6">
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
              <thead>
                <th>S.No</th>
                <th>Excluded</th>
              </thead>
              <tbody>
                {selectedExcludesText.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='pt-8 text-left'>
            <button type='button' className='btn btn-success mx-2' onClick={addTourDetails}>
              Update Details
            </button>
            <button type='button' className='btn btn-danger mx-2' onClick={() => (window as any).location = '/dashboard'}>
              Cancel
            </button>
          </div>*/}
        </div>
      </div>
    </>
  )
}
export {EditDetail}
