/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ChartsWidget1,
} from '../../../_metronic/partials/widgets';
import axios from 'axios';
import { Link } from 'react-router-dom';

const DashboardPage: FC = () => {
  const [contacts, setContacts] = useState([]);
  const [packages, setPackages] = useState([]);
  const [landing, setLanding] = useState([]);
  const [islands, setIslands] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    axios.get('https://admin.trawelmart.com/admin/public/index.php/api/getContacts').then((response) => {
      setContacts(response.data);
    });
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/getLandingPage')
      .then((response) => {
        if (response.data.status && response.status == 200) {
          setLanding(response.data.pages)
        }
      });
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/getTourDetails')
      .then((response) => {
        if (response.data.status && response.status == 200) {
          setPackages(response.data.tours)
        }
      });
    axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getIslandDetails")
      .then((response) => {
        if (response.data.status && response.status == 200) {
          setIslands(response.data.tours);
        }
      });
    axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getDepartments")
      .then((response) => {
        if (response.data.status && response.status == 200) {
          setDepartments(response.data.departments);
        }
      });
    axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getDesignations")
      .then((response) => {
        if (response.data.status && response.status == 200) {
          setDesignations(response.data.designations);
        }
      });
    axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getUsers")
      .then((response) => {
        if (response.data.status && response.status == 200) {
          setCustomers(response.data.users);
        }
      });
    axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getEmployees")
      .then((response) => {
        if (response.data.status && response.status == 200) {
          setEmployees(response.data.employees);
        }
      });
  }, []);

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 mt-6'>


        <h3 className='card-label fw-bold fs-3 mb-1 ml-2 -mb-6'>
          Activities
        </h3>
        {/* begin::Col */}
        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5 mt-2'>
          <Link to='/apps/user/enquiries'>
            <CardsWidget20
              className='h-md-90 mb-3 mb-xl-4'
              description='Active Enquiries'
              color='#0585c8'
              count={"" + contacts.length}
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            />
          </Link>
        </div>

        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5 mt-2'>
          <CardsWidget20
            className='h-md-90 mb-3 mb-xl-4'
            description=' Destinations'
            color='rgb(200 149 5 / 72%)'
            count={"" + landing.length}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5 mt-2'>
          <CardsWidget20
            className='h-md-90 mb-3 mb-xl-4'
            description=' Islands'
            color='rgb(233 114 173)'
            count={"" + islands.length}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5 mt-2'>
          <CardsWidget20
            className='h-md-90 mb-3 mb-xl-4'
            description=' Packages'
            color='rgb(9 207 162)'
            count={"" + packages.length}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          {/* <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        />*/}
        </div>

        <div className=' flex col-lg-12 gap-6 '>
          <div className='col-lg-6'>
            <ChartsWidget1 className={''}></ChartsWidget1>
          </div>
          <div className='col-lg-6 flex gap-2'>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
              <CardsWidget20
                className='h-md-90 mb-5 mb-xl-5'
                description=' Departments'
                color='rgb(200 149 5 / 72%)'
                count={"" + departments.length}
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              />


              <CardsWidget20
                className='h-md-90 mb-5 mb-xl-5 '
                description='Customers'
                color='#0585c8'
                count={"" + customers.length}
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              />
            </div>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
              <CardsWidget20
                className='h-md-90 mb-5 mb-xl-5'
                description=' Designations'
                color='rgb(5 200 155)'
                count={"" + designations.length}
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              />

              <CardsWidget20
                className='h-md-90 mb-5 mb-xl-5'
                description='Employee'
                color='rgb(224 66 105)'
                count={"" + employees.length}
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              />
            </div>
          </div>
        </div>

        {/* <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-90 mb-5 mb-xl-10'
            description='Total Departments'
            color='rgb(200 149 5 / 72%)'
            count={"" + departments.length}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div> */}
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10'>
          {/* <CardsWidget20
            className='h-md-90 mb-5 mb-xl-10'
            description='Total Designations'
            color='rgb(5 200 155)'
            count={"" + designations.length}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          /> */}
          {/* <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        />*/}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          {/* <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
        <ListsWidget26 className='h-lg-50' />*/}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };