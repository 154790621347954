import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'

const EditIncludes: FC = () => {
  const {id} = useParams()
  const [iconName, setIconName] = useState('')
  const [description, setDescription] = useState('')
  const icons = [
    'abstract-33',
    'abstract-27',
    'abstract-26',
    'abstract-32',
    'abstract-18',
    'abstract-24',
    'abstract-30',
    'abstract-8',
    'abstract-9',
    'abstract-31',
    'abstract-25',
    'abstract-19',
    'abstract-21',
    'abstract-35',
    'abstract-34',
    'abstract-20',
    'abstract-36',
    'abstract-22',
    'abstract-23',
    'abstract-37',
    'abstract-44',
    'abstract',
    'abstract-45',
    'abstract-47',
    'abstract-46',
    'abstract-42',
    'abstract-43',
    'abstract-41',
    'abstract-40',
    'abstract-48',
    'abstract-49',
    'abstract-12',
    'abstract-2',
    'abstract-3',
    'abstract-13',
    'abstract-39',
    'abstract-11',
    'abstract-1',
    'abstract-10',
    'abstract-38',
    'abstract-14',
    'abstract-28',
    'abstract-4',
    'abstract-5',
    'abstract-29',
    'abstract-15',
    'abstract-17',
    'abstract-7',
    'abstract-6',
    'abstract-16',
    'toggle-on',
    'toggle-on-circle',
    'toggle-off',
    'category',
    'setting',
    'toggle-off-circle',
    'more-2',
    'setting-4',
    'setting-2',
    'setting-3',
    'eraser',
    'paintbucket',
    'add-item',
    'design-2',
    'brush',
    'size',
    'design',
    'copy',
    'text',
    'design-frame',
    'bucket',
    'glass',
    'feather',
    'pencil',
    'colors-square',
    'design-mask',
    'bucket-square',
    'copy-success',
    'color-swatch',
    'instagram',
    'snapchat',
    'classmates',
    'facebook',
    'whatsapp',
    'social-media',
    'youtube',
    'dribbble',
    'twitter',
    'tiktok',
    'behance',
    'underlining',
    'disconnect',
    'code',
    'gear',
    'loading',
    'scroll',
    'wrench',
    'square-brackets',
    'frame',
    'message-programming',
    'data',
    'fasten',
    'click',
    'tech-wifi',
    'joystick',
    'faceid',
    'technology-3',
    'technology-2',
    'electricity',
    'fingerprint-scanning',
    'technology-4',
    'artificial-intelligence',
    'technology',
    'basket-ok',
    'cheque',
    'handcart',
    'shop',
    'tag',
    'purchase',
    'discount',
    'package',
    'percentage',
    'barcode',
    'lots-shopping',
    'basket',
    'book-square',
    'receipt-square',
    'save-2',
    'archive-tick',
    'shield-search',
    'password-check',
    'shield-tick',
    'lock',
    'key',
    'shield',
    'shield-cross',
    'key-square',
    'eye-slash',
    'security-check',
    'lock-3',
    'scan-barcode',
    'lock-2',
    'eye',
    'shield-slash',
    'security-user',
    'triangle',
    'subtitle',
    'ghost',
    'information',
    'milk',
    'home',
    'happy-emoji',
    'mouse-square',
    'filter-tick',
    'filter-search',
    'wifi-home',
    'trash-square',
    'paper-clip',
    'archive',
    'pin',
    'wifi-square',
    'auto-brightness',
    'coffee',
    'icon',
    'emoji-happy',
    'general-mouse',
    'ranking',
    'slider',
    'crown-2',
    'rescue',
    'flash-circle',
    'safe-home',
    'cloud-change',
    'crown',
    'filter-edit',
    'picture',
    'verify',
    'send',
    'tag-cross',
    'cloud-add',
    'home-3',
    'disk',
    'trash',
    'star',
    'cd',
    'home-2',
    'mouse-circle',
    'home-1',
    'call',
    'gift',
    'share',
    'sort',
    'magnifier',
    'filter-square',
    'tree',
    'filter',
    'switch',
    'cloud',
    'cup',
    'diamonds',
    'status',
    'rocket',
    'cloud-download',
    'menu',
    'chrome',
    'geolocation-home',
    'map',
    'telephone-geolocation',
    'satellite',
    'flag',
    'focus',
    'pointers',
    'compass',
    'route',
    'geolocation',
    'brifecase-timer',
    'briefcase',
    'clipboard',
    'bookmark-2',
    'note',
    'note-2',
    'book-open',
    'book',
    'teacher',
    'award',
    'brifecase-tick',
    'brifecase-cros',
    'bookmark',
    'chart-line',
    'chart',
    'graph-3',
    'chart-pie-3',
    'graph-2',
    'chart-line-down',
    'chart-pie-too',
    'chart-pie-4',
    'chart-line-down-2',
    'graph-4',
    'chart-line-up-2',
    'badge',
    'chart-line-up',
    'chart-simple-3',
    'chart-pie-simple',
    'chart-simple-2',
    'graph-up',
    'chart-line-star',
    'graph',
    'chart-simple',
    'tablet-delete',
    'file-added',
    'file-up',
    'minus-folder',
    'file',
    'delete-files',
    'add-folder',
    'file-left',
    'file-deleted',
    'some-files',
    'file-right',
    'notepad',
    'notepad-bookmark',
    'document',
    'like-folder',
    'folder-up',
    'folder-added',
    'file-down',
    'filter-tablet',
    'tablet-book',
    'update-file',
    'add-notepad',
    'questionnaire-tablet',
    'tablet-up',
    'tablet-ok',
    'update-folder',
    'files-tablet',
    'folder-down',
    'notepad-edit',
    'tablet-text-up',
    'search-list',
    'tablet-text-down',
    'add-files',
    'tablet-down',
    'delete-folder',
    'folder',
    'file-sheet',
    'bootstrap',
    'figma',
    'dropbox',
    'xaomi',
    'microsoft',
    'android',
    'vue',
    'js',
    'spring-framework',
    'github',
    'dj',
    'google-play',
    'angular',
    'soft-3',
    'python',
    'soft-2',
    'ts',
    'xd',
    'spotify',
    'js-2',
    'laravel',
    'css',
    'google',
    'photoshop',
    'twitch',
    'illustrator',
    'pails',
    'react',
    'html',
    'slack',
    'soft',
    'yii',
    'apple',
    'vuesax',
    'calendar-add',
    'calendar-search',
    'calendar-2',
    'calendar-tick',
    'time',
    'watch',
    'calendar-edit',
    'calendar',
    'calendar-8',
    'timer',
    'calendar-remove',
    'heart-circle',
    'like',
    'information-4',
    'information-5',
    'information-2',
    'information-3',
    'question',
    'dislike',
    'message-question',
    'medal-star',
    'like-tag',
    'like-2',
    'support-24',
    'question-2',
    'lovely',
    'like-shapes',
    'heart',
    'user',
    'user-square',
    'user-tick',
    'people',
    'user-edit',
    'profile-user',
    'profile-circle',
    'capsule',
    'virus',
    'bandage',
    'thermometer',
    'flask',
    'test-tubes',
    'syringe',
    'mask',
    'pill',
    'pulse',
    'burger-menu',
    'burger-menu-6',
    'burger-menu-5',
    'burger-menu-4',
    'burger-menu-1',
    'burger-menu-3',
    'burger-menu-2',
    'text-align-center',
    'text-italic',
    'text-bold',
    'text-strikethrough',
    'text-underline',
    'text-number',
    'text-align-left',
    'text-align-right',
    'text-circle',
    'text-align-justify-center',
    'theta',
    'dollar',
    'binance',
    'nexo',
    'euro',
    'avalanche',
    'bitcoin',
    'wallet',
    'price-tag',
    'finance-calculator',
    'dash',
    'lts',
    'vibe',
    'credit-cart',
    'paypal',
    'bill',
    'ocean',
    'celsius',
    'educare',
    'enjin-coin',
    'two-credit-cart',
    'bank',
    'binance-usd',
    'wanchain',
    'trello',
    'save-deposit',
    'xmr',
    'financial-schedule',
    'office-bag',
    'night-day',
    'sun',
    'drop',
    'moon',
    'exit-right-corner',
    'dots-circle-vertical',
    'check-square',
    'right-left',
    'arrow-down',
    'dots-horizontal',
    'arrow-right-left',
    'up-down',
    'double-check',
    'arrow-up-left',
    'down',
    'exit-up',
    'up-square',
    'down-square',
    'plus-square',
    'dots-circle',
    'arrow-down-left',
    'double-check-circle',
    'up',
    'entrance-right',
    'arrow-right',
    'arrow-two-diagonals',
    'minus-square',
    'arrow-diagonal',
    'black-left',
    'arrow-down-refraction',
    'black-right',
    'double-left',
    'arrow-circle-left',
    'arrow-zigzag',
    'plus',
    'check',
    'exit-left',
    'arrow-circle-right',
    'cross-square',
    'entrance-left',
    'left-square',
    'arrows-loop',
    'black-left-line',
    'double-left-arrow',
    'check-circle',
    'right',
    'dots-square-vertical',
    'arrow-up-right',
    'exit-down',
    'dots-square',
    'to-left',
    'double-down',
    'plus-circle',
    'black-down',
    'double-up',
    'black-up',
    'double-right-arrow',
    'arrow-up',
    'black-right-line',
    'arrow-up-refraction',
    'arrow-left',
    'cross',
    'minus-circle',
    'arrow-down-right',
    'exit-right',
    'to-right',
    'double-right',
    'arrow-mix',
    'right-square',
    'arrows-circle',
    'cross-circle',
    'left',
    'minus',
    'dots-vertical',
    'arrow-up-down',
    'message-text-2',
    'message-notif',
    'message-add',
    'sms',
    'directbox-default',
    'message-text',
    'messages',
    'address-book',
    'message-edit',
    'message-minus',
    'notification-circle',
    'notification-favorite',
    'notification-2',
    'notification',
    'notification-bing',
    'notification-status',
    'notification-on',
    'scooter-2',
    'parcel',
    'delivery-time',
    'delivery',
    'delivery-24',
    'ship',
    'courier',
    'logistic',
    'trailer',
    'car-2',
    'car-3',
    'airplane-square',
    'scooter',
    'truck',
    'cube-3',
    'bus',
    'cube-2',
    'delivery-door',
    'delivery-3',
    'delivery-2',
    'car',
    'courier-express',
    'airplane',
    'delivery-geolocation',
    'parcel-tracking',
    'monitor-mobile',
    'devices',
    'keyboard',
    'devices-2',
    'bluetooth',
    'wifi',
    'airpod',
    'simcard-2',
    'speaker',
    'printer',
    'simcard',
    'router',
    'phone',
    'electronic-clock',
    'external-drive',
    'laptop',
    'tablet',
    'screen',
    'calculator',
    'mouse',
    'grid',
    'slider-vertical-2',
    'maximize',
    'slider-vertical',
    'row-horizontal',
    'kanban',
    'row-vertical',
    'fat-rows',
    'grid-2',
    'element-8',
    'element-9',
    'element-12',
    'element-4',
    'element-5',
    'grid-frame',
    'element-11',
    'element-7',
    'element-6',
    'element-10',
    'element-2',
    'element-3',
    'element-equal',
    'element-1',
    'slider-horizontal-2',
    'slider-horizontal',
    'element-plus',
  ]

  useEffect(() => {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/getInclusion/' + id)
      .then((response) => {
        if (response.data.status) {
          setIconName(response.data.inclusion.icon)
          setDescription(response.data.inclusion.included)
        }
      })
  }, [])

  useEffect(() => {
    const accordionItemToClose = document.querySelector('#icons_accordion')

    if (accordionItemToClose) {
      // Remove the 'show' class from the accordion body to close it
      const accordionBody = accordionItemToClose.querySelector('.accordion-collapse')
      ;(accordionBody as any).classList.remove('show')
    }
  }, [iconName])

  function updateIncludes() {
    if ((iconName as any) == '') {
      toast.error('Please select a icon')
    } else if ((description as any) == '') {
      toast.error('Please enter description')
    } else {
      const requestData = {id: id, icon: iconName, included: description}
      axios
        .post(
          'https://admin.trawelmart.com/admin/public/index.php/api/updateInclusion',
          requestData
        )
        .then((response) => {
          if (response.data.status) {
            toast.success('Inclusion updated successfully')
            setTimeout(() => {
              ;(window as any).location = '/apps/itinerary/list-includes'
            }, 1000)
          }
        })
    }
  }

  return (
    <>
      <div className='card1 rounded bg-white p-3 px-8 mb-5 mt-16 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h1 className='font-medium  text-xl text-teal-600 pt-2'>Edit Includes</h1>
          </div>
        </div>
        <div className='border-top'>
          {/* begin::Table container */}
          <form>
            <div className='mt-3'>
              <div className='row mb-0'>
                <div className='col-lg-12 row'>
                  <div className='col-lg-2 fv-row pr-4'>
                    <p className='text-xl text-medium text-slate-500'>Selected Icon</p>
                    <br />
                    {iconName != '' && <KTIcon className='mx-1 my-1 fs-3x' iconName={iconName} />}
                  </div>
                  <div className='col-lg-10 fv-row pr-4'>
                    <div className='accordion' id='icons_accordion'>
                      <div className=''>
                        <h4 className='accordion-header' id='icons_accordion_header'>
                          <button
                            className='md:pt-6 xl:pt-0 icons_accordion_button fs-4 text-xl text-slate-500 collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#icons_accordion_body'
                            aria-expanded='false'
                            aria-controls='icons_accordion_body'
                          >
                            Choose Icon (Click to open/close)
                          </button>
                        </h4>
                        <div
                          id='icons_accordion_body'
                          className='accordion-collapse collapse'
                          aria-labelledby='icons_accordion_header'
                          data-bs-parent='#icons_accordion'
                        >
                          <div className='accordion-body'>
                            {icons.map((icon) => (
                              <button
                                key={icon}
                                onClick={() => setIconName(icon)}
                                type='button'
                                style={{
                                  border: icon === iconName ? '2px solid #0585c8' : undefined,
                                }}
                              >
                                <KTIcon className='mr-3 mt-1 mb-2 fs-3x' iconName={icon} />
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 fv-row px-2 pt-8'>
                  <label className='required text-medium text-slate-500 px-2'>Description</label>
                  <textarea
                    className='py-1 form-control form-control-lg form-control-solid mb-0 mb-lg-0'
                    placeholder=''
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-lg-9 pt-4'>
                <button
                  type='button'
                  className='btn btn-primary me-3 mb-3'
                  onClick={updateIncludes}
                >
                  <span className='indicator-label'>Update</span>
                </button>
                <button
                  type='button'
                  className='btn btn-danger mb-3'
                  onClick={() => ((window as any).location = '/dashboard')}
                >
                  <span className='indicator-label'>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export {EditIncludes}
