import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {AddExclude} from './components/AddExclude'
import {EditExclude} from './components/EditExclude'
import {ListExclude} from './components/ListExclude'

const maintenanceBreadCrumbs: Array<PageLink> = [
  {
    title: '',
    path: '/apps/itinerary/add-includes',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ExcludePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='add-exclude'
          element={
            <>
              <AddExclude />
            </>
          }
        />

        <Route
          path='edit-exclude/:id'
          element={
            <>
              <EditExclude />
            </>
          }
        />

        <Route
          path='list-exclude'
          element={
            <>
              <ListExclude />
            </>
          }
        />

        <Route index element={<Navigate to='/apps/exclude/add-exclude' />} />
      </Route>
    </Routes>
  )
}

export default ExcludePage
