import axios from 'axios'
import React, {FC, useEffect, useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import {toast} from 'react-toastify'

const ListExclude: FC = () => {
  interface DataRow {
    id: string
    excluded: string
    status: string
    // Add other properties here if needed
  }

  const columns: TableColumn<DataRow>[] = [
    {
      name: 'Excludes',
      selector: (row: DataRow) => row.excluded,
      sortable: true,
      style: ' color: rgb(100 116 139);',
    },
    {
      name: 'Status',
      selector: (row: DataRow) => row.status,
      sortable: true,
      cell: (row: DataRow) => (
        <button onClick={() => changeStatus(row.id)}>
          <i
            className={`fa-solid fa-toggle-${row.status == 'Active' ? 'on' : 'off'}`}
            style={{
              color: row.status == 'Active' ? 'green' : 'red', // Change icon color based on isActive
              fontSize: '28px',
            }}
          />
        </button>
      ),
    },
    {
      name: 'Actions',
      selector: (row: DataRow) => row.status,
      sortable: true,
      cell: (row: DataRow) => (
        <div className=' flex px-4'>
          <a href={'edit-exclude/' + row.id}>
            <i
              className='fas fa-pencil text-primary'
              style={{fontSize: '18px', marginTop: '4px'}}
            ></i>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='#!' onClick={() => deleteExclusion(row.id)}>
            <i className='las la-trash-alt text-danger' style={{fontSize: '23px'}}></i>
          </a>
        </div>
      ),
    },
  ]

  const [excludes, setExcludes] = useState([])
  const [searchText, setSearchText] = useState<string>('')

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredData = searchText
  ? excludes.filter((item: any) => {
      const concatenatedValues = Object.values(item).join(' ').toLowerCase();
      return concatenatedValues.includes(searchText.toLowerCase());
    })
  : excludes;

  useEffect(() => {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/getExclusions')
      .then((response) => {
        if (response.data.status) {
          setExcludes(response.data.exclusions)
        }
      })
  }, [])

  function changeStatus(id: any) {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/changeExclusionStatus/' + id)
      .then((response) => {
        if (response.data.status) {
          const updatedExcludesList = [...excludes]
          toast.success('Status changed successfully...')
          const index = updatedExcludesList.findIndex((excludes: any) => excludes.id === id)
          if (index !== -1) {
            // Toggle the status
            ;(updatedExcludesList[index] as any).status =
              (updatedExcludesList[index] as any).status === 'Active' ? 'Inactive' : 'Active'

            setExcludes(updatedExcludesList)
          }
        }
      })
  }

  function deleteExclusion(id: any) {
    axios
      .get('https://admin.trawelmart.com/admin/public/index.php/api/deleteExclusion/' + id)
      .then((response) => {
        if (response.data.status) {
          toast.success('Deleted Successfully...')
          setTimeout(() => {
            ;(window as any).location = '/apps/exclude/list-exclude'
          }, 1000)
        }
      })
  }

  return (
    <>
      {/* Modal Start */}

      <div className='card1 bg-white rounded p-7 mb-5 mt-16 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer flex items-center justify-between'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title '>
            <h1 className='font-medium  text-xl  text-teal-600 '>List Excludes</h1>
          </div>
          <div className='pt-0'>
            <a href='add-exclude'>
              <button type='button' className='btn btn-sm btn-primary'>
                Add New
              </button>
            </a>
          </div>
        </div>

        <div className='rounded py-3 mt-1'>
          {/* begin::Table container */}
          <div className='table-responsive1'>
            {/* begin::Table */}
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              customStyles={{
                headRow: {
                  style: {
                    backgroundColor: 'rgb(253 242 248);',
                  },
                },
              }}
              subHeader
              subHeaderComponent={
                // You can customize the subheader component for search
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleSearch}
                  style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', backgroundColor: '#fff' }}
                />
              }
            />
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}
export {ListExclude}
